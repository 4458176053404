import React, { useState, useEffect } from 'react';
import { BASE_URL_API, IMG_URL } from '../environment';
import axios from 'axios';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import noLogo from '../img/logo01.svg';
import "./Event.css";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Events() {
  const [event, setEvent] = useState([]);
  const [allevent, setAllEvent] = useState([]);

  //fetching all evets and showing in table
  const fetchData = () => {
    axios
      .get(BASE_URL_API + 'plogging/getAllEvents')
      .then(res => setEvent(res.data))
      .catch(err => console.log(err));

      axios
      .get(`https://mockups.ajatus.co.in/plogging-event/wp-json/events/v1/all-backend/`)
      .then(res => {
        if (res.code === "no_events_found") {
          setAllEvent([]); 
        } else {
          setAllEvent(res.data); 
        }
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //coulumn for showing data table
  const columns = [
    {
      dataField: 'imageURL',
      text: 'Image',
      formatter: dataField => {
        if (dataField) {
          return <img src={IMG_URL + dataField} style={{ height: '50px', width: '50px' }} className='rounded' />;
        } else {
          return <img src={noLogo} style={{ height: '50px', width: '50px' }} className='rounded' />;
        }
      },
    },

    {
      dataField: 'title',
      text: 'Title',
      sort: 'asc',
      filter: textFilter(),
    },
    {
      dataField: 'date',
      text: 'Date',
      sort: 'asc',
    },
    {
      dataField: 'location',
      text: 'Location',
      sort: 'asc',
      filter: textFilter(),
    },
    {
      dataField: 'plg_name',
      text: 'Plg_Name',
      sort: 'asc',
      filter: textFilter(),
    },
  ];

  //pagination function
  const customTotal = (from, to, size) => (
    <span className='react-bootstrap-table-pagination-total'>
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPage: 5, 
  };

  return (
    //showing data in both website end and approved events in table
    <div className="container-fluid">


  <div className="row align-items-center">
    <div className="col-md-6">
      <div className="mb-3">
        <h5 className="card-title">Approve Event List </h5>
      </div>
    </div>
  </div>
  <div className="row mb-4 ">
    <div className="col-lg-12">
      <div>
      <div className="table-responsive" style={{ maxHeight: "40vh", overflowY: "auto" }}>
        <table className="table project-list-table table-nowrap align-middle table-borderless">
            <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
              <tr style={{backgroundColor:"#73be44", color: "#fff"}}>
                <th scope="col">Title</th>
                <th scope="col">Date</th>
                <th scope="col">Location</th>
                <th scope="col">Plugger Name</th>
                <th scope="col">Plugger Contact</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
            {event.length > 0 ? (
              event.map(event => (
                <tr key={event._id}>
                  <td>{event.title}</td>
                  <td>{event.date}</td>
                  <td>{event.location}</td>
                  <td>{event.plg_name}</td>
                  <td>{event.plg_contact}</td>
                  <td><span className="badge badge-soft-success mb-0">{event.status}</span></td>
                  <td>
                    <Link to={event._id}>
                      <Button type="button" className="btn mx-1" style={{ background: "#73be44", color: "#fff" }}>
                        <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No events found</td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div className="row align-items-center">
    <div className="col-md-6">
      <div className="mb-2">
        <h5 className="card-title">All Events List </h5>
      </div>
    </div>
  </div>
  <div className="row mb-2 ">
    <div className="col-lg-12">
      <div>
      <div className="table-responsive" style={{ maxHeight: "40vh", overflowY: "auto" }}>
        <table className="table project-list-table table-nowrap align-middle table-borderless">
            <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
              <tr style={{backgroundColor:"#73be44", color: "#fff"}}>
                <th scope="col">Title</th>
                <th scope="col">Date</th>
                <th scope="col">Location</th>
                <th scope="col">Plugger Name</th>
                <th scope="col">Plugger Contact</th>
                <th scope="col">Status</th>
                <th scope="col">Permission</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
            {allevent.length > 0 ? (
              allevent.map(event => (
                <tr key={event.id}>
                  <td>{event.event_title}</td>
                  <td>{event.event_date}</td>
                  <td>{event.event_location}</td>
                  <td>{event.organization_name}</td>
                  <td>{event.organization_phone}</td>
                  <td><span className="badge badge-soft-success mb-0">{event.status}</span></td>
                  <td><span className="badge badge-soft-success mb-0">{event.permission}</span></td>
                  <td>
                    <Link to={`${event.id}?type=all`}>
                      <Button type="button" className="btn mx-1" style={{ background: "#73be44", color: "#fff" }}>
                        <i className="fa fa-arrow-right text-light" aria-hidden="true"></i>
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No events found</td>
              </tr>
            )}
          </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


  );
}

export default Events;
