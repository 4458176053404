import React from 'react'

function Privacypolicy() {
  return (
    <div classname="container-fluid mt-lg-5 mt-md-2 mt-sm-0">
      <div className="mx-lg-5 mt-lg-5 mx-sm-1 mx-md-3 mt-md-0 mt-sm-0 text-justify">
        <div className="pt-5 pb-3 text-center " style={{color:'#73be44'}}>
          <h2 className="text-decoration-underline"> Privacy Policy </h2>
        </div>

        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3 " style={{color:'#73be44'}}>1. Introduction</h5>

          <div className="mx-4">
            <p>
              Welcome to RecyclePay! This Privacy Policy is designed to help you
              understand how we collect, use, and protect your personal
              information when you use our mobile application ("<span style={{color:'#73be44'}}><b>RecyclePay</b></span>").
               By downloading, accessing, or using the App, you agree to the terms
              of this Privacy Policy.
            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3 " style={{color:'#73be44'}}>2.1. Personal Information</h5>
          <h5 className=" mb-3 " style={{color:'#73be44'}}>2. Information We Collect</h5>

          <div className="mx-4">
            <p>
              When you use our RecyclePay app, we may collect personal
              information that you provide, such as your name, email address,
              phone number, and payment information for transaction purposes.
            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3" style={{color:'#73be44'}}>2.2. User-Generated Content</h5>

          <div className="mx-4">
            <p>
              We may collect information and content you submit or post on the
              RecyclePay App, such as photos of recyclables, comments, and
              reviews.
            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3" style={{color:'#73be44'}}>2.3. Device Information</h5>

          <div className="mx-4">
            <p>
              We automatically collect certain information about your device,
              including your IP address, device type, operating system, and
              unique device identifiers.
            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className="mb-3" style={{color:'#73be44'}}>
            <span className="text-danger">**</span>2.4. Location Information
          </h5>

          <div className="mx-4">
            <p>
              With your consent, we may collect location information to provide
              services like pickup scheduling.
            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className="mb-3" style={{color:'#73be44'}}>3. How We Use Your Information</h5>

          <div className="mx-4">
            <p>We use the collected information for the following purposes:</p>
            <p className="mb-0">
              - To facilitate and process transactions for the sale of
              recyclables.
            </p>
            <p className="mb-0">
              - To provide customer support and respond to your inquiries.
            </p>
            <p className="mb-0">
              - To improve and enhance the App's features and user experience.
            </p>
            <p className="mb-0">
              - To communicate with you about the App, updates, promotions, and
              other relevant information.
            </p>
            <p className="mb-0">
              - To comply with legal requirements and protect our rights and
              interests.
            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className="mb-3" style={{color:'#73be44'}}>4. Sharing Your Information</h5>

          <div className="mx-4">
            <p>
              We do not sell your personal information to third parties.
              However, we may share your information with:
            </p>
            <p className="mb-0">
              - Service providers and partners necessary for the operation of
              the App.
            </p>
            <p className="mb-0">
              - Legal authorities when required by law, or to protect our rights
              or safety.
            </p>
            <p className="mb-0">
              - With your explicit consent, we may share information with other
              users for community or social features.
            </p>
          </div>
        </div>

        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3" style={{color:'#73be44'}}>5. Your Choices</h5>

          <div className="mx-4">
            <p>
              You have the following choices regarding your personal
              information:
            </p>

            <p className="mb-0">
              - You can access, review, and update your personal information in
              your account settings.
            </p>
            <p className="mb-0">
              - You can opt-out of marketing communications by following the
              instructions in the communications.
            </p>
            <p className="mb-0">
              - You can withdraw your consent to location sharing at any time
              through your device settings.
            </p>
          </div>
        </div>

        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className="mb-3" style={{color:'#73be44'}}>6. Data Security</h5>

          <div className="mx-4">
            <p>
              We take data security seriously and employ reasonable measures to
              protect your personal information. However, no method of
              transmission over the Internet or electronic storage is entirely
              secure, and we cannot guarantee absolute security.
            </p>
          </div>
        </div>

        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3" style={{color:'#73be44'}}>7. Children's Privacy</h5>

          <div className="mx-4">
            <p>
              Our App is not intended for use by children under the age of 13.
              If you believe a child has provided us with personal information,
              please contact us, and we will promptly remove it.
            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3" style={{color:'#73be44'}}>
            8. Changes to this Privacy Policy
          </h5>

          <div className="mx-4">
            <p>
              We may update this Privacy Policy to reflect changes in our
              practices or for other operational, legal, or regulatory reasons.
              We will provide notice of such changes in the App. Your continued
              use of the App after any changes to this Privacy Policy signifies
              your acceptance of the changes.
            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className="mb-3" style={{color:'#73be44'}}>9. Contact Us</h5>

          <div className="mx-4">
            <p>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or your personal information, please contact us at
              support@ceibagreen.com.
            </p>
          </div>
        </div>

        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className="mb-3" style={{color:'#73be44'}}>10. Data Protection Officer</h5>

          <div className="mx-4">
            <p>
              Our Data Protection Officer can be contacted at
              support@ceibagreen.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacypolicy
