import React,{ useEffect, useState } from 'react'
import '../css/setting.css'
import axios from 'axios'
import { BASE_URL_API } from '../environment';
import {Form} from 'react-bootstrap';
import Swal from 'sweetalert2';
import noLogo from '../img/logo01.svg';
function Settingpage() {

    const [paystatus, setPaystatus]=useState("");
    const [admindet, setAdmindet]=useState([]);
    const [adminid, setAdminid]=useState("650bdeb49d0d4331b0da71b3");
    const [todaysslot, setTodaysslot]=useState("");
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    const fetchData = () => {    
        axios
          .get(BASE_URL_API + "users/getPaymentAct/"+adminid)
          .then((res) => {
            console.log(res.data.data);
            setPaystatus(res.data.data.paymentStatus);
            setAdmindet(res.data.data);
          })
          .catch((err) => console.log(err));
        
        axios
          .get(BASE_URL_API + "users/samedayslotstatus/"+adminid)
          .then((res) => {
            console.log(res.data.data);
            setTodaysslot(res.data.data.samedayslotStatus);
          })
          .catch((err) => console.log(err));
      };
    
      useEffect(() => {
        fetchData();
      }, [adminid]);
      const handleToggle = () => {
        const messageText = paystatus
    ? "You are not available for payment settlement"
    : "You are available for payment settlement";
        swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text:  messageText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .put(BASE_URL_API+"users/paymentAct/"+adminid)
            .then((res) => {
              fetchData();
            })
            .catch((error) => {
              console.error("Error :", error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "error"
          );
        }
      });
        
      };
      const handleAvailable = () => {
        const messageText = todaysslot
    ? "Todays slot is not available for Order"
    : "Todays slot is available for Order";
        swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text:  messageText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .put(BASE_URL_API+"users/samedayslotshow/"+adminid)
            .then((res) => {
              fetchData();
            })
            .catch((error) => {
              console.error("Error :", error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "error"
          );
        }
      });
        
      };
    

  return (
    <div className="container-fluid light-style flex-grow-1 container-p-y">
  <h4 className="font-weight-bold mb-2">
    Account settings
  </h4>
  <div className="card overflow-hidden">
    <div className="row no-gutters row-bordered row-border-light min-vh-80" >
      <div className="col-md-3 pt-0">
        <div className="list-group list-group-flush account-settings-links">
          <a className="list-group-item list-group-item-action active" data-toggle="list" href="#account-general">General</a>
          <a className="list-group-item list-group-item-action" data-toggle="list" href="#payment-settlement">Payment Settlement</a>
          <a className="list-group-item list-group-item-action" data-toggle="list" href="#slot-available">Todays Slot</a>
        </div>
      </div>
      <div className="col-md-9">
        <div className="tab-content">
          <div className="tab-pane fade active show" id="account-general">
            <div className="card-body media align-items-center">
              <img src={noLogo} alt="adminlogo" className="d-block ui-w-80" />
              {/* <div className="media-body ml-4">
                <label className="btn btn-outline-primary">
                  Upload new photo
                  <input type="file" className="account-settings-fileinput" />
                </label> &nbsp;
                <button type="button" className="btn btn-default md-btn-flat">Reset</button>
                <div className="text-light small mt-1">Allowed JPG, GIF or PNG. Max size of 800K</div>
              </div> */}
            </div>
            <hr className="border-light m-0" />
            <div className="card-body">
              <div className="form-group">
                <label className="form-label">Name</label>
                <input type="text" className="form-control mb-1" value={admindet.name} />
              </div>
              <div className="form-group">
                <label className="form-label">E-mail</label>
                <input type="text" className="form-control mb-1" value={admindet.email} />
                {/* <div className="alert alert-warning mt-3">
                  Your email is not confirmed. Please check your inbox.<br />
                  <a href="javascript:void(0)">Resend confirmation</a>
                </div> */}
              </div>
              <div className="form-group">
                <label className="form-label">Mobile</label>
                <input type="text" className="form-control" value={admindet.mobile}/>
              </div>
              <div className="form-group">
                <label className="form-label">Password</label>
                <input type="text" className="form-control" value={admindet.password}/>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="payment-settlement">
            <div className="card-body pb-2">
            <div className='p-2'>
                <label className="form-label">Available For Payment Settlement</label>
                <Form.Check 
                type="switch"
                id="payment-status-toggle"
                checked={paystatus}
                onChange={handleToggle}
                label={paystatus ? "Available" : "Not Available"}
                className='ml-3'
                />
            </div>
            </div>
          </div>
          <div className="tab-pane fade" id="slot-available">
            <div className="card-body pb-2">
            <div className='p-2'>
                <label className="form-label">Todays Slot</label>
                <Form.Check 
                type="switch"
                id="payment-status-toggle"
                checked={todaysslot}
                onChange={handleAvailable}
                label={todaysslot ? "Online" : "Offline"}
                className='ml-3'
                />
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* <div className="text-right mt-3">
    <button type="button" className="btn btn-primary">Save changes</button>&nbsp;
    <button type="button" className="btn btn-default">Cancel</button>
  </div> */}
</div>

  )
}

export default Settingpage
