import React, { useState, useEffect } from 'react'
import { BASE_URL_API, IMG_URL } from '../environment';
import { Pagination } from 'react-bootstrap'
import axios from 'axios';
import noLogo from '../img/logo01.svg';
import { Link } from 'react-router-dom';

export const Customers = () => {
  const [last7dayscond, setLast7dayscond] = useState(false);
  const [last15dayscond, setLast15dayscond] = useState(false);
  const [last30dayscond, setLast30dayscond] = useState(false);
  const [notorderyet, setNotorderyet] = useState(false);
  const [accountst, setAccountst] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(1);
  const [totaloffset, setTotalOffset] = useState(null);  
  const [totalItems, setTotalItems] = useState(0);
  const [name, setName] =useState("");
  const [email, setEmail] =useState("");
  const [mobile, setMobile] =useState("");
  const [citystatename, setCitystatename] =useState("");

  // CUSTOMER LIST
  const [cust, setCust] = useState([]);  
  useEffect(() => {
    fetchData();
  }, [last7dayscond, last15dayscond, last30dayscond, notorderyet, accountst, offset, name, email, mobile, citystatename]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(BASE_URL_API + "customers?last7dayscond="+last7dayscond+"&last15dayscond="+last15dayscond+"&last30dayscond="+last30dayscond+"&notorderyet="+notorderyet+"&accountst="+accountst+"&offset="+offset+"&citystatename="+citystatename+"&name="+name+"&email="+email+"&mobile="+mobile)
      .then(res => {
        setCust(res.data.customers);
        setLoading(false);
        setTotalItems(res.data.total);
        setTotalOffset(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  
  useEffect(() => {
    if (accountst) {
      setSelectedFilter('accountst');
    } else {
      if (last7dayscond) setSelectedFilter('last7days');
      else if (last15dayscond) setSelectedFilter('last15days');
      else if (last30dayscond) setSelectedFilter('last30days');
      else if (notorderyet) setSelectedFilter('notorderyet');
      else setSelectedFilter('');
    }
  }, [accountst, last7dayscond, last15dayscond, last30dayscond, notorderyet]);
  const handleFilterChange = (e) => {
    const value = e.target.value;

    setSelectedFilter(value);

    switch (value) {
      case 'last7days':
        setLast7dayscond(true);
        setLast15dayscond(false);
        setLast30dayscond(false);
        setNotorderyet(false);
        setAccountst(false);
        setOffset(1);
        break;
      case 'last15days':
        setLast7dayscond(false);
        setLast15dayscond(true);
        setLast30dayscond(false);
        setNotorderyet(false);
        setAccountst(false);
        setOffset(1);
        break;
      case 'last30days':
        setLast7dayscond(false);
        setLast15dayscond(false);
        setLast30dayscond(true);
        setNotorderyet(false);
        setAccountst(false);
        setOffset(1);
        break;
      case 'notorderyet':
        setLast7dayscond(false);
        setLast15dayscond(false);
        setLast30dayscond(false);
        setNotorderyet(true);
        setAccountst(false);
        setOffset(1);
        break;
      case 'accountst':
        setAccountst(true); 
        setLast7dayscond(false);
        setLast15dayscond(false);
        setLast30dayscond(false);
        setNotorderyet(false);
        setOffset(1);
        break;
      default:
        setLast7dayscond(false);
        setLast15dayscond(false);
        setLast30dayscond(false);
        setNotorderyet(false);
        setAccountst(false);
        setOffset(1);
        break;
    }
  };  

  //FETCH ADDRESS
  const [address, setAddress] = useState([]);
  const adrs = (id) => {
    axios.get(BASE_URL_API + 'address/cusId/' + id)
      .then((res) => {
        setAddress(res.data)
      })
      .catch(err => console.log(err));
  };


  const renderPaginationItems = () => {
    const paginationItems = [];
    const maxPagesToShow = 7; // Show at most 7 pages in the pagination control
    const startPages = 3; // Show the first 3 pages
    const endPages = 2; // Show the last 2 pages

    if (totaloffset <= maxPagesToShow) {
      for (let page = 1; page <= totaloffset; page++) {
        paginationItems.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }
    } else {
      // Show first 3 pages
      for (let page = 1; page <= startPages; page++) {
        paginationItems.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }

      // Show ellipsis if necessary
      if (offset > startPages + 1) {
        paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      // Show the middle pages around the current page
      const middlePages = [];
      const startMiddlePage = Math.max(offset - 1, startPages + 1);
      const endMiddlePage = Math.min(offset + 1, totaloffset - endPages);

      for (let page = startMiddlePage; page <= endMiddlePage; page++) {
        middlePages.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }

      paginationItems.push(...middlePages);

      // Show ellipsis if necessary
      if (offset < totaloffset - endPages - 1) {
        paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }

      // Show last 2 pages
      for (let page = totaloffset - endPages + 1; page <= totaloffset; page++) {
        paginationItems.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }
    }

    return paginationItems;
  };
  const handlePageChange = (pageNumber) => {
    setOffset(pageNumber);
  };

  const startItem = (offset - 1) * 10 + 1;
  const endItem = Math.min(offset * 10, totalItems);
  return (
   
      <div className="card">
        <h5 className="card-header">Customers</h5>
        
        <div className="d-flex justify-content-between"> 
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
        <label htmlFor="cityInput" className="m-2">City:</label>
        <input
          type="text"
          id="cityInput"
          className="form-control form-control-md"
          style={{  borderColor: "#73be44" }}
          value={citystatename}
          onChange={(e) => {setCitystatename(e.target.value); setOffset(1);}}
          placeholder="Enter City"
        />
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end p-2">
        {/* <label className='mt-2' style={{color:"#73be44", fontWeight:"bold"}}>Order Conditions</label> */}
        <select
          className="form-select form-select-sm"
          style={{ width: '150px', height: '40px', borderColor: "#73be44" }}
          value={selectedFilter}
          onChange={handleFilterChange}
        >
          <option value="">All Customers</option>
          <option value="accountst">Deleted</option>  {/* Here the acountst is false for all customer and true for deleted customer here default is false so all customers are showing */}
          <option value="last7days">Last 7 Days Not order</option>
          <option value="last15days">Last 15 Days Not order</option>
          <option value="last30days">Last 30 Days Not order</option>
          <option value="notorderyet">No Orders Yet</option></select>
      </div>
      </div>
        <div>
        <>    
        <div className="">
        <div
          className="table-responsive table-bordered"
          style={{ minHeight: "75vh", overflowY: "auto" }}
        >
          <table className="table mb-0">
            <thead style={{backgroundColor:"#E6F8DB"}}>
              <tr>
                <th>
                  Image
                  <br />
                </th>
                <th>
                  Customer Name
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={name}
                    onChange={(e) => {setName(e.target.value); setOffset(1);}}
                  />
                </th>
                <th>
                  Contact No
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={mobile}
                    onChange={(e) => {setMobile(e.target.value); setOffset(1);}}
                  />
                </th>
                <th>
                  Email
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value); setOffset(1);}}
                  />
                </th>
                <th>
                  Account Status <br />
                </th>
              </tr>
            </thead>
            {loading ? (
        <tbody>
          <tr>
              <td colSpan="5" className="text-center">Loading....</td>
            </tr>
        </tbody>
      ) : (
        <tbody style={{ fontSize: "14px" }}>
          {cust.length > 0 ? (
            cust.map((data, index) => (
              <tr key={index}>
                <td>
                  {data.imageURL ? (
                    <img
                      src={IMG_URL + data.imageURL}
                      style={{ height: '50px', width: '50px' }}
                      className="rounded"
                      alt="Logo"
                    />
                  ) : (
                    <img
                      src={noLogo}
                      style={{ height: '50px', width: '50px' }}
                      className="rounded"
                      alt="No Logo"
                    />
                  )}
                </td>
                <td>
                  <Link
                    to={`${data._id}/${data.mobile}`}
                    style={{ textDecoration: 'none', color: '#73be44', cursor: 'pointer' }}
                  >
                    <span style={{ borderBottom: '1px solid transparent' }}>
                      {data.name}
                    </span>
                  </Link>
                </td>
                <td>{data.mobile}</td>
                <td>{data.email}</td>
                <td>{data.isDelete === true ? 'Deleted' : 'Active'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">No data found</td>
            </tr>
          )}
        </tbody>
      
          )}
          </table>
        </div>
      </div>

      <div className='d-flex justify-content-between pb-0'>
        <div className='p-2'>
          Showing {startItem} to {endItem} of {totalItems} items
        </div>
        <div className='p-2'>
          <Pagination className="mb-0">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={offset === 1} />
          <Pagination.Prev onClick={() => handlePageChange(offset - 1)} disabled={offset === 1} />
          {renderPaginationItems()}
          <Pagination.Next onClick={() => handlePageChange(offset + 1)} disabled={offset === totaloffset} />
          <Pagination.Last onClick={() => handlePageChange(totaloffset)} disabled={offset === totaloffset} />
          </Pagination>
        </div>
      </div>
</>
    </div>    
        </div>
      
    
  )
}
