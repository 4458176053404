import React from 'react'
import { Link } from 'react-router-dom'

function DataDeletion() {

    const handleMenuItemClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' 
        });
      };

  return (
    // data deletion page
    <div classname="container-fluid mt-lg-5 mt-md-2 mt-sm-0">
    <div className="mx-lg-5 mt-lg-5 mx-sm-1 mx-md-3 mt-md-0 mt-sm-0 text-justify">
      <div className="pt-5 pb-3 text-center " style={{color:'#73be44'}}>
        <h2 className="text-decoration-underline"> Data Deletion Instructions for Recycle Pay </h2>
      </div>

      <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
        <h5 className=" mb-3 " style={{color:'#73be44'}}>1. Introduction</h5>

        <div className="mx-4">
          <p>
          At Ceiba Green Solutions, we take data privacy seriously. This page outlines the steps to request 
          the deletion of your personal data from our app,
           Recycle Pay. Please follow the instructions below to initiate the data deletion process.
          </p>
        </div>
      </div>
      <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
        <h5 className=" mb-3 " style={{color:'#73be44'}}>2. How to Request Data Deletion</h5>
        <p className='mx-4'>To request the deletion of your data, please follow these steps:</p>
       <div className='mx-4'>
        <h6 className=" mb-3 " style={{color:'#73be44'}}>2.1. Submit a Deletion Request:</h6>

        <div className="mx-4 mb-3">
          
          <p className="mb-0">
            - Log in to your Recycle Pay account.
          </p>
          <p className="mb-0">
            - Navigate to the "Settings" or "Account" section.
          </p>
          <p className="mb-0">
            - Look for an option related to "Data Privacy" or "Data Management."
          </p>
          <p className="mb-0">
            - Select "Request Data Deletion" or a similar option.
          </p>
        </div>

        <h6 className=" mb-3 " style={{color:'#73be44'}}>2.2. Fill out the Deletion Request Form:</h6>

        <div className="mx-4 mb-3">
          
          <p className="mb-0">
            - You will be directed to a form where you can specify the data you want to be deleted.
          </p>
          <p className="mb-0">
            - Provide the necessary information, such as your name, email address, and any additional details to help us locate your data.
          </p>
         
        </div>

        <h6 className=" mb-3 " style={{color:'#73be44'}}>2.3. Verify Your Identity: </h6>

        <div className="mx-4 mb-3">
          
          <p className="mb-0">
            - As a security measure, we may require you to verify your identity. Follow the on-screen instructions for identity verification.
          </p>
         
         
        </div>

        <h6 className=" mb-3 " style={{color:'#73be44'}}>2.4. Confirmation Email: </h6>

<div className="mx-4 ">
  
  <p className="mb-0">
    -Once your request is submitted and verified, you will receive a confirmation email with details about the deletion process. 
    </p>
 
 
</div>

</div>
      </div>



 <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
        <h5 className="mb-3" style={{color:'#73be44'}}>3. Important Information</h5>

        <div className="mx-4">
          <p>
          - <b>Processing Time:</b> Please allow 3 to 4 business days for us to process your data deletion request.
          </p>
          <p className="mb-0">
            - <b>Exceptions:</b> Certain data may be retained for legal or business reasons even after your request for deletion. We will inform you if any exceptions apply.
          </p>
          
        </div>
      </div>

 
 
 
      <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
        <h5 className=" mb-3" style={{color:'#73be44'}}>4. Contact Us</h5>

        <div className="mx-4">
          <p>
          If you encounter any issues or have questions regarding the data deletion process, please contact our customer support team:
          </p>

          <p className="mb-0">
            - <b>Email:</b>support@ceibagreen.com

          </p>
          <p className="mb-0">
            - <b>Phone:</b> +91 7655892808
          </p>
          
        </div>
      </div>





      <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
        <h5 className="mb-3" style={{color:'#73be44'}}>5. Additional Information</h5>

        <div className="mx-4">
          <p>
          For more information about how we collect, use, and protect your data, 
          please refer to our <Link to="/privacypolicy" onClick={handleMenuItemClick}>Privacy Policy</Link>.
          </p>
        </div>

        <div className="mx-4">
          <p>
          Thank you for choosing Recycle Pay by Ceiba Green Solutions.
          </p>
        </div>
      </div>

     
    </div>
  </div>
  )
}

export default DataDeletion
