import React from 'react'
import { Dashsidebar } from './Dashsidebar'
import { Dashnav } from './dashnav'
import { Outlet } from 'react-router-dom'

export const Dashboard = () => {
  return (
    <>
      <div className="body1">
          <Dashsidebar />
          <div className="main">
          <Dashnav />
          <div className="start">
          <Outlet />
          </div>
          </div>
        </div>
    </>
  )
}
