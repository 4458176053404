import React, { useEffect, useState } from "react";
import "../css/bscustomer.css";
import Logo from "../img/WebLogo.svg";
import ReactApexChart from "react-apexcharts";
import { BASE_URL_API , IMG_URL} from "../environment";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { utils, writeFile as writeFileXLSX } from "xlsx";
import { jsPDF } from "jspdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function BsCustomer() {
  const navigate = useNavigate();
  const id = localStorage.getItem("customer_id");
  const cusname = localStorage.getItem("customer_name");

  const getCurrentMonth = () => {
    const today = new Date();
    return String(today.getMonth() + 1).padStart(2, "0");
  };

  const getCurrentYear = () => {
    const today = new Date();
    return today.getFullYear().toString();
  };

  const [mn, setMn] = useState(getCurrentMonth());
  const [yr, setYr] = useState(getCurrentYear());
  // const id = "667521509af35e745f1db20a";
  const [totalord, setTotalord] = useState(null);
  const [totalpaid, setTotalpaid] = useState(null);
  const [totalpending, setTotalpending] = useState(null);
  const [totalweight, setTotalWeight] = useState(null);
  const [orders, setOrders] = useState([]);
  //fetching data and showing in table
  const [totalprice, setTotalPrice] = useState(null);
  const [itemwisedata, setItemwisedata] = useState([]);
  const fetchData = () => {
    axios
      .get(BASE_URL_API + "orders/countCustAllOrders/" + id)
      .then((res) => {
        if (res.data.message === "No order placed by the customer") {
          setTotalord(0);
        } else {
          setTotalord(res.data.TotalOrders);
          console.log(res.data.TotalOrders);
        }
      })
      .catch((error) => {
        console.error("Error fetching orders count:", error);
        setTotalord(0);
      });

    axios
      .get(BASE_URL_API + "orders/custWasteAndPrice/" + id)
      .then((res) => {
        setTotalpaid(res.data.TotalAmountPaid.TotalPrice);
        setTotalpending(res.data.TotalPendingAmount.TotalPrice);
        setTotalWeight(res.data.TotalQuantity);
      })
      .catch((err) => console.log(err));
    
    axios
      .get(BASE_URL_API +"orders/bscustomerIndItems/"+id)
      .then((res) => {
        console.log(res.data);
        setItemwisedata(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(BASE_URL_API +"orders/custAllOrders/"+id+"?month="+mn+"&year="+yr)
      .then((res) => {
        console.log(res.data.data);
        setOrders(res.data.data);
        setTotalPrice(res.data.totalSum);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (mn && yr) {
      setOrders([]);
      setTotalPrice(0);
      fetchData();
    }
  }, [id, mn, yr]);

  const getCurrentDateFormatted = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();

    return `${dd}-${mm}-${yyyy}`;
  };
//export button function
  const handleExport = () => {
    const currentDate = getCurrentDateFormatted();
    handleDownloadExcel(
      orders,
      `Report-${currentDate}`,
      `Report_RP_${currentDate}`
    );
  };

  const pdfdownload = () => {
    const currentDate = getCurrentDateFormatted();
    handleDownloadPDF(
      orders,
      `Report-${currentDate}`,
      `Report_RP_${currentDate}`
    );
  };

  //export function for export as pdf
  const handleDownloadPDF = (orders, sheetName, fileName) => {
    //change the header name 
    const columnsToExport = [
      { header: "Order ID", dataKey: "order_id" },
      { header: "Date", dataKey: "schedule_date" },
      { header: "Item", dataKey: "item_name" },
      { header: "Quantity", dataKey: "weight" },
      { header: "Unit", dataKey: "unit" },
      { header: "Rate(/Kg)", dataKey: "price" },
      { header: "Amount", dataKey: "total" },
      { header: "Status", dataKey: "status" },
    ];
    const sumRow = {
      order_id: "", 
      schedule_date: "",
      item_name: "",
      weight: "",
      unit: "",
      price: "Total",
      total: totalprice,
      status: "",
    };

    const filteredData = orders.map((row) =>
      columnsToExport.reduce((acc, column) => {
        let value;
        if (column === "weight") {
          value = parseFloat(row[column]).toFixed(3);
        } else if (column === "price") {
          value = parseFloat(row[column]).toFixed(2);
        } else if (column.dataKey === "total") {
          value = parseFloat(row[column.dataKey]).toFixed(2);
        } else if (column.dataKey === "status") {
          value =
            row.status === "processed"
              ? "Payment Successful"
              : row.status === "Pickup Completed"
              ? "Payment Pending"
              : row.status;
        } else {
          value = row[column.dataKey];
        }

        acc[column.dataKey] = value;
        return acc;
      }, {})
    );

    const doc = new jsPDF();
    doc.autoTable({
      head: [columnsToExport.map((col) => col.header)],
      body: [...filteredData.map((row) => columnsToExport.map(col => row[col.dataKey])), Object.values(sumRow)],
    });

    doc.save(`${fileName}.pdf`);
  };
  //export function for export as excel
  const handleDownloadExcel = (orders, sheetName, fileName) => {
    //rename the excel header
    const columnsToExport = {
      order_id: "Order ID",
      schedule_date: "Date",
      item_name: "Item",
      weight: "Quantity",
      unit: "Unit",
      price: "Rate (₹)",
      total: "Amount (₹)",
      status: "Payment Status",
    };

    const sumRow = {
      [columnsToExport.order_id]: "",
      [columnsToExport.schedule_date]: "",
      [columnsToExport.item_name]: "",
      [columnsToExport.weight]: "",
      [columnsToExport.unit]: "",
      [columnsToExport.price]: "Total",
      [columnsToExport.total]: totalprice,
      [columnsToExport.status]: "",
    };
    const filteredData = orders.map((row) =>
      Object.keys(columnsToExport).reduce((acc, column) => {
        let value;
        if (column === "weight") {
          value = parseFloat(row[column]);
        } else if (column === "price") {
          value = parseFloat(row[column]);
        } else if (column === "total") {
          value = parseFloat(row[column]);
        } else if (column === "status") {
          value =
            row.status === "processed"
              ? "Payment Successful"
              : row.status === "Pickup Completed"
              ? "Payment Pending"
              : row.status;
        } else {
          value = row[column];
        }

        acc[columnsToExport[column]] = value;
        return acc;
      }, {})
    );

    filteredData.push(sumRow);
    // Creating the worksheet and workbook
    const ws = utils.json_to_sheet(filteredData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    // Saving the Excel file
    writeFileXLSX(wb, `${fileName}.xlsx`);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleMonthSelect = (e) => {
    setMn(e.target.value);
  };

  const handleYearSelect = (date) => {
    setYr(date.getFullYear().toString());
  };
  return (
    <div className="container-xl px-4 mt-4">
      {/* Account page navigation*/}
      <div className="d-flex justify-content-end align-items-center">
        <span
          style={{
            marginRight: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            color: "black",
          }}
          onClick={handleLogout}
        >
          Logout
        </span>
        <i
          className="fa-solid fa-sign-out"
          style={{ cursor: "pointer", color: "red" }}
          onClick={handleLogout}
        />
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-between ">
            <div className="mt-lg-4 px-lg-5 mt-sm-1 px-sm-0">
              <p
                className="mb-0"
                style={{ fontWeight: "bold", fontSize: "20px" }}
                // style={{ fontFamily: "Times New Roman" }}
              >
                Hello,
              </p>
              <h2>
                <span
                  style={{
                    color: "#73be44",
                    // fontFamily: "Times New Roman",
                    fontWeight: "bolder",
                  }}
                >
                  {cusname}
                </span>
              </h2>
            </div>
            <div className="mt-4 px-4">
              <figure>
                <img
                  className="img-responsive img-circle img-thumbnail img-user "
                  src={Logo}
                  alt="Logo"
                  style={{ width: "200px", border: "none" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-0 mb-4" />
      <div className="row">
        <div className="col-lg-4 mb-4">
          {/* Billing card 1*/}
          <div className="card h-100 border-start-lg border-start-primary">
            <div className="card-body">
              <div
                className="small "
                style={{ color: "#73be44", fontWeight: "bold" }}
              >
                Total Orders
              </div>
              <div className="h3 mt-2">{totalord}</div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3 mb-4">
          
          <div className="card h-100 border-start-lg border-start-secondary">
            <div className="card-body">
              <div
                className="small "
                style={{ color: "#73be44", fontWeight: "bold" }}
              >
                Total Sold Quantity
              </div>
              <div className="h3 mt-2">
                {totalweight ? totalweight.toFixed(3) : "0.000"} Kg
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-lg-4 mb-4">
          {/* Billing card 3*/}
          <div className="card h-100 border-start-lg border-start-success">
            <div className="card-body">
              <div
                className="small "
                style={{ color: "#73be44", fontWeight: "bold" }}
              >
                Settled Amount Till Date
              </div>
              <div className="h3 d-flex align-items-center mt-2">
                ₹ {totalpaid ? totalpaid.toFixed(2) : "0.00"}{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 mb-4">
          {/* Billing card 1*/}
          <div className="card h-100 border-start-lg border-start-primary">
            <div className="card-body">
              <div
                className="small "
                style={{ color: "#73be44", fontWeight: "bold" }}
              >
                Pending Amount
              </div>
              <div className="h3 mt-2">
                ₹ {totalpending ? totalpending.toFixed(2) : "0.00"}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Payment methods card*/}
      <div className="row">
        {itemwisedata && itemwisedata.length > 0 ? (
          itemwisedata.map((item) => (
            <div className="col-md-6 col-xl-4 mb-4" key={item.itemId}>
              <div className="card h-100">
                <div className="card-body d-flex flex-column">
                  <div className="row align-items-center">
                    <div className="col-4">
                      <img 
                        src={IMG_URL + item.imageURL} 
                        alt={item.itemName} 
                        className="img-fluid rounded"
                      />
                    </div>
                    <div className="col-8 media-body overflow-hidden">
                      <h5 className="card-text mb-0">{item.itemName}</h5>
                      <p className="card-text text-muted">
                        {item.unit === "Pc" ? Math.round(item.totalWeight) : item.totalWeight.toFixed(3)} {item.unit}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No orders available</p>
        )}
      </div>

      {/* Billing history card*/}
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between">
          <>
            <div style={{ color: "#73be44", fontWeight: "bold" }}>
              Order History
            </div>
            <div className="d-flex align-items-center">
              {/* select month for filter */}
              <div className="me-3">
                <select
                  className="form-select form-select-md"
                  id="monthSelect"
                  value={mn}
                  onChange={handleMonthSelect}
                >
                  <option value="">Select Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              {/* Year picker  */}
              <div className="me-3">
                <DatePicker
                  selected={yr ? new Date(yr, 0, 1) : null}
                  onChange={handleYearSelect}
                  showYearPicker
                  dateFormat="yyyy"
                  className="form-select"
                  placeholderText="Select Year"
                  minDate={new Date(2023, 0, 1)}
                />
              </div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="exportDropdown">
                    Export As
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={handleExport}
                      style={{ color: "#73be44" }}
                    >
                      Excel
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={pdfdownload}
                      style={{ color: "#73be44" }}
                    >
                      PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </>
        </div>
        <div className="card-body p-0">
          {/* Billing history table*/}
          <div className="table-responsive table-billing-history">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="border-gray-200" scope="col">
                    Order ID
                  </th>
                  <th className="border-gray-200" scope="col">
                    Date
                  </th>
                  <th className="border-gray-200" scope="col">
                    Item
                  </th>
                  <th className="border-gray-200" scope="col">
                    Quantity
                  </th>
                  <th className="border-gray-200" scope="col">
                    Unit
                  </th>
                  <th className="border-gray-200" scope="col">
                    Rate ( ₹ )
                  </th>
                  <th className="border-gray-200" scope="col">
                    Amount ( ₹ )
                  </th>
                  <th className="border-gray-200" scope="col">
                    Payment Status
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px", fontWeight: "bold" }}>
                {orders && orders.length > 0 ? (
                  orders.map((order) => (
                    <tr key={order._id}>
                      <td className="border-gray-200" scope="col">
                        <Link
                          to={order._id}
                          style={{ color: "#73be44", cursor: "pointer" }}
                        >
                          {order.order_id}
                        </Link>
                      </td>
                      <td className="border-gray-200" scope="col">
                        {order.schedule_date}
                      </td>
                      <td className="border-gray-200" scope="col">
                        {order.item_name}
                      </td>
                      <td className="border-gray-200" scope="col">
                        {order.unit === 'Kg' ? (order.weight !== null ? order.weight.toFixed(3) : 0) : order.unit === 'Pc' ? order.weight : ''}
                      </td>
                      <td className="border-gray-200" scope="col">
                        {order.unit}
                      </td>
                      <td className="border-gray-200" scope="col">
                        {order.price.toFixed(2)} /{order.unit}
                      </td>
                      <td className="border-gray-200" scope="col">
                        {order.total.toFixed(2)}
                      </td>
                      <td className="border-gray-200" scope="col">
                        <span
                          className={`badge ${
                            order.status === "processed"
                              ? "bg-success"
                              : order.status === "Pickup Completed"
                              ? "bg-warning"
                              : "bg-light text-dark"
                          }`}
                        >
                          {order.status === "processed"
                            ? "Payment Successful"
                            : order.status === "Pickup Completed"
                            ? "Payment Pending"
                            : order.status}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No orders found
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
              <tr>
                <td colSpan="5" className="text-right"></td>
                <td className="text-right"><b>Total</b></td>
                <td style={{color:"#73be44"}}>
                  <b>{totalprice}</b>
                </td>
              </tr>
            </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BsCustomer;
