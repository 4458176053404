import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useNavigate} from "react-router-dom";
import "../css/Receipt.css";
import { BASE_URL_API } from "../environment";
import PaidLogo from "../img/paidlogo.png";
function BsOrder() {
    var params = useParams();
  const navigate=useNavigate();
  const [orderData, setOrderData] = useState([]);
  
  useEffect(() => {
    fetchData();
  }, [params.id]);

  //fetching data and showing in page
  const fetchData=()=>{
    axios
    .get(BASE_URL_API + "orders/indhistorder/" + params.id)
    .then((res) => {
      setOrderData(res.data);
      localStorage.setItem("pout", res.data.data.result[0].pout);
       console.log(res.data.data.result[0].schedule_date)
       console.log(res.data.data.result[0].time)
      //  console.log(res.data)
      // console.log(res.data.data.result[0].pout)
      // console.log(res.data.data.result[0].pout)
      
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }
//convert date into dd-mm-yyyy format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };  
  //calculating total price
  const calculateSubtotal = (items) => {
    if (!Array.isArray(items)) return 0;
  
    return items.reduce((acc, item) => {
      const itemPrice = parseFloat(item.price) || 0;
      const weight = parseFloat(item.weight) || 0;
      return acc + itemPrice * weight;
    }, 0);
  };
  
  // Calculate subtotal
  const subtotal = calculateSubtotal(
    orderData?.data?.result[0]?.item
  );
  const goBack = () => {
    navigate(-1);
}
//print function for print as pdf
const printReceipt = () => {
    window.print();
};

  return (
    <div>
      {/* it is used for removing print button and goback button when click on print button  */}
        <style>
        {`
          @media print {
            .print.float-start.d-flex {
              display: none !important;
            }
            .print.float-end.d-flex {
              display: none !important;
            }
          }
        `}
      </style>
    <div className="receipt-content">
      <div className="container bootstrap snippets bootdey">
        <div className="row">
          <div className="col-md-12">
            <div className="invoice-wrapper mb-5">
              <div className="payment-info">
                <div className="row">
                  <div className="col-sm-6">
                    <strong>
                      <span style={{color:"#73be44"}}>Order Id</span>
                    </strong>
                    <strong>{orderData?.data?.result[0]?.order_id}</strong>

                    <strong>
                      <span style={{color:"#73be44"}}>Order Status</span>
                    </strong>
                    {orderData?.data?.result[0]?.status === "processed" ? (
                        <strong>Payment Successful</strong>
                        ) : orderData?.data?.result[0]?.status === "Pickup Completed" ? (
                        <strong>Payment Pending</strong>
                        ) : (
                        <strong>{orderData?.data?.result[0]?.status}</strong>
                        )}
                  </div>
                  <div className="col-sm-6 text-right">
                    <strong>
                      <span style={{color:"#73be44"}}>Order Date</span>
                    </strong>
                    <strong>
                      {formatDate(orderData?.data?.result[0]?.createdAt)}
                    </strong>

                    <strong>
                      <span style={{color:"#73be44"}}>Slot Date</span>
                    </strong>
                    <strong>{orderData?.data?.result[0]?.schedule_date}</strong>

                    <strong>
                      <span style={{color:"#73be44"}}>Slot Time</span>
                    </strong>
                    <strong>{orderData?.data?.result[0]?.time}</strong>
                  </div>
                </div>
                <div className="payment-details">
                  <div className="row">
                    <div className="col-sm-6 line-items">
                      <div>
                        <strong>
                          <span className="text-bold" style={{color:"#73be44"}}>
                            Customer Details:
                          </span>
                        </strong>
                        <strong>
                          {orderData?.data?.name}
                        </strong>
                        <p style={{ fontSize: "13px" }}>
                          {orderData?.data?.location},{" "}
                          {orderData?.data?.landmark}
                          <br />
                          {orderData?.data?.city}, {orderData?.data?.state}
                          <br />
                          <b>Pin:</b> {orderData?.data?.pin} <br />
                          <b>Mobile:</b> {orderData?.data?.mobile} <br />
                          <b>Email ID:</b> {orderData?.data?.email} <br />
                        </p>
                      </div>
                      <br />
                      <div>
                        <strong>
                          <span style={{color:"#73be44"}}>
                            Payment Details:&nbsp;
                          </span>
                        </strong>
                        {orderData?.data?.result[0]?.mode === "IMPS" && (
                          <p>
                            <b>Mode Of Payment :</b>
                            {orderData?.data?.result[0]?.mode}
                            <br />
                            {orderData?.data?.result[0]?.status ===
                              "processed" && (
                              <>
                                <b>UTR No :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.pout?.split(
                                    "_"
                                  )[1]
                                }{" "}
                                <br />
                              </>
                            )}
                            <b>Account Holder Name :</b>{" "}
                            {
                              orderData?.data?.result[0]?.payment?.bank_account
                                ?.name
                            }{" "}
                            <br />
                            <b>Account No :</b>{" "}
                            {
                              orderData?.data?.result[0]?.payment?.bank_account
                                ?.account_number
                            }{" "}
                            <br />
                            <b>IFSC :</b>{" "}
                            {
                              orderData?.data?.result[0]?.payment?.bank_account
                                ?.ifsc
                            }{" "}
                            <br />
                          </p>
                        )}

                        {orderData?.data?.result[0]?.mode === "UPI" && (
                          <p>
                            <b>Mode Of Payment :</b>{" "}
                            {orderData?.data?.result[0]?.mode}
                            <br />
                            {orderData?.data?.result[0]?.status ===
                              "processed" && (
                              <>
                                <b>UTR No :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.pout?.split(
                                    "_"
                                  )[1]
                                }{" "}
                                <br />
                              </>
                            )}
                            <b>UPI ID :</b>{" "}
                            {orderData?.data?.result[0]?.payment?.vpa?.address}{" "}
                            <br />
                          </p>
                        )}

                        {orderData?.data?.result[0]?.mode === "Cash" && (
                          <p>
                            <b>Mode Of Payment :</b>CASH
                            <br />
                          </p>
                          // {orderData?.data?.result[0]?.mode}
                        )}
                        {orderData?.data?.result[0]?.mode === "Eco" && (
                          <p>
                            <b>Mode Of Payment :</b>Eco
                            <br />
                          </p>
                        )}
                        {orderData?.data?.result[0]?.mode === "Donation" && (
                          <div>
                          <p>
                            <b>Mode Of Payment :</b>{" "}
                            {orderData?.data?.result[0]?.mode}
                          </p>
                          <p>
                            <strong>
                              <span style={{color:"#73be44"}}>
                                NGO Details:&nbsp;
                              </span>
                            </strong>
                            <b>NGO Name :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.name
                            }{" "}
                            <br />
                            <b>NGO Contact No :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.mobile
                            }{" "}
                            <br />
                            <b>NGO Email :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.email
                            }{" "}
                          </p>
                          <p>
                            <strong>
                              <span style={{color:"#73be44"}}>
                                Transaction Details:&nbsp;
                              </span>
                            </strong>
                            <b>Account Holder Name :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.ngo_bank_account?.name
                                
                            }{" "}
                            <br />
                            <b>Account No :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.ngo_bank_account?.account_number
                            }{" "}
                            <br />
                            <b>IFSC :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.ngo_bank_account?.ifsc
                            }{" "}
                            <br />
                            {orderData?.data?.result[0]?.status ===
                              "processed" && (
                              <>
                                <b>UTR No :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.pout?.split(
                                    "_"
                                  )[1]
                                }{" "}
                                <br />
                              </>
                            )}
                          </p>
                          </div>
                        )}
                      </div>
                      <br />
                      <div>
                        <strong>
                          <span style={{color:"#73be44"}}>
                            Assigned Agent:&nbsp;
                          </span>
                        </strong>
                        <strong>
                          {orderData?.data?.result[0]?.agent_id
                            ? orderData?.data?.Agent_name
                            : "Not Assigned"}
                        </strong>
                        <p>
                          {orderData?.data?.result[0]?.agent_id
                            ? orderData?.data?.Agent_mobile
                            : ""}
                          <br />
                          {orderData?.data?.result[0]?.agent_id
                            ? orderData?.data?.Agent_email
                            : ""}
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 line-items">
                      <div className="headers clearfix">
                        <div className="row">
                          <div className="col-3" style={{color:"#73be44"}}>
                            <b>Item</b>
                          </div>
                          <div className="col-4 " style={{color:"#73be44"}}>
                            <b>Quantity</b>
                          </div>
                          <div className="col-2 " style={{color:"#73be44"}}>
                            <b>Rate</b>
                          </div>
                          <div className="col-3 text-right" style={{color:"#73be44"}}>
                            <b>Amount</b>
                          </div>
                        </div>
                      </div>

                      <div className="items">
                        {orderData?.data?.result[0]?.item.map((item, index) => (
                          <div key={index}>
                            <div className="row item">
                              <div className="col-3 desc" style={{fontSize:"13px"}}>{item.item_name}</div>
                              <div className="col-4 qty" style={{fontSize:"13px"}}>
                              {item.unit === 'Kg' ? (item.weight !== null ? item.weight.toFixed(3) : 0) : item.unit === 'Pc' ? item.weight : ''} {item.unit}

                              </div>
                              <div className="col-2 qty" style={{fontSize:"13px"}}>₹{item.price.toFixed(2)}</div>
                              <div className="col-3 amount text-right" style={{fontSize:"13px"}}>
                                ₹
                                {(
                                  (item.weight !== null ? item.weight : 0) *
                                  item.price
                                ).toFixed(2)}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="total text-right ">
                        <div className="field grand-total">
                          Total{" "}
                          <span>
                            <strong>₹{subtotal.toFixed(2)}</strong>{" "}
                          </span>
                        </div>
                      </div>
                      {orderData?.data?.result[0]?.status ===
                              "processed" && (
                              <>
                               <div className="total text-right ">
                      <div className="field grand-total">
                        <span
                              style={{
                                color: "#73be44",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Amount Paid
                            </span>{" "}
                            <span
                              style={{
                                color: "#73be44",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              <strong>₹{subtotal.toFixed(2)}</strong>{" "}
                            </span>
                          </div>
                      </div>

                      <div className="d-flex justify-content-center mt-lg-5">
                        <div className="col-6">
                          <img src={PaidLogo} className="m-1" />
                        </div>
                      </div>
                              </>
                            )}
                    </div>
                  </div>
                </div>
                <div>
                <div>
                    <div className="text-center mt-5 p-3 row">
                      <div style={{ fontSize: "10px"}} className="mt-2 mx-auto col-7">
                      An initiative of Ceiba Green Solutions (OPC) Pvt. Ltd </div>
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="print float-start d-flex">
                      <Link onClick={printReceipt}>
                        <i className="fa fa-print"></i>
                        Print this receipt
                      </Link>
                    </div>
                    <div className="print float-end d-flex">
                      <Link onClick={goBack}>
                        <i className="fa fa-arrow-left"></i>
                        Go Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default BsOrder
