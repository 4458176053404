import React from 'react'
import { Link } from 'react-router-dom'
import Applogo from '../img/appstorelogo.jpg';
import Playlogo from '../img/playstorelogo.jpg';
import logo from '../img/WebLogo.svg'

export const Landfooter = () => {

    const handleMenuItemClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' 
        });
      };

  return (
   
   
    <div className="container-fluid text-light bg-dark " >
        
           <div className='row'>
                <div className="col-lg-4 col-md-6 footer-about shadow-lg ml-0 ">
                    <div className="d-flex flex-column btn-light align-items-center justify-content-center text-center h-100 p-4">
                        <Link to="/land" className="navbar-brand">
                         <div className='d-flex'>
            <span>
              <img
                src={logo}
                className="bg-light"
                style={{
                  width: "220px",
                 
                 
                }}
              />
            </span>
            <span> 
            {/* <h2 className="m-1 text-success text-start">₹ecyclePay</h2> */}
            
             </span>
          </div> 
                        </Link>
                        <p className="mt-3 mb-2">Where recycling meets rewards. Turn your scrap into savings while championing a cleaner, greener future. Join us in this eco-conscious journey today.</p>
                        <p className="mt-3 mb-4  " style={{fontSize:'14px',fontWeight:'bold',color:'#73be44'}}>DOWNLOAD THE APP</p>
                        <div className='d-flex justify-content-between '>
                        <Link to="https://apps.apple.com/in/app/recycle-pay/id6470977360" target="_blank"><img src={Applogo} style={{ width: '90%' }} /></Link>
                        <Link to="https://play.google.com/store/apps/details?id=com.recyclepay.customerapp" target="_blank"> <img src={Playlogo} style={{ width: '90%' }} /></Link></div>
                    </div>
                </div>
                <div className="col-lg-8 col-md-6 shadow-lg">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 pt-5 mb-5 " >
                            <div className="section-title section-title-sm position-relative pb-3 mb-2">
                                <h5 className="mb-0" style={{color:'#73be44'}}>Get In Touch</h5>
                            </div>
                            {/* <div className="d-flex mb-2" style={{fontSize:'14px'}}>
                                <i className="fas fa-map-marker-alt text-light me-2 mt-1 " ></i>
                                <p className='mr-3' >South Block, Fourth Floor, New OCAC Towers, Acharya Vihar, Bhubaneswar, Odisha 751013</p>
                            </div> */}
                            <div className="d-flex mb-2" style={{fontSize:'14px'}}>
                                <i className="fa fa-envelope text-light me-2 mt-1"></i>
                                <p>support@ceibagreen.com</p>
                            </div>
                            <div className="d-flex mb-2" style={{fontSize:'14px'}}>
                                <i className="fa fa-phone text-light me-2 mt-1"></i>
                                <p className="mb-0">+91 7655892808</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 pt-0 pt-lg-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-2">
                                <h5 className="mb-0" style={{color:'#73be44'}}>Follow Us</h5>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start"  style={{fontSize:'14px'}}>
                                {/* <a href='https://twitter.com/Ceiba_Green?s=20' className="text-light mb-2" ><i className="fab fa-twitter fw-normal me-2"></i>Twitter</a> */}
                                <a href='https://www.facebook.com/profile.php?id=61552223293234&mibextid=JRoKGi' className="text-light mb-2" ><i className="fab fa-facebook-f fw-normal me-2"></i>Facebook</a>
                                <a href='https://instagram.com/recycle_pay?igshid=OGQ5ZDc2ODk2ZA==' className="text-light mb-2" ><i className="fab fa-instagram fw-normal me-2"></i>Instagram</a>
                                {/* <a href='https://www.linkedin.com/company/ceiba-green-solutions/?originalSubdomain=in' className="text-light mb-2" ><i className="fab fa-linkedin-in fw-normal me-2"></i>LinkedIn</a> */}
                                {/* <Link className="text-light mb-2" ><i className="fab fa-youtube fw-normal me-2"></i>YouTube</Link> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 pt-0 pt-lg-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-2">
                                <h5 className="mb-0" style={{color:'#73be44'}}>Quick Links</h5>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start" style={{fontSize:'14px'}}>
                                <Link to='/land' className="text-light mb-2" onClick={handleMenuItemClick}><i className="fa fa-arrow-right text-light me-2"></i>Home</Link>
                                <Link to='/land/rates' className="text-light mb-2" onClick={handleMenuItemClick}><i className="fa fa-arrow-right text-light me-2"></i>Pricelist</Link>
                                {/* <Link to='/' className="text-light mb-2" onClick={handleMenuItemClick}><i className="fa fa-arrow-right text-light me-2"></i>About Us</Link> */}
                                <Link to='/land/contact' className="text-light mb-2" onClick={handleMenuItemClick}><i className="fa fa-arrow-right text-light me-2"></i>Contact Us</Link>
                                <Link to='/land/term-services' className="text-light mb-2" onClick={handleMenuItemClick}><i className="fa fa-arrow-right text-light me-2"></i>Terms and Services</Link>
                                <Link to='/land/privacypolicy' className="text-light mb-2" onClick={handleMenuItemClick}><i className="fa fa-arrow-right text-light me-2"></i>Privacy Policy</Link>
                                <Link to='/land/datadeletion' className="text-light mb-2" onClick={handleMenuItemClick}><i className="fa fa-arrow-right text-light me-2"></i>Data Deletion Instructions</Link>

                            </div>
                        </div>
                    </div>

                    <div className="container-fluid text-white bg-dark">
        <div className="container text-center mb-2">
            <div className="row justify-content-end">
                <div className="col-lg-8 col-md-6">
                    <div className="d-flex align-items-center justify-content-center" style={{fontSize:'12px'}}>
                        <p className="mb-0">&copy; <a className="border-bottom " style={{color:'#73be44'}}>Recycle Pay</a>. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
                </div>
                </div>
       
    </div>
    
   
  )
}
