import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { BASE_URL_API } from '../environment';
import Swal from 'sweetalert2';


export const Contact = () => {

    const [mail, setMail]=useState("");
    const [name, setName]=useState("");
    const [subject, setSubject]=useState("");
    const [message, setMessage]=useState("");

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      //feedback function in contact page
      const handleMenuItemClick = () => {
        if (!name || !mail || !message || !subject) {
          swalWithBootstrapButtons.fire({
            title: "Error",
            text: "All fields are required!",
            icon: "error",
          });
          return;
        }
      
        axios
          .post(BASE_URL_API + 'feedback/', {
            "name": name,
            "email": mail.trim(),
            "subject": subject,
            "message": message
          })
          .then((res) => {
            console.log(res);
            if (res.data.isSuccess === true) {
              setName('');
              setMail('');
              setSubject('');
              setMessage('');
              swalWithBootstrapButtons.fire({
                title: "Success",
                text: "Message Send Successfully",
                icon: "Success",
              });
            } else {
              swalWithBootstrapButtons.fire({
                title: "Error",
                text: "An Error occurred! Try again",
                icon: "error",
              });
            }
          })
          .catch((err) => {
            swalWithBootstrapButtons.fire({
              title: "Error",
              text: "An Error occurred! Try again",
              icon: "error",
            });
            console.log(err);
          });
      
          //it is for scrolling up
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
      
  return (
    <>
    <div class="container-fluid bg-success py-5 bg-header" >
            <div class="row py-5">
                <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                    <h1 class="display-4 text-white animated zoomIn">Contact Us</h1>
                    <Link to='/' class="h5 text-white">Home</Link>
                    <i class="text-white px-2">/</i>
                    <a class="h5" style={{color:'#73be44'}}>Contact</a>
                </div>
            </div>
        </div>
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
            <h5 class="fw-bold text-uppercase" style={{color:'#73be44'}}>Contact Us</h5>
            <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
        </div>
        <div class="row g-5 mb-5 justify-content-between">
            <div class="col-lg-4">
                <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                    <div class="d-flex align-items-center justify-content-center rounded"
                        style={{width: "60px", height: "60px", background:"#73be44"}}>
                        <i class="fa fa-phone-alt text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Call to ask any question</h5>
                        <h4 class="mb-0" style={{color:'#73be44'}}>+91 7655892808</h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                    <div class=" d-flex align-items-center justify-content-center rounded"
                        style={{width: "60px", height: "60px", background:"#73be44"}}>
                        <i class="fa fa-envelope-open text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Email if have any query</h5>
                        <h4 class="mb-0" style={{color:'#73be44'}}>support@ceibagreen.com</h4>
                    </div>
                </div>
            </div>
            {/* <div class="col-lg-4">
                <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                    <div class="bg-success d-flex align-items-center justify-content-center rounded"
                        style={{width: "60px", height: "60px"}}>
                        <i class="fa fa-map-marker-alt text-white"></i>
                    </div>
                    <div class="ps-4">
                        <h5 class="mb-2">Visit our office</h5>
                        <h4 class="text-success mb-0">123 Street, NY, USA</h4>
                    </div>
                </div>
            </div> */}
        </div>
        <div class="row g-5">
            <div class="wow slideInUp" data-wow-delay="0.3s">
                <form>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <input type="text" class="form-control border-0 bg-light px-4" placeholder="Your Name" value={name} onChange={(e)=>setName(e.target.value)}
                                style={{height: "55px"}}/>
                        </div>
                        <div class="col-md-6">
                            <input type="email" class="form-control border-0 bg-light px-4" placeholder="Your Email" value={mail} onChange={(e)=>setMail(e.target.value)}
                                style={{height: "55px"}}/>
                        </div>
                        <div class="col-12">
                            <input type="text" class="form-control border-0 bg-light px-4" placeholder="Subject" value={subject} onChange={(e)=>setSubject(e.target.value)}
                                style={{height: "55px"}}/>
                        </div>
                        <div class="col-12">
                            <textarea class="form-control border-0 bg-light px-4 py-3" rows="4" value={message} onChange={(e)=>setMessage(e.target.value)}
                                placeholder="Message"></textarea>
                        </div>
                        <div class="col-12">
                            <button class="btn w-100 py-3 text-white" onClick={handleMenuItemClick} type="submit" style={{background:"#73be44"}}>Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</>
  )
}
