import React from 'react'
import { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {BASE_URL_API} from '../environment.js';
import '../css/Login.css'
import axios from 'axios'
import Swal from 'sweetalert2';
// import Loginimg from '../img/LoginImg.jpeg';
 import logo from '../img/LogoRP.png';

export const Login = () => {

  const nav = useNavigate();
	const [Email, setEmail] = useState();
	const [Pass, setPass] = useState();

  const HandleSubmit = async(e)=>{
    e.preventDefault();
    try{
      await axios.post(BASE_URL_API+'users/login',
        {email:Email, password:Pass})
        .then(res=>{
          // console.log(res.data._id);
          localStorage.setItem('token', res.data._id);
          if(res.data.usertype==="admin"){
            nav('/dashboard');
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong! This Admin does not exist.',
              
            })
          }
        })
        
      }
      catch(err) { 
        if(err){
          // setError(err.data)
          console.log(err);
        }
      }
  };

  return (
    <div id="logbody" className='d-flex justify-content-center align-items-center bg-light ' 
    style={{
      height: '100vh',
      // backgroundImage: `url(${Loginimg})`, 
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      
    }}>
    <div className="container-fluid  ">
      <div className="login-container col-lg-3 col-sm-6 col-md-5 " id="logcontainer" style={{opacity:'0.9'}}>
        <div className='d-flex justify-content-center mb-4'><img src={logo} alt="" style={{width:'120px', height:'80px'}} /></div>
        {/* <h2 className="mb-4 text-center text-success mt-3">Login</h2> */}
        <form>
          <div className="form-group">
            <label htmlFor="username " className='text-success font-weight-bold'>Username</label>
            <input type="text" className="form-control" id="username" onChange={(e) => setEmail(e.target.value)} autoComplete='off' placeholder="Enter your username" />
          </div>
          <div className="form-group">
            <label htmlFor="password" className='text-success font-weight-bold'>Password </label>
            <input type="password" className="form-control" id="password" onChange={(e) => setPass(e.target.value)} placeholder="Enter your password" />
          </div>
          <br/><button type="submit" className="btn btn-primary btn-block mb-2 mt-0" onClick={HandleSubmit}>Login</button>
          <label>
        <input type="checkbox" checked="checked" name="remember"/> Remember me
      </label>
        </form>
      </div>
    </div>
  </div>
  
  )
}
