import React from 'react'
import { NavLink,Link } from 'react-router-dom'
import logo1 from '../img/logo01.svg';

export const Landnavbar = () => {
    const token=localStorage.getItem('token');
    const bread = () => {
        let nav = document.querySelector('#navbarCollapse');
        nav.classList.toggle('collapse');
        nav.classList.toggle('show');
    }

    const handleMenuItemClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' 
        });
      };
    return (
      <nav className="navbar navbar-expand-lg navbar-dark px-4 py-3 py-lg-0 sticky-top bg-light" >
        <Link to="/" className="navbar-brand p-0">
          <div className='d-flex'>
            <span>
              <img
                src={logo1}
                className="rounded-circle bg-white"
                style={{
                  width: "50px",
                  height: "50px",
                  borderColor: "green",
                  
                }}
              />
            </span>
            &nbsp;
            <span>
              <h1 className="m-0 "><span style={{color:"#3f4040"}}>Recycle </span><span style={{color:"#73be44"}}>Pay</span></h1>
            </span> 
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          onClick={bread}
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="navbar-collapse collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <NavLink
              className="nav-item nav-link " style={{color:"#73be44"}}
              to="/land"
              onClick={handleMenuItemClick}
            >
              Home
            </NavLink>
            <NavLink
              className="nav-item nav-link " style={{color:"#73be44"}}
              to="rates"
              onClick={handleMenuItemClick}
            >
              Pricelist
            </NavLink>
            <NavLink
              className="nav-item nav-link " style={{color:"#73be44"}}
              to="contact"
              onClick={handleMenuItemClick}
            >
              Contact Us
            </NavLink>
          </div>
          {/* {token?(
                <Link to="/dashboard" className="btn btn-success py-2 px-4 ms-3">Go to Dashboard &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></Link>
                ):(
                <Link to="/login" className="btn btn-success py-2 px-4 ms-3">Login &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></Link>
                )} */}
        </div>
      </nav>
    );
}
