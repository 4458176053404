import React from "react";
// import "./Dash.css";
import ReactApexChart from "react-apexcharts";
import Logo from "../img/WebLogo.svg";
import truck from "../img/Trucks.png";
import plastic from '../img/plastic.png';
import paper from '../img/paper.png';
import metal from '../img/metal.png';
import glass from '../img/glass.png';

const PieChartPage = () => {
  const chartOptions = {
    series: [75],
    chart: {
      height: 242,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px'
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: '#111',
            fontSize: '36px',
            show: true,
          }
        }
      }
    },
    fill: {
      colors: ['#73b344'],
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#73b344'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Percent'],
    tooltip: {

      enabled: true  // Enable tooltip
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="radialBar"
        height={chartOptions.chart.height}
      />
    </div>
  );
};

const AreaChart = () => {
  const series = [{
    name: "STOCK ABC",
    data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
  }];

  const options = {
    series,
    chart: {
      type: 'area',
      height: 172,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      colors: ['#73be44'],  // Set the fill color to green
      // type: 'solid',
      // opacity: 0.7,
    },
    stroke: {
      curve: 'straight',
      colors: ['#73be44'],  // Set the stroke color to green
      width: 2,
    },
    markers: {
      size: 5,
      colors: ['#73be44'], 
      hover: {
        size: 9
      }
    },
    // title: {
    //   text: 'Fundamental Analysis of Stocks',
    //   align: 'left'
    // },
    // subtitle: {
    //   text: 'Price Movements',
    //   align: 'left'
    // },
    tooltip: {
      theme: 'light', 
      x: {
        show: true,
        format: 'dd MMM yyyy',  
      },
      marker: {
        show: true,
        fillColors: ['#73be44'],  // Set the marker color inside the tooltip to green
      },
      
    },
    labels: ['2022-01-01', '2022-01-02', '2022-01-03', '2022-01-04', '2022-01-05', '2022-01-06', '2022-01-07', '2022-01-08', '2022-01-09'],
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      opposite: false
    },
    legend: {
      horizontalAlign: 'left'
    }
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={options.chart.height}
      />
    </div>
  );
};

function OrgDash() {
    return (
        <div className="container-fluid bg-white" style={{ minheight: "100vh" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between ">
                <div className="mt-4 px-5">
                  <p
                    className="mb-0"
                    style={{ fontWeight: "bold" }}
                    // style={{ fontFamily: "Times New Roman" }}
                  >
                    Hello,
                  </p>
                  <h2>
                    <span
                      style={{
                        color: "#73be44",
                        // fontFamily: "Times New Roman",
                        fontWeight: "bolder",
                      }}
                    >
                      Organisation Name, Address
                    </span>
                  </h2>
                  <p
                    style={{
                      fontWeight: "bold",
                      // fontFamily: "Times New Roman"
                    }}
                  >
                    Welcome to Recycle Pay Carbon-footprint
                  </p>
                </div>
                <div className="mt-4 px-4">
                  <figure>
                    <img
                      className="img-responsive img-circle img-thumbnail img-user "
                      src={Logo}
                      alt="Logo"
                      style={{ width: "200px", border: "none" }}
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-4">
            <div className="col-md-6 mb-3 px-2">
              <div className="card bg-gray text-center rounded-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      {/* <div
                        className="col-12 text-center"
                        style={{
                          fontWeight: "bold",
                          // fontFamily: "Times New Roman",
                        }}
                      >
                        Recycle Year to
                        <br /> data
                      </div> */}
                      <div className="col-12 text-center">
                        <img src={truck} alt="img" className="img-fluid" style={{ width: "200px", height: "197px"}}/>
                      </div>
                      <div className="col-12 px-3">
                        <p
                          style={{
                            fontWeight: "bold",
                            // fontFamily: "Times New Roman",
                          }}
                          className="mb-0"
                        >
                          You have Prevented : <br />
                          <span style={{ color: "#73be44" }}>1930 T</span>
                        </p>
                      </div>
                    </div>
    
                    {/* Right column with 3 divs in a grid */}
                    <div className="col-md-6">
                      <div className="border shadow m-4 p-2 rounded-4">
                        <div
                          className="border "
                          style={{ backgroundColor: "rgba(115, 190, 64, 0.2)", borderRadius: "10px" }}
                        >
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              Sent For recycling
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                1550 T
                              </span>
                            </p>
                          </div>
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              Not recyclable
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                130 T
                              </span>
                            </p>
                          </div>
    
                          <div className="col-12 text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              Total recyclable
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                1420 T
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3 px-2">
              <div class="card bg-gray text-center rounded-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="col-12 text-center"
                        style={{
                          fontWeight: "bold",
                          // fontFamily: "Times New Roman",
                        }}
                      >
                        Pickup Status
                      </div>
                      <div className="col-12 text-center">
                        <PieChartPage />
                      </div>
                    </div>
    
                    {/* Right column with 3 divs in a grid */}
                    <div className="col-md-6">
                      <div className="border shadow m-4 p-2 rounded-4">
                        <div
                          className="border  "
                          style={{ backgroundColor: "rgba(115, 190, 64, 0.2)", borderRadius:"10px" }}
                        >
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              Sent For recycling
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                1550 T
                              </span>
                            </p>
                          </div>
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              Not recyclable
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                130 T
                              </span>
                            </p>
                          </div>
    
                          <div className="col-12 text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              Total recyclable
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                1420 T
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <div class="row mb-5 mx-4">
            <div className="col-md-6 mb-3 px-2">
              <div className="card bg-gray text-center rounded-0">
                <div
                  className="card-header rounded-0 text-white"
                  style={{ backgroundColor: "#73be44" }}
                >
                  <b>Recycled By Category</b>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-3">
                          <div
                            className="col-12 text-center mb-3"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Paper
                          </div>
                          <div className="col-12 text-center">
                            <img src={paper} alt="Paper" className="img-fluid rounded-circle p-1" 
                              style={{
                              height: "75px",
                              width: "75px",
                              borderWidth: "3px",  
                              borderColor: "#73be44", 
                              borderStyle: "double", 
                            }}
                            />
                          </div>
                          <div className="col-12 px-3 mt-3">
                            <p
                              style={{
                                fontWeight: "bold",
                                color: "#73be44",
                              }}
                              className="mb-0"
                            >
                              1930 Kg
                            </p>
                          </div>
                        </div>
                        <div className="col-3">
                          <div
                            className="col-12 text-center mb-3"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Plastic
                          </div>
                          <div className="col-12 text-center">
                            <img src={plastic} alt="plastic" className="img-fluid rounded-circle p-1" 
                              style={{
                              height: "75px",
                              width: "75px",
                              borderWidth: "3px",  
                              borderColor: "#73be44", 
                              borderStyle: "double", 
                            }}
                            />
                          </div>
                          <div className="col-12 px-3 mt-3">
                            <p
                              style={{
                                fontWeight: "bold",
                                color: "#73be44",
                              }}
                              className="mb-0"
                            >
                              1930 Kg
                            </p>
                          </div>
                        </div>
                        <div className="col-3">
                          <div
                            className="col-12 text-center mb-3"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Metal
                          </div>
                          <div className="col-12 text-center">
                            <img src={metal} alt="metal" className="img-fluid rounded-circle p-1" 
                              style={{
                              height: "75px",
                              width: "75px",
                              borderWidth: "3px",  
                              borderColor: "#73be44", 
                              borderStyle: "double", 
                            }}
                            />
                          </div>
                          <div className="col-12 px-3 mt-3">
                            <p
                              style={{
                                fontWeight: "bold",
                                color: "#73be44",
                              }}
                              className="mb-0"
                            >
                              1930 Kg
                            </p>
                          </div>
                        </div>
                        <div className="col-3">
                          <div
                            className="col-12 text-center mb-3"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Glass
                          </div>
                          <div className="col-12 text-center">
                            <img src={glass} alt="glass" className="img-fluid rounded-circle p-1" 
                              style={{
                              height: "75px",
                              width: "75px",
                              borderWidth: "3px",  
                              borderColor: "#73be44", 
                              borderStyle: "double", 
                            }}
                            />
                          </div>
                          <div className="col-12 px-3 mt-3">
                            <p
                              style={{
                                fontWeight: "bold",
                                color: "#73be44",
                              }}
                              className="mb-0"
                            >
                              1930 Kg
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3 px-2">
              <div class="card bg-gray text-center rounded-0">
                <div
                  className="card-header rounded-0 text-white"
                  style={{ backgroundColor: "#73be44" }}
                >
                  <b>CO₂ Emission per year</b>
                </div>
                <div className="card-body p-0">
                  <AreaChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default OrgDash
