import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import logo from '../img/WebLogo.svg';
import axios from 'axios';
import { BASE_URL_API } from '../environment';
function Refcode() {
  const { code } = useParams();
  const [mobileNumber, setMobileNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); 
  const [exist, setExist] = useState(false);
  //function for checking number exist or not
  const handleMobileNumberChange = (event) => {
    const { value } = event.target;
    setMobileNumber(value);
    setIsButtonDisabled(value.length !== 10); 
    if (value.length === 10) {
      axios.get(`${BASE_URL_API}customers/check/${value}`)
        .then(response => {
          if (response.data.length > 0) {
            setErrorMessage('** Number already exists. You cannot use a referral code.');
            setIsButtonDisabled(false); 
            setExist(true);
          } else {
            setExist(false);
            setErrorMessage('');
            setIsButtonDisabled(false); 
          }
        })
        .catch(error => {
          console.error('Error fetching customer data:', error);
          setErrorMessage('Error checking mobile number. Please try again.');
          setIsButtonDisabled(true);
        });
    } else {
      setErrorMessage('');
    }
  };

  //function for redirect of page
  const handleContinueToApp = () => {
    if (exist === true) {
      // window.location.assign('https://bit.ly/re-pay');
      document.getElementById("open_recyclepay").click()
    } else {
      axios.post(BASE_URL_API+'customers/redirect', {
        mobile: mobileNumber,
        useRefCode: code
      })
      .then(response => {
        // console.log('Redirect API response:', response);
        if (response.data.isSuccess === true) {
        // window.location.href = 'https://bit.ly/re-pay'; 
        document.getElementById("open_recyclepay").click()
        }
      })
      .catch(error => {
        console.error('Error redirecting:', error);
      });
    }
  };
  return (
    // referal code page
    <div className="container-fluid ">
    <div className="row d-flex justify-content-center align-items-center ">
      <div className="row g-0 d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
        <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center ">
          <div className="col-md-5 col-sm-8">
            <form>
              <div className="text-center mb-2">
                <img
                  className="img-responsive img-circle img-thumbnail img-user"
                  src={logo}
                  alt="Logo"
                  style={{ width: "180px", border: "none" }}
                />
              </div>
              <div className="form-outline mb-3 row">
                  <div className='col-3'>
                  <label
                    className="form-label"
                    htmlFor="refCodeInput"
                    style={{ fontSize: "13px", color: "#73be44" }}
                  >
                    RefCode
                  </label>
                  </div>
                  <div className='col-9'>
                  <input
                    type="text"
                    id="refCodeInput"
                    placeholder="RefCode"
                    className="form-control form-control-sm"
                    style={{ borderColor: "#73be44" }}
                    value={code}
                    disabled
                  />
                </div>
                </div>
              <div className="form-outline mb-3">
                <label
                  className="form-label"
                  htmlFor="form2Example17"
                  style={{ fontSize: "13px", color: "#73be44" }}
                >
                  Enter Mobile Number
                </label>
                <input
                  type="tel" 
                  pattern="[0-9]{10}" 
                  placeholder="Mobile Number"
                  id="form2Example17"
                  className="form-control form-control-sm"
                  style={{ borderColor: "#73be44" }}
                  value={mobileNumber}
                  onChange={handleMobileNumberChange}
                  maxLength={10}
                />
              </div>
              {errorMessage && (
                <div className="mt-3 mb-3" style={{ fontSize: "12px", color: "red" }}>
                  {errorMessage}
                </div>
              )}
              <div className="d-grid pt-4 mb-4">
                <button
                  className="btn btn-sm btn-block"
                  style={{ backgroundColor: "#73be44", color: "white" }}
                  type="button"
                  onClick={handleContinueToApp}
                  disabled={isButtonDisabled} 
                >
                  Continue to App
                </button>
                <a href='https://bit.ly/re-pay' target='_blank' id='open_recyclepay'></a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Refcode
