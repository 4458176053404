import React, { useState } from 'react';
import logo from '../img/WebLogo.svg';
import axios from 'axios';
import { BASE_URL_API } from '../environment';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
function CustomerLogin() {
  const navigate = useNavigate();
  const [mobileno, setMobileNO] = useState("");
  const [otp, setOtp] = useState("");
  //function for checking mobilenumber and if present then redirect to pwa
  const handlecheckphone = () => {
    const phone = localStorage.getItem("mobile");
    axios.get(`${BASE_URL_API}customers/check/${phone}`)
        .then(response => {
          if (response.data.length > 0) {
           console.log('** Number already exists. You cannot use a referral code.');
           window.location.href = 'https://bit.ly/re-pay';
          } else {
            console.log("not exit")
            navigate("details");
          }
        })
        .catch(error => {
          console.error('Error fetching customer data:', error);
          
        });
  }

  //verify otp function and if verifued the redirect to pwa
  const verifyOtp = () => {
    axios
      .post(BASE_URL_API + "customers/verifyOTP", {
        mobile: mobileno,
        OTP: otp,
      })
      .then((res) => {
        console.log(res.data);
  if(res.data.message === "Wrong OTP enter."){
    setOtp("");
    Swal.fire({
      icon: "error",
      title: res.data.message,
    });
  }
  else{
        if (res.data.customerDetails && Object.keys(res.data.customerDetails).length > 0) {
          // Existing customer
          // localStorage.setItem("mobile", mobileno);
          window.location.href = 'https://bit.ly/re-pay';
        } else {
          // New customer
          navigate("details");
          // console.log("showAllDetails set to true");
        }
      }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //getting otp after clcik on getotp button
  const getotp = () =>{
    axios
    .post(BASE_URL_API + "customers/login", {
      mobile: mobileno,
    })
    .then((res) => {
      console.log(res.data);
      console.log(res.data.length);
      Swal.fire({
        position: "center",
        icon: "success",
        title: res.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  // after mobile number change set in local storage
  const handleMobileNumberChange = (e) => {
    setMobileNO(e.target.value);
    localStorage.setItem("mobile", e.target.value);
  }
  return (
    // login page
    <div className="container-fluid ">
    <div className="row d-flex justify-content-center align-items-center ">
      <div className="row g-0 d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
        <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center ">
          <div className="col-md-5 col-sm-8">
            <form>
              <div className="text-center mb-2">
                <img
                  className="img-responsive img-circle img-thumbnail img-user"
                  src={logo}
                  alt="Logo"
                  style={{ width: "180px", border: "none" }}
                />
              </div>
              
              <div className="form-outline mb-3">
                <label
                  className="form-label"
                  htmlFor="form2Example17"
                  style={{ fontSize: "13px", color: "#73be44" }}
                >
                  Enter Mobile Number
                </label>
                <input
                  type="tel" 
                  pattern="[0-9]{10}" 
                  placeholder="Mobile Number"
                  id="form2Example17"
                  className="form-control form-control-sm"
                  style={{ borderColor: "#73be44" }}
                  value={mobileno}
                  onChange={handleMobileNumberChange}
                  maxLength={10}
                />
              </div>
              
              <div className='d-flex mb-2'>
                <button
                  className="btn btn-sm"
                  style={{ backgroundColor: "#73be44", color: "white" }}
                  type="button"
                  onClick={getotp}
                >
                  Get OTP
                </button>
              </div>
              
              <div className="form-outline mb-3">
                <label
                  className="form-label"
                  htmlFor="form2Example17"
                  style={{ fontSize: "13px", color: "#73be44" }}
                >
                  Enter OTP
                </label>
                <input
                  type="tel" 
                  pattern="[0-9]{10}" 
                  placeholder="OTP"
                  id="form2Example17"
                  className="form-control form-control-sm"
                  style={{ borderColor: "#73be44" }}
                  maxLength={4}
                  value={otp}
                  onChange={(e) => {setOtp(e.target.value)}}
                />
              </div>
             
              <div className="d-grid pt-4 mb-4">
                <button
                  className="btn btn-sm btn-block"
                  style={{ backgroundColor: "#73be44", color: "white" }}
                  type="button"
                  onClick={verifyOtp}
                >
                  Login
                </button>
                {/* <a href='https://bit.ly/re-pay' target='_blank' id='open_recyclepay'></a> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CustomerLogin
