import React, { useEffect, useState } from "react";
import { BASE_URL_API } from "../environment";
import axios from "axios";
import Chart from 'react-apexcharts';
import "../css/defaultdash.css";
import truck from '../img/Trucks.png';

//charts and graphs
const ColumnChart = ({ data }) => {
  const categories = data.map(entry => entry.month);
  const prices = data.map(entry => parseFloat(entry.price+0.15*entry.price).toFixed(2));

  const options = {
    chart: {
      height: 390,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: '40%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    grid: {
      row: {
        colors: ['#fff', '#E6F8DB'],
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      title: {
        text: 'Date',
      },
      categories: categories,
     
    },
    yaxis: {
      title: {
        text: 'Price',
      },
    },
    series: [
      {
        name: 'Price',
        data: prices,
        color: '#73be44',
      },
    ],
   
  };

  return (
    <Chart options={options} series={options.series} type="bar" height={options.chart.height} className="bg-white" />
  );
};

const ColumnChart1 = ({ data }) => {
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };

  const categories = data.map(entry => formatDate(new Date(entry.date)));
  const prices = data.map(entry => parseFloat(entry.price+0.15*entry.price).toFixed(2));

  const options = {
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    grid: {
      row: {
        colors: ['#fff', '#E6F8DB'],
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      title: {
        text: 'Date',
      
      },
      categories: categories,
     
    },
    yaxis: {
      title: {
        text: 'Price',
      },
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'light',
    //     type: 'horizontal',
    //     shadeIntensity: 0.25,
    //     gradientToColors: undefined,
    //     inverseColors: true,
    //     opacityFrom: 0.85,
    //     opacityTo: 0.85,
    //     stops: [50, 0, 100],
    //   },
    // },
    series: [
      {
        name: 'Price',
        data: prices,
        color: '#73be44',
      },
    ],
   
  };

  return (
    <Chart options={options} series={options.series} type="bar" height={options.chart.height} className="bg-white" />
  );
};

const PieChart = ({ data }) => {
  const options = {
    labels: Object.keys(data),
  };

  const series = Object.values(data);

  return (
    <Chart
      options={options}
      series={series}
      type="pie"
      height={150}
      className="bg-white"
    />
  );
};

const HorizontalBarChart = ({ data }) => {
  const categories = data.map(entry => entry.categoryDetails);
  const prices = data.map(entry => parseFloat(entry.totalAmount+0.15*entry.totalAmount).toFixed(2));

  const options = {
    chart: {
      height: 200,
      type: 'bar',
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        height: '40%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    yaxis: {
      categories: prices,
      title: {
        text: 'Category',
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Price',
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Rs";
        },
      },
    },
  };

  const series = [
    {
      name: 'Total Amount',
      data: prices,
      color: '#73be44',
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height={options.chart.height}
      className="bg-white"
    />
  );
};

const PieChartPage = ({data}) => {
  const chartOptions = {
    series: [parseInt(data)],
    chart: {
      height: 200,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#000',
            fontSize: '17px'
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: '#73be44',
            fontSize: '30px',
            show: true,
          }
        }
      }
    },
    fill: {
      colors: ['#73b344'],
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#73be44'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Percent'],
    tooltip: {

      enabled: false  // Enable tooltip
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Chart
        options={chartOptions}
        series={chartOptions.series}
        type="radialBar"
        height={chartOptions.chart.height}
      />
    </div>
  );
};
export const Dash = () => {
  const [cus, setCus] = useState(null);
  const [cus7, setCus7] = useState(null);
  const [ord, setOrd] = useState(null);
  const [ord7, setOrd7] = useState(null);
  const [est, setEst] = useState("");
  const [est7, setEst7] = useState("");
  const [payment, setPayment] = useState(null);
  const [payment7, setPayment7] = useState(null);
  const [totalqty, setTotalqty] = useState(null);
  const [scsord, setScsord] = useState(null);

  const [chartData1, setChartData1] = useState(null);

  const generateDatesForMonth = (year, month) => {
    const lastDay = new Date(year, month + 1, 0).getDate();
    const datesArray = [];
    
    for (let day = 1; day <= lastDay; day++) {
      const date = new Date(Date.UTC(year, month, day));
      datesArray.push(date.toISOString());
    }

    return datesArray;
  };

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [paymentModes, setPaymentModes] = useState(null);
  const [paymentCat, setPaymentCat] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);
  const [emissionData, setEmissionData] = useState(null);

 const monthNames = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  const emission = [
    {
        "category": "658128707d7c3ecbefb61463",
        "categoryDetails": "Paper",
        "emission": 2.75
    },
    {
        "category": "658137b86fd01cebfc6430ae",
        "categoryDetails": "Metal",
        "emission": 17.2

    },
    {
        "category": "65812f317a1767defb811f7b",
        "categoryDetails": "Plastic",
        "emission": 1.68
    },
    {
        "category": "659e8e9b230e476982c0987b",
        "categoryDetails": "Glass",
        "emission": 0.15
    },
    {
        "category": "658137c76fd01cebfc6430b1",
        "categoryDetails": "E-waste",
        "emission": 17.2
    }
  ];
  //fetching all data for dashboard card and graphs
  const fetchData = () => {
    axios
      .get(BASE_URL_API + "dashboard/cust")
      .then((res) => {
        setCus(res.data.count);
        setCus7(res.data.count7);
      })
      .catch((err) => console.log(err));

    axios
      .get(BASE_URL_API + "dashboard/ord")
      .then((res) => {
        setOrd(res.data.count);
        setOrd7(res.data.count7);
      })
      .catch((err) => console.log(err));

    axios
      .get(BASE_URL_API + "dashboard/est")
      .then((res) => {
        setEst(res.data.count);
        setEst7(res.data.count7);
      })
      .catch((err) => console.log(err));

    axios
      .get(BASE_URL_API + "dashboard/payment")
      .then((res) => {
        setPayment(res.data.count);
        setPayment7(res.data.count7);
      })
      .catch((err) => console.log(err));

      axios
      .get(BASE_URL_API + `dashboard/daily?month=${selectedMonth}&year=${selectedYear}`)
      .then((res) => {
        const datesForMonth = generateDatesForMonth(selectedYear, selectedMonth - 1); 
        const extractedData1 = datesForMonth.map(date => {
          const matchingEntry = res.data.result.find(entry => entry._id.schedule_date_iso === date);
          return {
            date,
            price: matchingEntry ? matchingEntry.totalEstPrice : 0
          };
        });

        setChartData1(extractedData1);
      })
      .catch((err) => console.log(err));

     
        axios
          .get(BASE_URL_API + "dashboard/payavg/all")
          .then((res) => {
            const transformedData = res.data.result.reduce((acc, item) => {
              const mode = item._id.mode;
              const totalEstPrice = item.totalEstPrice;
              acc[mode] = totalEstPrice+0.15*totalEstPrice;
              return acc;
            }, {});
    
            ["Cash", "Eco", "IMPS", "UPI"].forEach((mode) => {
              if (!transformedData[mode]) {
                transformedData[mode] = 0;
              }
            });
    
            setPaymentModes(transformedData);
          })
          .catch((err) => console.log(err));
     
          axios
          .get(BASE_URL_API + `dashboard/monthly6/${new Date().getFullYear()}`)
          .then((res) => {
            const dataFromApi = res.data.result;
            const data = dataFromApi.length > 0
              ? Array.from({ length: 6 }, (_, index) => {
                  const currentDate = new Date();
                  const targetDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - index, 1);
                  
                  const matchingEntry = dataFromApi.find(entry => {
                    const entryDate = new Date(entry._id.year, entry._id.month - 1, 1);
                    return entryDate.getTime() === targetDate.getTime();
                  });
        
                  return {
                    month: `${(targetDate.getMonth() + 1).toString().padStart(2, '0')}-${targetDate.getFullYear().toString()}`,
                    price: matchingEntry ? matchingEntry.totalEstPrice : 0,
                  };
                }).reverse() // Reverse the array to have the data in chronological order
              : Array.from({ length: 6 }, (_, index) => {
                  const currentDate = new Date();
                  const targetDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - index, 1);
                  
                  return {
                    month: `${(targetDate.getMonth() + 1).toString().padStart(2, '0')}-${targetDate.getFullYear()}`,
                    price: 0,
                  };
                }).reverse();
        
            setMonthlyData(data);
          })
          .catch((err) => console.log(err));
        
          axios
            .get(BASE_URL_API + "dashboard/catitemavg")
            .then((res) => {
              setPaymentCat(res.data);
            })
            .catch((err) => console.log(err)); 

          axios
          .get(BASE_URL_API + "footprint/overalwst")
          .then((res) => {
            setTotalqty(res.data.result[0].totalWeight);
          })
          .catch((err) => console.log(err)); 

          axios
          .get(BASE_URL_API + "orders/successfulOrders")
          .then((res) => {
            setScsord(res.data.data);
          })
          .catch((err) => console.log(err)); 
          axios.get(BASE_URL_API + "dashboard/catitemavg")
      .then((res) => {
        const paymentCat = res.data;
        
        // Calculate emissions for each category
        const categoryEmissions = paymentCat.result.map(category => {
          const emissionValue = emission.find(item => item.category === category.category)?.emission || 1;
          const categoryEmission = category.totalWeight * emissionValue;
          return {
            categoryId: category.category,
            categoryEmission
          };
        });

        // Calculate total emissions
        const totalEmissions = categoryEmissions.reduce((total, category) => total + category.categoryEmission, 0);

        setEmissionData(totalEmissions);
      })
      .catch((err) => console.log(err));
  };



  useEffect(() => {
    fetchData();
  }, [selectedMonth, selectedYear]);

 

  return (
    <div className="container">
      {/* showing data in card */}
     <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2">
        <div className="col">
          <div className="card radius-10 border-start border-0 border-3 border-info mt-2">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0" style={{color:"#73be44"}}><b>Total Orders</b></p>
                  <h4 className="my-1 text-info">{parseInt(ord+(0.15*ord))}</h4>
                  <p className="mb-0 " style={{ fontSize: "14px", color:"#000000" }}>
                    + <b className="text-info">{parseInt(ord7+(0.15*ord7))}</b> New Order from last 7
                    days
                  </p>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                  <i className="fa fa-shopping-cart" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card radius-10 border-start border-0 border-3 border-success mt-2">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0" style={{color:"#73be44"}}><b>Total Payment</b></p>
                  <h4 className="my-1 text-success">
                  {payment !== null ? parseFloat(payment+0.15*payment).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}
                  </h4>
                  <p className="mb-0 " style={{ fontSize: "14px", color:"#000000"  }}>
                    +{" "}
                    <b className="text-success">
                      {payment7 !== null
                        ? parseFloat(payment7+0.15*payment7).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        : "0.00"}{" "}
                      Rs
                    </b>{" "}
                    Payment paid in 7 days
                  </p>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                  <i className="fa fa-bar-chart" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card radius-10 border-start border-0 border-3 border-danger mt-2">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0" style={{color:"#73be44"}}><b>Total Profit</b></p>
                  <h4 className="my-1 text-danger">
                    {(0.25 * payment+0.15*0.25*payment).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </h4>
                  <p className="mb-0 " style={{ fontSize: "14px", color:"#000000"  }}>
                    +{" "}
                    <b className="text-danger">
                      {(
                        ((0.25 * payment7+0.15*0.25*payment7).toFixed(2) * 100) /
                        (0.25 * payment+0.15*0.25*payment)
                      ).toFixed(2)}
                      %
                    </b>{" "}
                    Profit from last 7 days
                  </p>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                  <i className="fa fa-rupee" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card radius-10 border-start border-0 border-3 border-warning mt-2">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 " style={{color:"#73be44"}}><b>Total Customers</b></p>
                  <h4 className="my-1 text-warning">{cus}</h4>
                  <p className="mb-0 " style={{ fontSize: "14px", color:"#000000"  }}>
                    + <b className="text-warning">{cus7}</b> New Customer from
                    last 7 days
                  </p>
                </div>
                <div className="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto">
                  <i className="fa fa-users" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
            <div className="col-md-6">
              <div className="card bg-gray text-center ">
                <div className="card-body row">
                    <div className="col-md-6 ">
                      {/* <div className="col-12 text-center"  style={{ fontWeight: "bold", fontSize: "14px",}}>
                        Recycle Year to
                        <br /> data
                      </div> */}
                      <div className="col-12 text-center mt-4">
                        <img src={truck} alt="Your Image" className="img-fluid" style={{ width: "150px", height: "150px" }} />
                      </div>
                      <div className="col-12 px-3 mt-2 mb-1">
                        <p style={{ fontWeight: "bold",  fontSize: "14px",}} className="mb-0">
                          Total Distance Covered <br />
                          <span style={{ color: "#73be44" }}>11549 Km</span>
                        </p>
                      </div>
                    </div>
    
                    {/* Right column with 3 divs in a grid */}
                    <div className="col-md-6">
                        <div className="border" style={{ backgroundColor: "#E6F8DB", borderRadius: "10px" }} >
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              Volume of Waste Recycled
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                               {parseFloat(totalqty).toLocaleString('en-IN', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Kg
                              </span>
                            </p>
                          </div>
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                             Total Carbon Saving
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                {parseInt(emissionData).toLocaleString('en-IN')} Kg of CO₂
                              </span>
                            </p>
                          </div>
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              No of Trees Saved
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                {parseInt(emissionData/4).toLocaleString('en-IN')}
                              </span>
                            </p>
                          </div>
                          <div className="col-12 text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              No of Lives Impacted
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                {parseInt(ord)*10} 
                              </span>
                            </p>
                          </div>

                        </div>
                    </div>
                 
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card bg-gray text-center">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="col-12 text-center"
                        style={{
                          fontWeight: "bold",fontSize: "14px" }}>
                        Pickup Status
                      </div>
                      <div className="col-12 text-center">
                        <PieChartPage data={(scsord+0.15*scsord)*100/(ord+0.15*ord)}/>
                      </div>
                    </div>
    
                    {/* Right column with 3 divs in a grid */}
                    <div className="col-md-6">
                     
                        <div className="border" style={{ backgroundColor: "#E6F8DB", borderRadius:"10px" }}>
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              No of Scheduled Pickups
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                {parseInt(scsord+0.15*scsord)} 
                              </span>
                            </p>
                          </div>
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              No of Collection Drives
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                 40+ 
                              </span>
                            </p>
                          </div>
                          <div className="col-12 border-gray shadow-sm border-bottom text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              No of Partners
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                                30+ 
                              </span>
                            </p>
                          </div>
    
                          <div className="col-12 text-center">
                            <p
                              className="mt-2 mb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "#73be44",
                              }}
                            >
                              App Downloads
                              <br />
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "#000000",
                                }}
                              >
                               1400+ 
                              </span>
                            </p>
                          </div>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* charts and graphs */}
          <div className="row mt-3" style={{ display: "flex" }}>
            <div className="col-md-7" style={{ display: "flex", flexDirection: "column" }}>
              <div className="card bg-gray text-center flex-fill">
                <div className="card-body row">
                  <h6 className="card-header bg-white" style={{ color: "#73be44" }}>
                    Last 6 Months Purchase
                  </h6>
                  <div className="card-body">
                    {monthlyData && <ColumnChart data={monthlyData} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5" style={{ display: "flex", flexDirection: "column" }}>
              <div className="card bg-gray text-center flex-fill">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <h6 className="card-header bg-white" style={{ color: "#73be44" }}>
                        Payment Mode Wise Status
                      </h6>
                      <div className="card-body">
                        {paymentModes && <PieChart data={paymentModes} />}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <h6 className="card-header bg-white" style={{ color: "#73be44" }}>
                        Category Wise Purchase
                      </h6>
                      <div className="card-body">
                        {paymentCat && <HorizontalBarChart data={paymentCat.result} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


      <div className="card mt-3">
        <div className="card-header bg-white d-flex justify-content-between ">
          <h6 style={{color:"#73be44"}}>Date Wise Purchase</h6>
         <div className="d-flex">
           <select className="form-select form-select-sm text-success border border-success" style={{width:"auto"}} aria-label=".form-select-sm example" value={selectedMonth} onChange={(e) => setSelectedMonth(parseInt(e.target.value))}>
           {monthNames.map((month, index) => (
          <option key={index + 1} value={index + 1}>
            {month}
          </option>
        ))}
      </select>
      <select className="form-select form-select-sm text-success border border-success" aria-label=".form-select-sm example" value={selectedYear} onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
        {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map((year) => (
          <option key={year} value={year}> {year} </option>))}
      </select></div>
        </div>

        <div className="card-body">
          {chartData1 && <ColumnChart1 data={chartData1} />}          
        </div>
      </div>
    </div>
  );
};
