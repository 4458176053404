import React from 'react'
import { Landnavbar } from './Landnavbar'
import { Landfooter } from './Landfooter'
import '../css/Landing.css'
import { Outlet } from 'react-router-dom'

export const Landingpage = () => {
  return (
    <>
     {/* <div id='landbody'> */}
    <div className="container-fluid position-relative p-0">
    <Landnavbar/>
    </div>
    {/* <div id='landcontain'> */}
    <Outlet />
    <Landfooter/>
    </>
  )
}
