import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL_API } from "../environment";
import RpLogo from "../img/sidebarLogo.svg";
import PaidLogo from "../img/paidlogo.png";
function PrintReceipt() {
  var params = useParams();
  const Navigate =useNavigate();
  const pout = localStorage.getItem("pout");
  const [orderData, setOrderData] = useState([]);
  const [payDetails, setPayDetails] = useState([]);
  const [accountName, setAccountName] = useState("");
  useEffect(() => {
    fetchData();
  }, [params.id]);

  //validating api api function
  const validateUpi = (upiid) => {
    axios
      .post('https://shop.ceibagreen.com/wp-json/custom-api/v1/validate/vpa', { vpa: upiid})
      .then((res) => {
        console.log(res.data);
        setAccountName(res.data.customer_name);
      })
      .catch((err) => console.log(err));
  }
  //fetching all data
  const fetchData = () => {
    axios
      .get(BASE_URL_API + "orders/indhistorder/" + params.id)
      .then((res) => {
        setOrderData(res.data);
        if (res.data.result && res.data.result[0] && res.data.result[0].payment && res.data.result[0].payment.vpa && res.data.result[0].payment.vpa.address) {
          validateUpi(res.data.result[0].payment.vpa.address);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(BASE_URL_API + "orders/razReceipt/" + pout)
      .then((res) => {
        console.log(res.data);
        setPayDetails(res.data);
        if (res.data.data1 && res.data.data1.vpa && res.data.data1.vpa.address) {
          validateUpi(res.data.data1.vpa.address);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //function for calculating total price
  const calculateSubtotal = (items) => {
    if (!Array.isArray(items)) return 0;

    return items.reduce((acc, item) => {
      const itemPrice = parseFloat(item.price) || 0;
      const weight = parseFloat(item.weight) || 0;
      return acc + itemPrice * weight;
    }, 0);
  };

  const subtotal = calculateSubtotal(orderData?.data?.result[0]?.item);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleString("en-US", options);
  };

  const printReceipt = () => {
    window.print();
  };
  //navigate to previous page
  const goBack = () => {
    Navigate(-1);
  }
  return (
    <div>
      {/* showing all data for receipt */}
      <style>
        {`
          @media print {
            .print.float-start.d-flex {
              display: none !important;
            }
            .print.float-end.d-flex {
              display: none !important;
            }
          }
        `}
      </style>
      <div className="receipt-content">
        <div className="container bootstrap snippets bootdey">
          <div className="row">
            <div className="col-md-12">
              <div className="invoice-wrapper">
                <div className="payment-info">
                  <div className="row">
                    <div className="col-sm-6">
                      <img src={RpLogo} className="m-1" />
                    </div>
                    <div className="col-sm-6 text-right">
                      <span
                        style={{
                          color: "#73be44",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        PAID ON
                      </span>
                      <strong style={{ fontSize: "14px" }}>
                      {/* {payDetails?.data1?.created_at ? formatDate(payDetails?.data1?.created_at) : orderData?.data?.result[0]?.schedule_date} */}
                      {orderData?.data?.result[0]?.schedule_date}
                      </strong>
                    </div>
                  </div>
                  <div className="payment-details">
                    <div className="row">
                      <strong style={{ fontSize: "20px", fontWeight: "bold" }}>
                        Payment Receipt
                      </strong>
                    </div>
                    <div className="row">
                      <div className="row">
                      <div>
                          <div
                            className="mb-2 mt-2"
                            style={{
                              fontSize: "13px",
                              color: "#73be44",
                              fontWeight: "bold",
                            }}
                          >
                            ORDER ID : {orderData?.data?.result[0]?.order_id}
                          </div>
                        </div>
                        <div>
                          <div
                            className="mb-2 mt-2"
                            style={{
                              fontSize: "13px",
                              color: "#73be44",
                              fontWeight: "bold",
                            }}
                          >
                            AMOUNT PAID : ₹{(payDetails?.data?.amount && !isNaN(payDetails.data.amount) ? (payDetails.data.amount / 100).toFixed(2) : subtotal.toFixed(2))}
                          </div>
                        </div>
                        <div className="col-sm-8 line-items">
                          <div>
                            <strong>
                              <span
                                className="text-bold"
                                style={{ color: "#73be44" }}
                              >
                                Customer Details:
                              </span>
                            </strong>
                            <strong>
                              {orderData?.data?.name}
                            </strong>
                            <p style={{ fontSize: "13px" }}>
                              {orderData?.data?.location},{" "}
                              {orderData?.data?.landmark}
                              <br />
                              {orderData?.data?.city}, {orderData?.data?.state}
                              <br />
                              <b>Pin:</b> {orderData?.data?.pin} <br />
                              <b>Mobile:</b> {orderData?.data?.mobile} <br />
                              <b>Email ID:</b> {orderData?.data?.email} <br />
                            </p>
                          </div>
                          <br />
                          <div>
                            <strong>
                              <span
                                style={{ color: "#73be44" }}
                                className="mb-3"
                              >
                                Payment Details:&nbsp;
                              </span>
                            </strong>

                            {/* payment mode wise details view */}
                            {orderData?.data?.result[0]?.mode === "IMPS" && (
                              <div className="row" style={{ fontSize: "14px" }}>
                                <div className="mb-2" style={{ fontSize: "13px" }}>
                                  ISSUED TO
                                </div>
                                <div className="col-sm-6">
                                  <div className="mb-2">
                                    <b>Account Holder Name :</b>
                                    <br />
                                    {payDetails?.data1?.bank_account?.name ? payDetails.data1.bank_account.name : orderData?.data?.result[0]?.payment?.bank_account?.name || "NA"} {/* Show NA if name is null */}
                                  </div>
                                  <div className="mb-2">
                                    <b>Account No :</b>
                                    {payDetails?.data1?.bank_account?.account_number ? payDetails.data1.bank_account.account_number : orderData?.data?.result[0]?.payment?.bank_account?.account_number || "NA"} {/* Show NA if account_number is null */}
                                  </div>
                                  {payDetails?.data?.utr && (
                                    <div className="mb-2">
                                      <b>UTR No :</b> {payDetails?.data?.utr}
                                    </div>
                                  )}
                                  {orderData?.data?.result[0]?.transctionId && (
                                    <div className="mb-2">
                                      <b>Transaction ID :</b> {orderData?.data?.result[0]?.transctionId}
                                    </div>
                                  )}
                                </div>
                                <div className="col-sm-6">
                                  <div className="mb-2">
                                    <b>IFSC :</b>
                                    {payDetails?.data1?.bank_account?.ifsc ? payDetails.data1.bank_account.ifsc : orderData?.data?.result[0]?.payment?.bank_account?.ifsc || "NA"} {/* Show NA if IFSC is null */}
                                  </div>
                                  <div className="mb-2">
                                    <b>Bank Name :</b>
                                    {payDetails?.data1?.bank_account?.bank_name || "NA"} {/* Show NA if bank_name is null */}
                                  </div>
                                </div>
                              </div>
                            )}

                            {orderData?.data?.result[0]?.mode === "UPI" && (
                              <div className="row" style={{ fontSize: "14px" }}>
                                <div className="mb-2" style={{ fontSize: "13px" }}>
                                  ISSUED TO
                                </div>
                                <div className="col-sm-6">
                                  <div className="mb-2">
                                    <b>UPI ID :</b>
                                    {payDetails?.data1?.vpa?.address
                                      ? payDetails.data1.vpa.address
                                      : orderData?.data?.result[0]?.payment?.vpa?.address || "NA"} {/* Show NA if UPI ID is null */}
                                  </div>
                                  {payDetails?.data?.utr || orderData?.data?.result[0]?.pout?.split("_")[1] ? (
                                    <div className="mb-2">
                                      <b>UTR No :</b>
                                      {payDetails?.data?.utr || orderData?.data?.result[0]?.pout?.split("_")[1]}
                                    </div>
                                  ) : null} {/* Only show UTR No if it's not null */}
                                  {orderData?.data?.result[0]?.transctionId && (
                                    <div className="mb-2">
                                      <b>Transaction ID :</b> {orderData?.data?.result[0]?.transctionId}
                                    </div>
                                  )}
                                </div>
                                <div className="col-sm-6">
                                  <div className="mb-2">
                                    <b>Account Holder Name :</b>
                                    <br />
                                    {payDetails?.data1?.bank_account?.name || "NA"} {/* Show NA if Account Holder Name is null */}
                                  </div>
                                </div>
                              </div>
                            )}
                            {orderData?.data?.result[0]?.mode === "NEFT" && (
                              <p>
                                <b>Mode Of Payment : </b> {orderData?.data?.result[0]?.mode}
                                <br />
                                { orderData?.data?.result[0]?.status === "processed" && orderData?.data?.result[0]?.transactionId && (
                                  <>
                                    <b>Transaction ID:</b> {orderData?.data?.result[0]?.transactionId}
                                    <br />
                                    <b>Payment Settlement Date:</b> {orderData?.data?.result[0]?.settlement_date}
                                    <br />
                                  </>
                                )}
                              </p>
                            )}

                            {orderData?.data?.result[0]?.mode === "Cash" && (
                              <p>
                                <b>Mode Of Payment :</b>CASH
                                <br />
                              </p>
                            )}
                            {orderData?.data?.result[0]?.mode === "Eco" && (
                              <p>
                                <b>Mode Of Payment :</b>Eco
                                <br />
                              </p>
                            )}
                            {orderData?.data?.result[0]?.mode === "Donation" && (
                              <div>
                              <p>
                                <b>Mode Of Payment :</b>{" "}
                                {orderData?.data?.result[0]?.mode}
                              </p>
                              <p>
                                <strong>
                                  <span style={{color:"#73be44"}}>
                                    NGO Details:&nbsp;
                                  </span>
                                </strong>
                                <b>NGO Name :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.NGO?.name
                                }{" "}
                                <br />
                                <b>NGO Contact No :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.NGO?.mobile
                                }{" "}
                                <br />
                                <b>NGO Email :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.NGO?.email
                                }{" "}
                              </p>
                              <p>
                                <strong>
                                  <span style={{color:"#73be44"}}>
                                    Transaction Details:&nbsp;
                                  </span>
                                </strong>
                                <b>Account Holder Name :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.NGO?.ngo_bank_account?.name
                                }{" "}
                                <br />
                                <b>Account No :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.NGO?.ngo_bank_account?.account_number
                                }{" "}
                                <br />
                                <b>IFSC :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.NGO?.ngo_bank_account?.ifsc
                                }{" "}
                                <br />
                                {orderData?.data?.result[0]?.status ===
                                  "processed" && (
                                  <>
                                    <b>UTR No :</b>{" "}
                                    {
                                      orderData?.data?.result[0]?.pout?.split(
                                        "_"
                                      )[1]
                                    }{" "}
                                    <br />
                                  </>
                                )}
                              </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* paid logo  */}
                        <div className="col-sm-4 line-items">
                          <img src={PaidLogo} className="m-1" />
                        </div>
                      </div>
                      <div className="col-sm-12 line-items">
                        <div className="headers clearfix">
                          <div className="row">
                            <div className="col-4" style={{ color: "#73be44" }}>
                              <b>Item</b>
                            </div>
                            <div
                              className="col-4 "
                              style={{ color: "#73be44" }}
                            >
                              <b>Quantity</b>
                            </div>
                            <div
                              className="col-4 text-right"
                              style={{ color: "#73be44" }}
                            >
                              <b>Amount</b>
                            </div>
                          </div>
                        </div>

                        {/* items */}
                        <div className="items">
                          {orderData?.data?.result[0]?.item.map(
                            (item, index) => (
                              <div key={index}>
                                <div className="row item">
                                  <div className="col-4 desc">
                                    {item.item_name}
                                  </div>
                                  <div className="col-4 qty">
                                    {item.unit === "Kg"
                                      ? item.weight !== null
                                        ? item.weight.toFixed(3)
                                        : 0
                                      : item.unit === "Pc"
                                      ? item.weight
                                      : ""}{" "}
                                    {item.unit}
                                  </div>
                                  <div className="col-4 amount text-right">
                                    ₹
                                    {(
                                      (item.weight !== null ? item.weight : 0) *
                                      item.price
                                    ).toFixed(2)}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>

                        {/* total */}
                        <div className="total text-right ">
                          <div className="field grand-total">
                            Total{" "}
                            <span>
                              <strong>₹{subtotal.toFixed(2)}</strong>{" "}
                            </span>
                          </div>
                          <div className="field grand-total">
                            <span
                              style={{
                                color: "#73be44",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              Amount paid
                            </span>{" "}
                            <span
                              style={{
                                color: "#73be44",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              <strong>₹{subtotal.toFixed(2)}</strong>{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="text-center mt-5 p-3 row">
                      <div style={{ fontSize: "10px"}} className="mt-2 mx-auto col-7">
                      An initiative of Ceiba Green Solutions (OPC) Pvt. Ltd </div>
                    </div>
                  </div>

                  {/* button for print and back page */}
                  <div className="col-12 mt-3">
                    <div className="print float-start d-flex">
                      <Link onClick={printReceipt}>
                        <i className="fa fa-print"></i>
                        Print this receipt
                      </Link>
                    </div>
                    <div className="print float-end d-flex">
                      <Link onClick={goBack}>
                        <i className="fa fa-arrow-left"></i>
                        Go Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintReceipt;
