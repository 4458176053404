import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL_API, IMG_URL } from "../environment";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

function ItemPage() {
    const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });
  const [showCart, setShowCart] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [quantities, setQuantities] = useState(
    Array(selectedItems.length).fill("")
  );

  const [modifiedCartItems, setModifiedCartItems] = useState([]);
  const [showSaveModal, setShowSaveModal] = useState(true);

  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const CusId = localStorage.getItem("cusID");
  const [adsId, setAdsId] = useState([]);

  const [showSlotModal, setShowSlotModal] = useState(false);
  const [showPaymentMode, setShowPaymentMode] = useState(false);

  const [itemshow, setItemshow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleQuantityChange = (index, value) => {
    const newQuantities = [...quantities];
    newQuantities[index] = value;
    setQuantities(newQuantities);
  };

  const handleSaveChanges = () => {
    const updatedCartItems = selectedItems.map((item, index) => ({
      ...item,
      quantity: parseFloat(quantities[index] || 0),
    }));
    localStorage.setItem("modifiedCartItems", JSON.stringify(updatedCartItems));
    setModifiedCartItems(updatedCartItems);
    setShowCart(false);
    setShowSlotModal(true);
  };


  //fetching address of that customer
const fetchAddress = () =>{
  axios
  .get(BASE_URL_API + `address/cusId/${CusId}`)
  .then((response) => {
    console.log(response.data);
    setAddresses(response.data);
  })
  .catch((error) => {
    console.error("Error fetching addresses:", error);
  });
}

  useEffect(() => {
    fetchAddress();
  }, []);

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
    setAdsId(address._id);
    // console.log(address._id);
  };

  //add to cart function 
  const addToCart = (item) => {
    const itemIndex = cartItems.findIndex(
      (cartItem) => cartItem._id === item._id
    );

    if (itemIndex === -1) {
      Swal.fire(item.item_name + " is added to cart");
      const updatedCartItems = [...cartItems, item];
      setCartItems(updatedCartItems);
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

      const updatedItems = items.filter((i) => i._id !== item._id);
      setItems(updatedItems);
    } else {
      console.log("Item is already in the cart");
      Swal.fire(item.item_name + " is already in the cart");
    }
  };

  const openCart = () => {
    setShowCart(true);
    setSelectedItems(cartItems);
  };

  const handleCloseCart = () => {
    setShowCart(false);
    handleshowitempage(selectedAddress);
  };

  const handleDeleteItem = (index) => {
    const updatedSelectedItems = [...selectedItems];
    const deletedItem = updatedSelectedItems.splice(index, 1)[0];
    setCartItems((prevItems) =>
      prevItems.filter((item) => item._id !== deletedItem._id)
    );
    setSelectedItems(updatedSelectedItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedSelectedItems));
  };

  const handleCloseAddress = () => {
    setShowSaveModal(false);
    setShowSlotModal(true);
  };

  const handleCloseSlot = () => {
    setShowSlotModal(false);
    setShowPaymentMode(true);
  };

  const [selectedSlot, setSelectedSlot] = useState(null);

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  const formattedDate = `${dd}-${mm}-${yyyy}`;

  const timeSlots = [
    "08:45 AM to 09:30 AM",
    "09:45 AM to 10:30 AM",
    "10:30 AM to 11:00 AM",
    "11:00 AM to 11:30 AM",
    "11:30 AM to 12:00 PM",
    "12:30 PM to 01:00 PM",
    "01:30 PM to 02:30 PM",
    "03:00 PM to 03:45 PM",
    "04:00 PM to 04:45 PM",
    "05:00 PM to 05:45 PM"
  ];

  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);

  const handleCompleteOrder = () => {
    // Prepare data for the API request
    const customer_id = localStorage.getItem("cusID");
    const item = modifiedCartItems.map((cartItem) => ({
      itemid: cartItem._id,
      weight: cartItem.quantity.toString(),
    }));
    const schedule_date = formattedDate;
    const address_id = adsId;
    const time = selectedSlot;
    const mode = selectedPaymentMode;

    // Make the API request
    axios
      .post(BASE_URL_API + "orders/createOrder", {
        customer_id,
        item,
        schedule_date,
        address_id,
        time,
        mode,
      })
      .then((response) => {
        // Swal.fire("This Order "+response.data.order.order_id+" created successfully...");
        Swal.fire("A new order created successfully...");
        console.log("Order created successfully:", response.data);
        setShowPaymentMode(false);
        localStorage.removeItem("cartItems");
        localStorage.removeItem("modifiedCartItems");
        localStorage.removeItem("cusID");

        navigate(-1);

        
      })
      .catch((error) => {
        Swal.fire("Error creating order");
        console.error("Error creating order:", error);
      });
  };

  const [showNewAdd, setShowNewAdd] = useState(false);

  const handleNewAddress = () => {
    setShowSaveModal(false);
    setShowNewAdd(true);
  };

  const accessToken =
    "pk.eyJ1IjoiYWRpdGkyMDE3IiwiYSI6ImNsbWhlM2tkOTJzbmkzZW14dHB5ZzhiczIifQ.clzsn05t8dyPUXKRLmMeXQ";

  const [location, setLocation] = useState("");
  const [pin, setPin] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [landmark, setLandmark] = useState("");

  const handleInputChange = (event) => {
    setPin(event.target.value);
  };

//function for checking pin correct or not
  const handlePinBlur = () => {
    const getpin = pin;
    if (getpin) {
      axios
        .get(`https://api.postalpincode.in/pincode/${getpin}`)
        .then((response) => {
          const data = response.data;
          if (data && data.length > 0 && data[0].PostOffice.length > 0) {
            const city = data[0].PostOffice[0].Block;
            const state = data[0].PostOffice[0].State;
            setCity(city);
            setState(state);
          } else {
            console.log("Invalid PIN");
            Swal.fire({
              icon: "error",
              title: "Invalid PIN",
              text: "Please fill a valid pin for adding the location.",
            });
            setCity("");
            setState("");
          }
        })
        .catch((error) => {
          console.error("Error fetching PIN details:", error);
        });
    } else {
      setCity("");
      setState("");
    }
  };

  //add location
  const handleAddAddress = async () => {
    try {
      if (!location || !city || !state || !pin) {
        console.error("Please fill in all required fields");
        // window.alert("Please fill in all required fields");
        Swal.fire("Please fill in all required fields");
        return;
      }
  
      const geoCodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${pin}.json?country=IN&access_token=${accessToken}`;
      
      // Use 'await' to wait for the asynchronous API call to complete
      const geoCodingResponse = await axios.get(geoCodingUrl);
      const features = geoCodingResponse.data.features;
  
      if (features.length > 0) {
        const [longitude, latitude] = features[0].center;
        const customer_id = localStorage.getItem("cusID");
        
        // Now, store data in the database
        const addressData = {
          location: location,
          landmark: landmark,
          city: city,
          state: state,
          PIN: pin,
          latitude: latitude,
          longitude: longitude,
          customer_Id: customer_id,
        };
  
        // Use 'await' to wait for the asynchronous API call to complete
        const response = await axios.post(BASE_URL_API + "address/create", addressData);
        fetchAddress();
        console.log(response.data.message);
        if (response.data.message === "Saved successfully. Profile is incomplete due to inactive location.") {
            setCity("");
            setState("");
            setPin("");
            setLandmark("");
            setLocation("");
            window.alert("Address added successfully, But we are not providing our service in this location. Add another address....");
            // Swal.fire("Address added successfully, But we are not providing our service in this location. Add another address....");
        } else if (response.data.message === "The address already exists.") {
          setCity("");
          setState("");
          setPin("");
          setLandmark("");
          setLocation("");
          window.alert("Address already exists. Add another address....");
        } else {
          console.error("Address added successfully.");
          window.alert("Address added successfully.");
          setCity("");
          setState("");
          setPin("");
          setLandmark("");
          setLocation("");
          setShowNewAdd(false);
          setShowSaveModal(true);
        }
        // window.alert("A new address added successfully");
        
      } else {
        console.error("Unable to get latitude and longitude from the provided location");
        setLocation("");
        setCity("");
        setState("");
        setPin("");
        setLandmark("");
            
         Swal.fire("Please Provide a Valid Location");
     
      }
    } catch (error) {
      console.error("Error creating new location:", error);
      Swal.fire("An error occur during location creation");
      fetchAddress();
      setShowNewAdd(false);
      setShowSaveModal(true);
    }
  };
  
  const handleshowitempage = (selectedAddress) => {
    // Retrieve cart items from localStorage
    setShowSaveModal(false);
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  console.log("selectcted address is", selectedAddress);
    // Fetch the items from the API
    setLoading(true);
    axios
      .get(BASE_URL_API + "items/itembyCityByLang/"+selectedAddress.PIN+"/EN")
      .then((res) => {
        const filteredItems = res.data.result.map((itemData) => {
          const orderedItem = itemData.orderedItems;
  
          return {
            _id: orderedItem._id,
            item_name: orderedItem.item_name,
            price: orderedItem.price,
            unit: orderedItem.unit,
            city: orderedItem.city,
            imageURL: orderedItem.imageURL,
            isActive: orderedItem.isActive,
          };
        }).filter((item) => {
          return (
            item.isActive &&
            !cartItems.some((cartItem) => cartItem._id === item._id) // Exclude items already in cart
          );
        });
  
        setItems(filteredItems); // Set the fetched items
        setItemshow(true); // Show the items after fetching
        setLoading(false);
      })
      .catch((err) => {console.log(err); setLoading(false);});
  };

  const handleAddresshide = (selectedAddress) => {
    if (!selectedAddress) {
      // alert("Please select an address before proceeding.");
      navigate(-1);
    } else {
      handleshowitempage(selectedAddress); // Proceed if the address is selected
    }
  };
  return (
    //showing all item in cart page
    <div className="container-fluid bg-trasparent p-3">
      <div className="d-flex justify-content-between">
        <h5 className="">All Items :- </h5>

        <div
          onClick={openCart}
          style={{ position: "relative", cursor: "pointer" }}
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          title="Go to Cart"
        >
          <i
            className="fa fa-shopping-cart text-danger "
            aria-hidden="true"
            style={{ fontSize: "24px" }}
          >
            {cartItems.length > 0 && (
              <span
                className="badge bg-success rounded-circle"
                style={{
                  position: "absolute",
                  top: "-8px",
                  right: "-8px",
                  fontSize: "8px",
                }}
              >
                {cartItems.length}
              </span>
            )}
          </i>
        </div>
      </div>
      {loading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {itemshow && !loading && (
      <div className="row g-3">
        {items.map((item) => (
          <div key={item._id} className="col-lg-3 col-md-4 col-sm-6">
            <div className="card h-100 shadow-sm">
              <div className="d-flex justify-content-center">
                <img
                  src={`${IMG_URL + item.imageURL}`}
                  className="mt-3 mb-2"
                  alt={item.item_name}
                  style={{ height: "80px", width: "80px" }}
                />
              </div>
              <div className="card-body d-flex flex-column">
                <h6 className="card-title text-center text-dark">
                  {item.item_name}
                </h6>

                <div className="mb-auto">
                  <div className="clearfix mb-3">
                    <span className="float-start price-hp ">Item Price :</span>
                    <span className="float-end price-hp ">
                      <b>
                        {item.price.toFixed(2)}
                        <span style={{color:"#73be44"}}> /{item.unit}</span>
                      </b>
                    </span>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-warning btn-sm"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Add to Cart"
                    onClick={() => addToCart(item)}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      )}
      {/* Cart Modal */}    
      <Modal
        size="lg"
        show={showCart}
        onHide={handleCloseCart}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            Cart Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-group">
            <li className="list-group-item bg-light">
              <div className="d-flex">
                <div className="col-md-4 " style={{color:"73be44"}}>
                  <strong>Name</strong>
                </div>
                <div className="col-md-2 " style={{color:"73be44"}}>
                  <strong>Rates</strong>
                </div>
                <div className="col-md-2 " style={{color:"73be44"}}>
                  <strong>Quantity</strong>
                </div>
                <div className="col-md-3 " style={{color:"73be44"}}>
                  <strong>Price</strong>
                </div>
                <div className="col-md-1 " style={{color:"73be44"}}>
                  <strong>Action</strong>
                </div>
              </div>
            </li>
            {selectedItems.map((item, index) => (
              <li key={item._id} className="list-group-item">
                <div className="d-flex ">
                  <div className="col-md-4">{item.item_name}</div>
                  <div className="col-md-2">
                    {item.price.toFixed(2)} / {item.unit}
                  </div>
                  <div className="col-md-2">
                    <input
                      type="text"
                      placeholder="Enter quantity"
                      autoFocus
                      className="px-2 py-1 border border-success form-control"
                      style={{ width: "100px" }}
                      value={quantities[index]}
                      onChange={(e) => {
                        if (item.unit === "Kg") {
                          const input = e.target.value;
                          if (/^\d*\.?\d{0,3}$/.test(input)) {
                            handleQuantityChange(index, e.target.value);
                          }
                        } else if (item.unit === "Pc") {
                          const input = e.target.value;
                          if (/^\d*$/.test(input)) {
                            handleQuantityChange(index, e.target.value);
                          }
                        }
                      }}
                      onKeyPress={(e) => {
                        const allowedKeys = /^[0-9\b.]$/;
                        if (!allowedKeys.test(e.key)) {
                          e.preventDefault();
                        }
                        if (
                          e.key === "." &&
                          item.unit === "Kg" &&
                          quantities[index].includes(".")
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    {(item.price * parseFloat(quantities[index] || 0)).toFixed(
                      2
                    )}
                  </div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-danger btn-sm ms-2"
                      onClick={() => handleDeleteItem(index)}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveChanges}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Save Address Modal */}
      <Modal
        size="md"
        show={showSaveModal}
        onHide={() => {handleAddresshide(selectedAddress)}}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select an address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Choose an address</p>

          <Form>
            {addresses.map(
              (address) =>
                address.isActive && (
                  <Form.Check
                    key={address._id}
                    type="checkbox"
                    label={`${address.location}, ${address.landmark}, ${address.city}, ${address.state} - ${address.PIN}`}
                    checked={
                      selectedAddress && selectedAddress._id === address._id
                    }
                    onChange={() => handleSelectAddress(address)}
                  />
                )
            )}
          </Form>

          {selectedAddress && (
            <p style={{ color: "#73be44", fontSize:"13px" }}>
              You have selected: {selectedAddress.location},{" "}
              {selectedAddress.landmark}, {selectedAddress.city},{" "}
              {selectedAddress.state} - {selectedAddress.PIN}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleNewAddress}>
            Add Another Address
          </Button>
          <Button variant="primary"  onClick={() => {
            if (selectedAddress) {
              handleshowitempage(selectedAddress);
            } else {
              alert("Please select an address before proceeding.");
            }
          }}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add New Address Modal */}
      <Modal size="md" show={showNewAdd} onHide={() => {setShowNewAdd(false); setShowSaveModal(true);}}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
          <p className='mt-4 mb-2 text-danger text-end mr-5'><b>*</b> marked fields are mandatory.</p>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Pin/Zip Code<span className='text-danger' style={{fontWeight:"bold"}}> *</span></label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Pin "
                    onChange={handleInputChange}
                    onKeyUp={handlePinBlur}
                    value={pin}
                    onKeyPress={(event) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(event.charCode);

                      if (
                        event.target.value.length === 0 &&
                        inputChar === "0"
                      ) {
                        event.preventDefault();
                      }

                      if (!pattern.test(inputChar)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={6}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>City<span className='text-danger' style={{fontWeight:"bold"}}> *</span></label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter City"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Landmark</label>
                  <input
                    type="text"
                    class="form-control"
                    value={landmark}
                    placeholder="Enter Landmark "
                    onChange={(e) => {
                      
                      setLandmark(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>State<span className='text-danger' style={{fontWeight:"bold"}}> *</span></label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter State"
                    value={state}
                    // disabled
                    onChange={(e) => {setState(e.target.value);}}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>House No, Area, Colony<span className='text-danger' style={{fontWeight:"bold"}}> *</span></label>
                  <input
                    type="text"
                    class="form-control"
                    value={location}
                    placeholder="Enter House No, Area, Colony "
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddAddress}>
            Add Address
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Select Slot Modal */}
      <Modal
        size="md"
        show={showSlotModal}
        onHide={() => setShowSlotModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select a slot date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Today's Date: {formattedDate}</p>
          <Form>
            {timeSlots.map((slot, index) => (
              <Form.Check
                key={index}
                type="radio"
                label={slot}
                checked={selectedSlot === slot}
                onChange={() => setSelectedSlot(slot)}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {
            if (selectedSlot) {
              handleCloseSlot();
            } else {
              alert("Please select a time slot before proceeding.");
            }
          }}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Payment Mode Modal */}
      <Modal
        size="md"
        show={showPaymentMode}
        onHide={() => setShowPaymentMode(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select mode of Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Check
              type="radio"
              label="Cash"
              checked={selectedPaymentMode === "Cash"}
              onChange={() => setSelectedPaymentMode("Cash")}
            />
            {/* More Payment field i will add later */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary"  onClick={() => {
            if (selectedPaymentMode) {
              handleCompleteOrder();
            } else {
              alert("Please select a payment mode before completing the order.");
            }
          }}>
            Complete Order
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}


export default ItemPage
