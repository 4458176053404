import React, { useState } from 'react';
import logo from '../img/WebLogo.svg';
import axios from 'axios';
import { BASE_URL_API } from '../environment';
import Swal from 'sweetalert2';

function CustomerDetails() {
    const [name, setName] =useState("");
    const [email, setEmail] =useState("");
    const [organisation, setOrganisation] =useState("");
    //this is used for registering a new customer and taking fixed location information
    const handleRegister = () => {
        const phone = localStorage.getItem("mobile");
        axios.post(`${BASE_URL_API}customers/createProfile`, 
        {
            name: name,
            email: email,
            mobile: phone,
            location: "129",
            landmark: "patia",
            city: "Bhubaneswar",
            state: "Odisha",
            PIN: "751024",
            latitude: 20.353465,
            longitude: 85.824206,
            organisation: organisation
        }
        )
        .then(response => {
            console.log('Profile created successfully:', response.data);
            Swal.fire({
              title: 'Success!',
              text: 'You have been successfully registered. Please download the app and login to place your order.',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                //after registration redirect to pwa
                window.location.href = 'https://bit.ly/re-pay';
              }
            });
          })
            .catch(error => {
                console.error('Error creating profile:', error);
            });
      }
    
      
  return (
    // registration form 
    <div className="container-fluid ">
    <div className="row d-flex justify-content-center align-items-center ">
      <div className="row g-0 d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
        <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center ">
          <div className="col-md-5 col-sm-8">
            <form>
              <div className="text-center mb-2">
                <img
                  className="img-responsive img-circle img-thumbnail img-user"
                  src={logo}
                  alt="Logo"
                  style={{ width: "180px", border: "none" }}
                />
              </div>
              
              <div className="form-outline mb-3">
                <label
                  className="form-label"
                  htmlFor="form2Example17"
                  style={{ fontSize: "13px", color: "#73be44" }}
                >
                  Enter Name
                </label>
                <input
                  type="text" 
                  placeholder="Enter Name"
                  id="form2Example17"
                  className="form-control form-control-sm"
                  style={{ borderColor: "#73be44" }}
                  value={name}
                  onChange={(e)=>{setName(e.target.value)}}
                />
              </div>

              <div className="form-outline mb-3">
                <label
                  className="form-label"
                  htmlFor="form2Example17"
                  style={{ fontSize: "13px", color: "#73be44" }}
                >
                  Enter Email
                </label>
                <input
                  type="text" 
                  placeholder="Enter Email"
                  id="form2Example17"
                  className="form-control form-control-sm"
                  style={{ borderColor: "#73be44" }}
                  value={email}
                  onChange={(e)=>{setEmail(e.target.value)}}
                />
              </div>

              <div className="form-outline mb-3">
                <label
                  className="form-label"
                  htmlFor="form2Example17"
                  style={{ fontSize: "13px", color: "#73be44" }}
                >
                  Organisation
                </label>
                <select
                className="form-control form-control-sm"
                style={{ borderColor: "#73be44" }}
                value={organisation}
                onChange={(e) => { setOrganisation(e.target.value) }}
              >
                <option value="" disabled selected>Select an option</option>
                <option value="YII">YII</option>
                <option value="CII">CII</option>
                <option value="OTHERS">OTHERS</option>
              </select>
              </div>
             
              <div className="d-grid pt-4 mb-4">
                <button
                  className="btn btn-sm btn-block"
                  style={{ backgroundColor: "#73be44", color: "white" }}
                  type="button"
                  onClick={handleRegister}
                >
                  Register Now
                </button>
                {/* <a href='https://bit.ly/re-pay' target='_blank' id='open_recyclepay'></a> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CustomerDetails
