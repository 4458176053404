import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL_API, IMG_URL } from "../environment";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Swal from "sweetalert2";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import noLogo from '../img/logo01.svg';


export const Products = () => {
  //LIST PRODUCTS APPI
  const [Podt, setProt] = useState([]);
  const [cat, setCat]= useState([]);
  const [cityPer, setCityPer] = useState([]);
  const [selectedCity, setSelectedCity] = useState("65d5772df1cccd1633087da7"); 
  const [percent, setPercent] = useState(null); 
  useEffect(() => {
    fetchData();
  }, [selectedCity]);

  //function for getting all details
  useEffect(() =>{
    axios
    .get(BASE_URL_API + "itemcategory/getall")
    .then((res) => {
      console.log(res);
      console.log(res.data);
      setCat(res.data);
    })
    .catch((err) => console.log(err));

    axios
    .get(BASE_URL_API + "items/allCityPercent")
    .then((res) => {
      console.log(res);
      console.log(res.data);
      setCityPer(res.data);
    })
    .catch((err) => console.log(err));

    axios
    .get(BASE_URL_API + "items/getallind/" + selectedCity)
    .then((res) => {
      console.log(res);
      console.log(res.data.item.percentage);
      setPercent(res.data.item.percentage);
    })
    .catch((err) => console.log(err));

    axios
    .get(BASE_URL_API + "items/getallind/" + selectedCity)
    .then((res) => {
      console.log(res);
      console.log(res.data.item.percentage);
      setPercent(res.data.item.percentage);
    })
    .catch((err) => console.log(err));
  }, [selectedCity]);

  const fetchData = () => {
    axios
      .get(BASE_URL_API + "items")
      .then((res) => {
        const filteredItems = res.data.filter(item => item.cityid === selectedCity);
        setProt(filteredItems);
        console.log(filteredItems);
      })
      .catch((err) => console.log(err));
  };

  //VIEW PRODUCTS APPI
  const [prodView, setProdView] = useState([]);

  const viewBtn = (idd) => {
    handleApiView(idd);
    setView(true);
  };

  const handleApiView = (id) => {
    axios
      .get(BASE_URL_API + "items/" + id)
      .then((res) => {
        setProdView(res.data);
      })
      .catch((err) => console.log(err));
  };

  //ADD PRODUCTS API
  const [name, setName] = useState();
  const [price, setPrice] = useState("");
  const [unit, setUnit] = useState("");
  const [category, setCategory] = useState("");
  const [dealprice, setDealPrice] = useState("");
  const [description, setDescription] = useState();
  const [image, setImage] = useState([]);
  const [oldimage, setOldImage] = useState([]);
  const [cityid, setCityid] = useState("");
  const [city, setCity] = useState("");
  const [itemLang, setItemLang] = useState([
    { language: 'EN', item_name: '', price: '', unit: '', item_desc: '' },
    { language: 'HI', item_name: '', price: '', unit: '', item_desc: '' },
    { language: 'OD', item_name: '', price: '', unit: '', item_desc: '' },
  ]);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  
  //item details change functions
  let isFirstTime = true;
  const handleItemChange = (e, index) => {
    const { name, value } = e.target;
    const langIndex = name.split('_')[2];
  
    if (isFirstTime && langIndex === '0') {
      setName(e.target.value); 
      isFirstTime = false; 
    }
  
    setItemLang((prevItemLang) => {
      const updatedItemLang = [...prevItemLang];
      updatedItemLang[langIndex].item_name = value;
      return updatedItemLang;
    });
  };

  const handleDescriptionChange = (e, index) => {
    const { name, value } = e.target;
    const langIndex = name.split('_')[2];
  
    if (isFirstTime && langIndex === '0') {
      setDescription(e.target.value); 
      isFirstTime = false; 
    }
  
    setItemLang((prevItemLang) => {
      const updatedItemLang = [...prevItemLang];
      updatedItemLang[langIndex].item_desc = value;
      return updatedItemLang;
    });
  };
  const handleUnitChange = (e, index) => {
    const { value } = e.target;
    if (isFirstTime && index === 0) {
      setUnit(value);
      isFirstTime = false;
    }
    setItemLang((prevItemLang) =>
      prevItemLang.map((item, idx) =>
        idx === index ? { ...item, unit: value } : item
      )
    );
  };
  const handlePriceChange = (e, index) => {
    const { value } = e.target;
    const newPrice = value.trim();
  
    setItemLang((prevItemLang) => {
      const updatedItemLang = [...prevItemLang];
  
      // Convert dealprice and newPrice to numbers for comparison
      const numericDealPrice = parseFloat(dealprice);
      const numericNewPrice = parseFloat(newPrice);
  
      // Check if the new price is valid and does not exceed the deal price
      if (index === 0) {
        if (numericDealPrice < numericNewPrice) {
          // Show an error or adjust the price if necessary
          alert("Price cannot exceed the dealer price.");
          return prevItemLang;
        }
  
        // If valid, update the price for the first index
        updatedItemLang[index].price = newPrice;
  
        // Update the price for the other indices to match the first index
        updatedItemLang.forEach((item, i) => {
          if (i !== index) {
            item.price = newPrice;
          }
        });
      } else {
        // Update the price for other indices
        updatedItemLang[index].price = newPrice;
  
      }
  
      return updatedItemLang;
    });
  };
  const handlePriceAllChange = (e, index) => {
    const { name, value } = e.target;
    const langIndex = parseInt(name.split('_')[2], 10); // Ensure langIndex is a number

    // Check for dealprice constraints
    const numericDealPrice = parseFloat(dealprice);
    const numericNewPrice = parseFloat(value);

    if (langIndex === 0) {
      if (numericDealPrice < numericNewPrice) {
        // Show an error or adjust the price if necessary
        alert("Price cannot exceed the deal price.");
        return; // Prevent state update if validation fails
      }
      // Update deal price state if it's the first time
      if (isFirstTime) {
        setPrice(e.target.value);
        isFirstTime = false; 
      }
    }

    setItemLang((prevItemLang) => {
      const updatedItemLang = [...prevItemLang];
      // Update price for the specific index
      updatedItemLang[langIndex].price = value;
      // If it's the first item, update all items with the same price
      if (langIndex === 0) {
        updatedItemLang.forEach((item) => {
          item.price = value;
        });
      }
      return updatedItemLang;
    });
  };
  //function for adding new item
  const handleApiAdd = () => {
    if (!name || !dealprice || !image || !unit || !city || !description) {
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: "All fields are required!",
        icon: "error",
      });
      return;
    }

    // const itemLang =  [{ "item_name": "ସ୍କ୍ରାପ୍ ପେପର |", "price": "୧୦", "unit": "କିଲୋଗ୍ରାମ୍", "language": "OD" },
    //  { "item_name": "रद्दी कागज", "price": "दस", "unit": "किलोग्राम", "language": "HI" }];
    const formData = new FormData();
    // formData.append("image", image);
    formData.append("item_name", name.trim());
    formData.append("price", price);
    formData.append("dealer_price", dealprice);
    formData.append("unit",unit);
    formData.append("cityid", cityid);
    formData.append("city", city);
    formData.append("category",category);
    if (description !== undefined) {
      formData.append("description", description.trim());
    } else {
      formData.append("description", "");
    }
    const trimmedItemLang = itemLang.map((langItem) => ({
      ...langItem,
      item_name: langItem.item_name.trim(),item_desc: langItem.item_desc.trim(),
    }));
    formData.append("itemLang", JSON.stringify(trimmedItemLang));
    
    image.forEach((file, index) => {
      formData.append("image", file);
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to add this aproduct !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Add",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(BASE_URL_API + "items/create", formData)
            .then((res) => {
              if (res.data.uploaded === false) {
                swalWithBootstrapButtons.fire(
                  "Failed to add !",
                  "This product already exists.",
                  "error"
                );
                setName("");
                setPrice("");
                setDealPrice("");
                setDescription("");
                setUnit("");
                setCity("");
                setCityid("");
                setCategory("");
                setImage(null);
                setItemLang([
                  { language: 'EN', item_name: '', price: '', unit: '', item_desc: '' },
                  { language: 'HI', item_name: '', price: '', unit: '', item_desc: '' },
                  { language: 'OD', item_name: '', price: '', unit: '', item_desc: '' },
                ]);
                setAdd(true);

              } else {
                swalWithBootstrapButtons.fire(
                  "Added!",
                  "A new product has been Added.",
                  "success"
                );
                setName("");
                setUnit("");
                setPrice("");
                setDealPrice("");
                setCity("");
                setCityid("");
                setDescription("");
                setCategory("");
                setImage(null);
                setAdd(false);
                fetchData();
                setItemLang([
                  { language: 'EN', item_name: '', price: '', unit: '', item_desc: '' },
                  { language: 'HI', item_name: '', price: '', unit: '', item_desc: '' },
                  { language: 'OD', item_name: '', price: '', unit: '', item_desc: '' },
                ]);
                
              }
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not add this product",
            "error"
          );
          setName("");
          setPrice("");
          setDealPrice("");
          setDescription("");
          setImage(null);
          setUnit("");
          setCity("");
          setCityid("");
          setCategory("");
          fetchData();
          setAdd(false);
          setItemLang([
            { language: 'EN', item_name: '', price: '', unit: '', item_desc: '' },
            { language: 'HI', item_name: '', price: '', unit: '', item_desc: '' },
            { language: 'OD', item_name: '', price: '', unit: '', item_desc: '' },
          ]);
        }
      });
  };

  //UPDATE PRODUCTS API
  const [upId, setUpId] = useState();

  const editBtn = (idd) => {
    axios
      .get(BASE_URL_API + "items/" + idd)
      .then((res) => {
        const item = res.data[0];
        setName(item.item_name);
        setDealPrice(item.dealer_price.toString());
        setPrice(item.price.toString());
        setDescription(item.description);
        setOldImage(item.imageURL); 
        setUpId(item._id);
        setUnit(item.unit);
        setCategory(item.category._id);
        if (item.itemLang.length === 0) {
          setItemLang([
            { language: 'EN', item_name: '', price: '', unit: '', item_desc: '' },
            { language: 'HI', item_name: '', price: '', unit: '', item_desc: '' },
            { language: 'OD', item_name: '', price: '', unit: '', item_desc: '' },
          ]);
        } else {
          const updatedItemLang = item.itemLang.map((langItem) => ({
            language: langItem.language,
            item_name: langItem.item_name,
            price: langItem.price.toString(),
            unit: langItem.unit,
            item_desc: langItem.item_desc,
          }));
          setItemLang(updatedItemLang);
        }
        setEdit(true);
      })
      .catch((err) => console.log(err));
    setEdit(true);
  };

  //function for updating the item
  const handleApiUpdate = (id) => {
    if (!price || !dealprice || !unit || !description ) {
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: "Dealer price field and unit are required!",
        icon: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.append("_id", id);
    formData.append("item_name", name);
    formData.append("dealer_price", dealprice);
    formData.append("price", price);
    formData.append("unit", unit);
    formData.append("category", category);
    formData.append("description", description);
    formData.append("itemLang", JSON.stringify(itemLang));
    if (image) {
      image.forEach((file, index) => {
        formData.append("image", file);
      });
    }
  
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to update this product!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Update",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .put(BASE_URL_API + "items/update", formData)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Updated!",
                "This product details have been updated successfully.",
                "success"
              );
              console.log(res.data);
              setEdit(false);
              setName("");
              setDescription("");
              setPrice("");
              setUnit("");
              setCategory("");
              setImage(null);
              setItemLang([
                { language: 'EN', item_name: '', price: '', unit: '', item_desc: '' },
                { language: 'HI', item_name: '', price: '', unit: '', item_desc: '' },
                { language: 'OD', item_name: '', price: '', unit: '', item_desc: '' },
              ]);
              fetchData();
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not update this product",
            "error"
          );
          setEdit(false);
        }
      });
  };
  

  //DELETE PRODUCTS API

  const apiDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(BASE_URL_API + "items/delete/" + id)
          .then((res) => {
            Swal.fire("Deleted!", "This item has been deleted.", "success");
            console.log(res.data);
            fetchData();
          })
          .catch((err) => console.log(err));
      }
    });
  };

  //inactive products
  const productsInactive = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to deactivate this item !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deactive!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(BASE_URL_API + "items/deactivate/" + id)
          .then((res) => {
            Swal.fire("Deactivated!", "This item has been deactivated.", "success");
            // console.log(res.data);
            fetchData();
          })
          .catch((err) => console.log(err));
      }
    });
  };

  //activate product
  const productsActive = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to activate this item !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Activate!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(BASE_URL_API + "items/activate/" + id)
          .then((res) => {
            Swal.fire("Activated!", "This item has been activated.", "success");
            // console.log(res.data);
            fetchData();
          })
          .catch((err) => console.log(err));
      }
    });
  };

  //MODALS
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);

  const actionsFormatter = (dataField, row) => {
    if (row.isActive) {
      return (
        <>
          <Button
            type="button"
            className="btn btn-sm "
            onClick={() => viewBtn(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="View Details"
            style={{background:"#73be44", color:"#fff"}}
          >
            <i className="fa-solid fa-eye "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-secondary btn-sm mx-1"
            onClick={() => editBtn(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-solid fa-edit "></i>
          </Button>
          {/* <Button
            type="button"
            className="btn btn-danger btn-sm "
            onClick={() => apiDelete(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i className="fa-solid fa-trash "></i>
          </Button> */}
          <Button
            type="button"
            className="btn btn-primary btn-sm mx-1"
            onClick={() => productsInactive(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Inactive"
          >
            <i className="fa-solid fa-toggle-on" aria-hidden="true"></i>
          </Button>
        </>
      );
    } else {
      return (
        <>
        <Button
            type="button"
            className="btn btn-light btn-sm disabled "
            onClick={() => viewBtn(dataField)}
            
          >
            <i className="fa-solid fa-eye "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-light btn-sm disabled mx-1"
            onClick={() => editBtn(dataField)}
           
          >
            <i className="fa-solid fa-edit "></i>
          </Button>
          {/* <Button
            type="button"
            className="btn btn-light btn-sm disabled"
            onClick={() => apiDelete(dataField)}
            
          >
            <i className="fa-solid fa-trash "></i>
          </Button> */}
          <Button
          type="button"
          className="btn btn-primary btn-sm mx-1"
          onClick={() => productsActive(dataField)}
          data-toggle="tooltip"
            data-placement="top"
            title="Active"
        >
          <i className="fa-solid fa-toggle-off" aria-hidden="true"></i>
        </Button>
        </>
        
      );
    }
  };
  

  //column for data table
  const columns = [
    {
      dataField: "imageURL",
      text: "Image",
      formatter: (dataField) => {
        if (dataField) {
          return (
            <div style={{ display: "flex", overflowX: "auto" }}>
              {dataField.map((image, index) => (
                <img
                  key={index}
                  src={IMG_URL + image}
                  style={{ height: "50px", width: "50px", marginRight: "5px" }}
                  className="rounded"
                  alt={`Image ${index + 1}`}
                />
              ))}
            </div>
          );
        } else {
          return <img src={noLogo} style={{ height: "50px", width: "50px" }} className="rounded" />;
        }
      },
      headerStyle: { width: "75px" },
    },
    {
      dataField: "item_name",
      text: "Name",
      sort: "asc",
      filter: textFilter(),
      
    },
    {
      dataField: "dealer_price",
      text: "Dealer Price ",
      sort: "asc",
      filter: textFilter(),
      formatter: (cell, row) => {
        const dealerPrice = parseFloat(cell).toFixed(2);
        const unit = row.unit;
        if (typeof unit === 'string' && unit.trim() !== '') {
          return `${dealerPrice} / ${unit}`;
        } else {
          return `${dealerPrice} / N/A`;
        }
      },
    },
    {
      dataField: "price",
      text: "Customer Price",
      sort: "asc",
      filter: textFilter(),
      formatter: (cell, row) => {
        const price = parseFloat(cell).toFixed(2);
        const unit = row.unit;
        if (typeof unit === 'string' && unit.trim() !== '') {
          return `${price} / ${unit}`;
        } else {
          return `${price} / N/A`;
        }
      },
    },

    {
      dataField: "city",
      text: "City",
      sort: "asc",
      filter: textFilter(),
      
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: (isActive) => {
        return isActive ? "Active" : "Inactive";
      },
      
      
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: actionsFormatter,
    },
  ];

  const rowClasses = (row) => {
    return row.isActive ? "table-lightgreen" : ""; 
  };

  const [sizePerPage, setSizePerPage] = useState(10);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setSizePerPage(sizePerPage);
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: sizePerPage,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onTableChange: handleTableChange,
  };

  const handleInputChange = (e) => {
    const dealerPrice = parseFloat(e.target.value);
    if (!isNaN(dealerPrice)) {
      const customerPrice = dealerPrice * (1-(percent/100));
      const formattedPrice = customerPrice % 1 !== 0 ? customerPrice.toFixed(2) : customerPrice;
      setPrice(formattedPrice.toString());
      const updatedItemLang = itemLang.map(langItem => ({ ...langItem, price: formattedPrice.toString(),}));
      setItemLang(updatedItemLang);
    } else {
      setPrice("");
      const updatedItemLang = itemLang.map(langItem => ({ ...langItem, price: '',}));
      setItemLang(updatedItemLang);
    }
  };
  
 

  const hideModal = () => {
    setAdd(false);
    setName("");
    setUnit("");
    setPrice("");
    setDealPrice("");
    setDescription("");
    setCategory("");
    setImage(null);
    setCityid("");
    setCity("");
    setItemLang([
      { language: 'EN', item_name: '', price: '', unit: '', item_desc: '' },
      { language: 'HI', item_name: '', price: '', unit: '', item_desc: '' },
      { language: 'OD', item_name: '', price: '', unit: '', item_desc: '' },
    ]);
  };
  const hideeditModal = () => {
    setEdit(false);
    setName("");
    setUnit("");
    setPrice("");
    setDealPrice("");
    setDescription("");
    setCategory("");
    setImage(null);
    setItemLang([
      { language: 'EN', item_name: '', price: '', unit: '', item_desc: '' },
      { language: 'HI', item_name: '', price: '', unit: '', item_desc: '' },
      { language: 'OD', item_name: '', price: '', unit: '', item_desc: '' },
    ]);
  };
  
  //change city function
  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    const selectedCity = cityPer.cities.find((city) => city._id === selectedCityId);
    if (selectedCity) {
      setCityid(selectedCityId);
      setCity(selectedCity.city);
      setPercent(selectedCity.percentage)
    }
  };

  const handleChangedropdown = (e) => {
    const dropcity = e.target.value;
    setSelectedCity(e.target.value);
    const dropdownCity = cityPer.cities.find((city) => city._id === dropcity);
    setPercent(dropdownCity.percentage);
  };

  const [isDirty, setIsDirty] = useState(false); 

  const handleChange = (e) => {
      setPercent(e.target.value);
      setIsDirty(true); 
  };

  //update the item percentage
  const handleApplyChanges = () => {
      
      swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to update for all product !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .put(BASE_URL_API + "items/updateprice/",{
              "cityid": selectedCity,
              "percentage": percent
            })
            .then((res) => {
              if (res.data.isSuccess === false) {
                setIsDirty(true);
                swalWithBootstrapButtons.fire(
                  "Failed to Update",
                  "error"
                );
              } else {
                swalWithBootstrapButtons.fire(
                  "Updated!",
                  "success"
                );
                setIsDirty(false);
                fetchData();
                
              }
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "error"
          );
          setIsDirty(true);
         
        }
      }); 
  };

  //romove image function
  const handleRemoveImage =(inno)=>{
    axios
    .delete(BASE_URL_API+"items/"+upId+"/image/"+inno)
    .then((response) => { console.log('Image removed successfully', response.data);
      editBtn(upId);
    })
    .catch((error) => {
      console.error('Error removing image', error);
    });
  }
  const handleImageChangeedit = (e) => {
    const files = e.target.files;
    const allowedSize = 1024 * 1024; 
    let isValid = true;

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > allowedSize) {
        isValid = false;
        alert('Please upload images with a maximum size of 1 MB.');
        break;
      }
    }
    if (isValid) {
      setImage([...files]);
    } else {
      e.target.value = null;
    }
  };
  return (
    <div className="card">
      <h5 className="card-header">Products</h5>
      <div className="d-flex justify-content-between"> 
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
        <select onChange={(e) => handleChangedropdown(e)} className="form-select form-select-sm " value={selectedCity} style={{ width: '150px', height: '40px', borderColor:"#73be44" }}>
          {cityPer && cityPer.cities
            ? cityPer.cities.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.city}
                </option>
              ))
            : null}
        </select>
        <div className="d-flex align-items-center ml-5">Percentage : </div>
            <div className="d-flex align-items-center" style={{position: 'relative', maxWidth:"80px"}}>
                <input 
                    type="text" 
                    className="form-control form-control-sm" 
                    value={percent} 
                    onChange={(e)=>handleChange(e)} 
                    style={{ width: '80px', height: '40px', borderColor:"#73be44", paddingRight: '30px' }} 
                    onKeyPress={(event) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(event.charCode);

                      if (
                        event.target.value.length === 0 &&
                        inputChar === "0"
                      ) {
                        event.preventDefault();
                      }

                      if (!pattern.test(inputChar)) {
                        event.preventDefault();
                      }
                    }}
                  maxLength={2}
                />
                <i className="fa fa-pencil" style={{position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)'}}></i>
            </div>
            <button className="btn btn-sm" style={{color:"#fff", background:"#73be44"}} disabled={!isDirty || !percent} 
                onClick={handleApplyChanges}>Apply Changes</button>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
      <Button className="btn" onClick={() => setAdd(true)} style={{background:"#73be44", color:"#fff"}}>
        <i className="fa-solid fa-plus"></i>Add new Products
      </Button>
      </div>
      </div>
      {/* Products List*/}
      
        <BootstrapTable
          keyField="id"
          data={Podt}
          columns={columns}
          pagination={paginationFactory(options)}
          search
          filter={filterFactory()}
          rowClasses={rowClasses}
        />
      

      {/* Add Products modal */}
      <Modal
        size="lg"
        show={add}
        onHide={hideModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Products
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>City </Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <select onChange={(e) => handleCityChange(e)} className="form-select" defaultValue="">
                  <option value="" disabled>
                    Select city
                  </option>
                  {cityPer && cityPer.cities
                    ? cityPer.cities.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.city}
                        </option>
                      ))
                    : null}
                  </select>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Item Category </Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <select
  onChange={(e) => setCategory(e.target.value)}
  className="form-select"
  defaultValue=""
>
  <option value="" disabled>
    Select An Item Category
  </option>
  {cat && cat.item
    ? cat.item.map((item) => (
        <option key={item._id} value={item._id}>
          {item.item_name}
        </option>
      ))
    : null}
</select>

                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Dealer Price</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Form.Control
  type="text"
  placeholder="Place the Dealer price of the Item"
  value={dealprice}
  onChange={(e) => {
    const input = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(input)) {
      setDealPrice(input);
      handleInputChange(e);
    }
  }}
  onKeyPress={(e) => {
    const allowedKeys = /^[0-9\b.]$/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
    // Allow only one decimal point
    if (e.key === "." && dealprice.includes(".")) {
      e.preventDefault();
    }
  }}
/>

              </Col>
                
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xs={12} md={12}>
                  <Form.Label>Item Details :- </Form.Label>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Language</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <Row>
                    {itemLang.map((langItem, index) => (
                      <Col key={langItem.language} xs={4} md={4}>
                        <Form.Label>{langItem.language}</Form.Label>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Name</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Row>
                  {itemLang.map((langItem, index) => (
                    <Col key={langItem.language} xs={4} md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Product Name"
                        name={`item_name_${index}`}
                        value={langItem.item_name}
                        onChange={(e) => handleItemChange(e, index)}
                      />
                    </Col>
                  ))}
                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Unit Of Measure</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Row>
                {itemLang.map((langItem, index) => (
                    <Col key={langItem.language} xs={4} md={4}>
                      <select
                        onChange={(e) => handleUnitChange(e, index)}
                        value={langItem.unit}
                        className="form-select"
                      >
                        <option value="" disabled>
                          Select An Unit
                        </option>
                        {langItem.language === 'EN' && (
                          <>
                            <option value="Kg">Kg</option>
                            <option value="Pc">Pc</option>
                          </>
                        )}
                        {langItem.language === 'HI' && (
                          <>
                            <option value="किलो">किलो</option>
                            <option value="टुकड़ा">टुकड़ा</option>
                          </>
                        )}
                        {langItem.language === 'OD' && (
                          <>
                            <option value="କିଲୋ">କିଲୋ</option>
                            <option value="ଖଣ୍ଡ">ଖଣ୍ଡ</option>
                          </>
                        )}
                      </select>
                    </Col>
                  ))}

                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Customer Price</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Row>
                {itemLang.map((langItem, index) => (
                  <Col key={langItem.language} xs={4} md={4}>
                    <Form.Control
                      type="text"
                      placeholder="Place the price of the Item"
                      value={langItem.price}
                      onChange={(e) => handlePriceChange(e, index)}
                      required
                    />
                  </Col>
                ))}
                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Description</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Row>
                {itemLang.map((langItem, index) => (
                    <Col key={langItem.language} xs={4} md={4}>
                      <Form.Control
                        placeholder="Description"
                        name={`item_name_${index}`}
                        value={langItem.item_desc}
                        onChange={(e) => handleDescriptionChange(e, index)}
                        as="textarea"
                        // onChange={(e) => setDescription(e.target.value)}
                        rows={3}
                      />
                    </Col>
                  ))}
                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Image</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <Form.Control
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    multiple
                    onChange={handleImageChangeedit}
                  />
                </Col>
              </Row>
            </Form.Group>

            {/* <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                onChange={(e) => setDescription(e.target.value)}
                rows={2}
              />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleApiAdd}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View model start */}

      <Modal
        size="lg"
        show={view}
        onHide={() => setView(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {prodView.map((item) => (
                <>
                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Label>Image</Form.Label>
                    </Col>
                    <Col xs={12} md={9}>
                    {item.imageURL && item.imageURL.length > 0 ? (
                        <div style={{ display: "flex", overflowX: "auto" }}>
                          {item.imageURL.map((image, index) => (
                            <img key={index} src={IMG_URL + image} style={{ height: "75px", width: "75px", marginRight: "5px"}} />
                          ))}
                        </div>
                      ) : (
                        <img
                          src={noLogo}
                          style={{ height: "75px", width: "75px" }}
                        />
                      )}
</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Label>Item Category</Form.Label>
                    </Col>
                    <Col xs={12} md={9}>
                      <h4>{item.category.item_name}</h4>
                    </Col>
                    <br />
                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Label>Dealer Price</Form.Label>
                    </Col>
                    <Col xs={12} md={9}>
                      <h5>{item.dealer_price} /{item.unit}</h5>
                    </Col>
                  </Row>
                  </Row>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Row>
                  <Col xs={6} md={3}>
                    <Form.Label>Language</Form.Label>
                  </Col>
                  <Col xs={12} md={9}>
                  <Row>
                    {item.itemLang.length > 0 ? (
                      item.itemLang.map((langItem, index) => (
                        <Col key={langItem.language} xs={4} md={4}>
                          <h6>{langItem.language}</h6>
                        </Col>
                      ))
                    ) : (
                      <Col xs={4} md={4}>
                        <h6>EN</h6> {/* Default language */}
                      </Col>
                    )}
                  </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Name</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Row>
                  {item.itemLang.length > 0 ? (
                    item.itemLang.map((langItem, index) => (
                      <Col key={langItem.item_name} xs={4} md={4}>
                        <h6>{langItem.item_name}</h6>
                      </Col>
                    ))
                  ) : (
                    <Col xs={4} md={4}>
                      <h6>{item.item_name}</h6> 
                    </Col>
                  )}
                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Row>
                    <Col xs={6} md={3}>
                      <Form.Label>Customer Price</Form.Label>
                    </Col>
                    <Col xs={12} md={9}>
                      <Row>
                        {item.itemLang.length > 0 ? (
                          item.itemLang.map((langItem, index) => (
                            <Col key={langItem.language} xs={4} md={4}>
                              <h6>{langItem.price}/{langItem.unit}</h6>
                            </Col>
                          ))
                        ) : (
                          <Col xs={4} md={4}>
                            <h6>{item.price}/{item.unit}</h6>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <br />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Row>
                      <Col xs={6} md={3}>
                        <Form.Label>Description</Form.Label>
                      </Col>
                      <Col xs={12} md={9}>
                      <Row>
                        {item.itemLang.length > 0 ? (
                          item.itemLang.map((langItem, index) => (
                            <Col key={langItem.item_desc} xs={4} md={4}>
                              <h6>{langItem.item_desc}</h6>
                            </Col>
                          ))
                        ) : (
                          <Col xs={4} md={4}>
                            <h6>{item.description}</h6> 
                          </Col>
                        )}
                      </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              ))}
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* View modal End */}

      {/* Edit modal End */}
      <Modal
        size="lg"
        show={edit}
        onHide={hideeditModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Item Category </Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <select
                        onChange={(e) => setCategory(e.target.value)}
                        value={category}
                        className="form-select"
                      >
                        <option value="" disabled>
    Select An Item Category
  </option>
  {cat && cat.item
    ? cat.item.map((item) => (
        <option key={item._id} value={item._id}>
          {item.item_name}
        </option>
      ))
    : null}
                        </select>
                </Col>
              </Row>
            </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Dealer Price</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <Form.Control
                    type="text"
                    value={dealprice}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^\d*\.?\d{0,2}$/.test(input)) {
                        setDealPrice(input);
                        // handleInputChange(e);
                      }
                    }}
                    onKeyPress={(e) => {
                      const allowedKeys = /^[0-9\b.]$/;
                      if (!allowedKeys.test(e.key)) {
                        e.preventDefault();
                      }
                      
                      if (e.key === "." && dealprice.includes(".")) {
                        e.preventDefault();
                      }
                    }}
                    autoFocus
                    required
                  />
                </Col>
              </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Row>
                  <Col xs={6} md={3}>
                    <Form.Label>Language</Form.Label>
                  </Col>
                  <Col xs={12} md={9}>
                    <Row>
                      {itemLang.map((langItem, index) => (
                        <Col key={langItem.language} xs={4} md={4}>
                          <Form.Label>{langItem.language}</Form.Label>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Name</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Row>
                  {itemLang.map((langItem, index) => (
                    <Col key={langItem.language} xs={4} md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Product Name"
                        name={`item_name_${index}`}
                        value={langItem.item_name}
                        onChange={(e) => handleItemChange(e, index)}
                      />
                    </Col>
                  ))}
                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Unit Of Measure</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Row>
                {itemLang.map((langItem, index) => (
                    <Col key={langItem.language} xs={4} md={4}>
                      <select
                        onChange={(e) => handleUnitChange(e, index)}
                        value={langItem.unit}
                        className="form-select"
                      >
                        <option value="" disabled>
                          Select An Unit
                        </option>
                        {langItem.language === 'EN' && (
                          <>
                            <option value="Kg">Kg</option>
                            <option value="Pc">Pc</option>
                          </>
                        )}
                        {langItem.language === 'HI' && (
                          <>
                            <option value="किलो">किलो</option>
                            <option value="टुकड़ा">टुकड़ा</option>
                          </>
                        )}
                        {langItem.language === 'OD' && (
                          <>
                            <option value="କିଲୋ">କିଲୋ</option>
                            <option value="ଖଣ୍ଡ">ଖଣ୍ଡ</option>
                          </>
                        )}
                      </select>
                    </Col>
                  ))}

                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Customer Price</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Row>
                {itemLang.map((langItem, index) => (
                  <Col key={langItem.language} xs={4} md={4}>
                    <Form.Control
                      type="text"
                      placeholder="Place the price of the Item"
                      name={`item_name_${index}`}
                      value={langItem.price}
                      onChange={(e) => handlePriceAllChange(e, index)}
                      required
                    />
                  </Col>
                ))}
                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Description</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Row>
                  {itemLang.map((langItem, index) => (
                    <Col key={langItem.language} xs={4} md={4}>
                      <Form.Control
                        as="textarea"
                        placeholder="Description"
                        name={`item_name_${index}`}
                        value={langItem.item_desc}
                        onChange={(e) => handleDescriptionChange(e, index)}
                        rows={3}
                      />
                    </Col>
                  ))}
                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Image</Form.Label>
                </Col>
                <Col xs={12} md={12}>
                  <div style={{ display: "flex", overflowX: "auto" }}>
                    {oldimage && oldimage.map((immg, index) => (
                      <div key={index} style={{ position: "relative", display: "inline-block" }} className="bg-light mr-1">
                        <img
                          src={IMG_URL + immg}
                          style={{
                            height: "75px",
                            width: "75px",
                            marginRight: "5px",
                          }}
                        />

              {oldimage.length > 1 && ( 
                        <span
                          className="badge text-danger "
                          style={{
                            position: "absolute",
                            top: "-3px",
                            right: "-7px",
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRemoveImage(index)} 
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                      )}
                      </div>
                    ))}
                  </div>
                </Col>
                </Row>
                <br />
                <Row>
                  <Col xs={12} md={3}>
                  <Form.Label>Add Another Image </Form.Label>
                  </Col>
                <Col xs={12} md={9}>
                <Form.Control
                    type="file"
                    accept=".jpg, .png, .jpeg"
                    multiple
                    onChange={handleImageChangeedit}
                      // const files = e.target.files;
                      // setImage([...files]);                    
                  />
                </Col>
                </Row>
              
                  <br/>
              {/* <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Description</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <Form.Control
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
              </Row> */}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleApiUpdate(upId)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit modal End */}
    </div>
  );
};
