import React from "react";

function TermCondition() {
  return (
    <div classname="container-fluid mt-lg-5 mt-md-2 mt-sm-0">
      <div className="mx-lg-5 mt-lg-5 mx-sm-1 mx-md-3 mt-md-0 mt-sm-0 text-justify">
        <div className="pt-5 pb-3 text-center " style={{color:'#73be44'}}>
          <h2 className="text-decoration-underline"> Terms & Services </h2>
        </div>

        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3 " style={{color:'#73be44'}} >1. Introduction</h5>

          <div className="mx-4">
            <p>
            Welcome to Recycle Pay! This Support Policy outlines the level of support
             and assistance you can expect when using our mobile application ("<span style={{color:'#73be44'}}><b>RecyclePay</b></span>").
              Our aim is to provide you with a smooth and positive experience, and we are committed 
              to addressing your inquiries and concerns promptly.

            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className="mb-3" style={{color:'#73be44'}}>2. Support Channels</h5>
          <p>We offer support through the following channels:</p>
          <h5 className="mb-3 " style={{color:'#73be44'}}>2.1. In-App Support</h5>

          <div className="mx-4">
            <p>
            For general inquiries and assistance, you can use the support features
             within the RecyclePay app. This includes features such as the help center, chat, or contact forms.

            </p>
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3" style={{color:'#73be44'}}>2.2. Email Support</h5>

          <div className="mx-4">
            <p>
            You can also reach out to us via email at <span className="text-primary text-decoration-underline">support@ceibagreen.com</span> for questions, concerns, or feedback. 
            We aim to respond to email inquiries within 48 working hours during regular business hours.
            </p>
          </div>
        </div>
        
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className="mb-3" style={{color:'#73be44'}}>3. Scope of Support</h5>

          <div className="mx-4">
            <p>Our support team is available to assist you with the following matters:</p>
            <p className="mb-0">
            - General inquiries about how to use the App.
            </p>
            <p className="mb-0">
            - Technical issues and troubleshooting.

            </p>
            <p className="mb-0">
            - Assistance with transactions and account-related issues.

            </p>
            <p className="mb-0">
            - Feedback, suggestions, and feature requests.

            </p>
           
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <h5 className=" mb-3" style={{color:'#73be44'}}>4. Support Hours</h5>

          <div className="mx-4">
            <p>
            Our support team is available during the following hours:
            </p>
            <p className="mb-0">
            - Monday to Friday: [9am to 6 pm]

            </p>
           
          </div>
        </div>

        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <b><p className=" mb-3" style={{color:'#73be44'}}><span  className="text-danger">**</span>5. Expected Response Times<span className="text-danger">**</span></p></b>

          <div className="mx-4">
            <p>Our support team may not be able to assist with the following matters:
</p>

            <p className="mb-0">
            - Issues caused by a device or operating system that is not supported by the App.


            </p>
            <p className="mb-0">
            - Problems resulting from your use of the App in a manner inconsistent with our terms and policies.


            </p>
            <p className="mb-0">
            - Inquiries or issues not directly related to the use of the App.


            </p>
           
          </div>
        </div>

        {/* <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <p className="text-success mb-3"><span  className="text-danger">**</span>6. Exclusions<span className="text-danger">**</span></p>

          <div className="mx-4">
            <p>
            We are committed to providing timely responses to your inquiries.
             While response times may vary, we strive to acknowledge and address
              your questions within the following timeframes:


            </p>

            <p className="mb-0">
            - General inquiries: [Insert Expected Response Time]

            </p>
            <p className="mb-0">
            - Technical issues: [Insert Expected Response Time]

            </p>
            <p className="mb-0">
            - Account-related issues: [Insert Expected Response Time]

            </p>
            <p className="mb-0">
            - Feedback and feature requests: [Insert Expected Response Time]


            </p>
          </div>
        </div> */}
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <b><p className=" mb-3" style={{color:'#73be44'}}><span  className="text-danger">**</span>6. Feedback and Feature Requests<span className="text-danger">**</span></p></b>

          <div className="mx-4">
            <p>
            We welcome your feedback and suggestions for improving the App. If you have ideas or requests for new features, please feel free to share them with us. While we cannot guarantee the implementation of every suggestion, we value your input in enhancing the App's functionality and user experience.

            </p>

            
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
         <b> <p className=" mb-3" style={{color:'#73be44'}}><span  className="text-danger">**</span>7. Changes to this Support Policy<span className="text-danger">**</span></p></b>

          <div className="mx-4">
            <p>
            We may update this Support Policy as needed to better serve our users. We will notify you of any significant changes to the policy through the App. Your continued use of the App signifies your acceptance of the updated policy.



            </p>

           
          </div>
        </div>
        <div className="mt-lg-5 mt-md-3 mt-sm-2 mb-5 px-5 mx-lg-4 mx-md-0 mx-sm-0">
          <b><p className=" mb-3" style={{color:'#73be44'}}><span  className="text-danger">**</span>8. Contact Us<span className="text-danger">**</span></p></b>

          <div className="mx-4">
            <p>
            If you have questions or need support, please reach out to us through the available support channels.
             For general inquiries or feedback, you can email us at <span className="text-primary text-decoration-underline">support@ceibagreen.com</span> .



            </p>

            
          </div>
        </div>

       
      </div>
    </div>
  );
}

export default TermCondition;
