import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BASE_URL_API, IMG_URL } from "../environment";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Swal from "sweetalert2";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import noLogo from "../img/logo01.svg";

export const Dealers = () => {
  const [add, setAdd] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);

  // FETCH LISTS
  const [dealer, setDealer] = useState([]);

  const fetchData = () => {
    axios
      .get(BASE_URL_API + "users?utype=dealer")
      .then((res) => {
        setDealer(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //View Dealers
  const [DelView, setDelView] = useState([]);

  const viewBtn = (idd) => {
    handleApiView(idd);
    setView(true);
  };
  const handleApiView = (id) => {
    axios
      .get(BASE_URL_API + "users/" + id)
      .then((res) => {
        setDelView(res.data);
      })
      .catch((err) => console.log(err));
  };

  //CREATE DEALERS
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [image, setImage] = useState("");
  const [mobile, setMobile] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  //function for adding dealer
  const handleApiAdd = () => {
    if (!name || !email || !password || !image || !mobile) {
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: "All fields are required!",
        icon: "error",
      });
      return;
    }
    const addFormData = new FormData();
    addFormData.append("image", image);
    addFormData.append("name", name);
    addFormData.append("email", email);
    addFormData.append("mobile", mobile);
    addFormData.append("usertype", "dealer");
    addFormData.append("password", password);

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to add this Dealer!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Add",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(BASE_URL_API + "users/create", addFormData)
            .then(() => {
              fetchData();

              swalWithBootstrapButtons.fire(
                "Added!",
                "A New dealer added successfully.",
                "success"
              );

              setAdd(false);
              setName("");
              setEmail("");
              setPassword("");
              setImage("");
              setMobile("");
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not add this dealer",
            "error"
          );
          setEdit(false);
          setName("");
          setEmail("");
          setMobile("");
          setImage("");
          setPassword("");
        }
      });
  };

  //UPDATE PRODUCTS API
  const [upId, setUpId] = useState();

  const editBtn = (idd) => {
    axios
      .get(BASE_URL_API + "users/" + idd)
      .then((res) => {
        const item = res.data[0];
        setName(item.name);
        setEmail(item.email);
        setMobile(item.mobile);
        setPassword(item.password);
        setImage(item.imageURL);
        setUpId(item._id);
        setEdit(true);
      })
      .catch((err) => console.log(err));
  };

  //function for updating dealers
  const handleApiUpdate = (id) => {
    if (!name || !email || !password || !image || !mobile) {
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: "All fields are required!",
        icon: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.append("_id", id);
    formData.append("image", image);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("password", password);

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to update this Dealer !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Update",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .put(BASE_URL_API + "users/updateUsers", formData)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Updated!",
                "This Dealer details has been updated Successfully.",
                "success"
              );
              console.log(res.data);
              setEdit(false);
              fetchData();
              setName("");
              setEmail("");
              setPassword("");
              setImage({});
              setMobile("");
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not Update this dealer details",
            "error"
          );
          setEdit(false);
        }
      });
  };

  // Delete
  const apiDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(BASE_URL_API + "users/delete/" + id)
          .then((res) => {
            Swal.fire("Deleted!", "This Dealer has been deleted.", "success");
            console.log(res.data);
            fetchData();
          })
          .catch((err) => console.log(err));
      }
    });
  };

  //inactive
  const dealerInactive = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to deactivate this dealer !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deactive!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(BASE_URL_API + "users/deactivate/" + id)
          .then((res) => {
            Swal.fire(
              "Deactivated!",
              "This dealer has been deactivated.",
              "success"
            );
            // console.log(res.data);
            fetchData();
          })
          .catch((err) => console.log(err));
      }
    });
  };

  //activate
  const dealerActive = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to activate this dealer !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Activate!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(BASE_URL_API + "users/activate/" + id)
          .then((res) => {
            Swal.fire(
              "Activated!",
              "This dealer has been activated.",
              "success"
            );
            // console.log(res.data);
            fetchData();
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const rowClasses = (row) => {
    return row.isActive ? "table-lightgreen  " : "";
  };

  //action button in data table
  const actionsFormatter = (dataField, row) => {
    if (row.isActive) {
      return (
        <>
          <Button
            type="button"
            className="btn btn-sm "
            onClick={() => viewBtn(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="View Details"
            style={{background:"#73be44"}}
          >
            <i className="fa-solid fa-eye "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-secondary btn-sm mx-1"
            onClick={() => editBtn(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-solid fa-edit "></i>
          </Button>
          {/* <Button
            type="button"
            className="btn btn-danger btn-sm "
            onClick={() => apiDelete(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i className="fa-solid fa-trash "></i>
          </Button> */}
          <Button
            type="button"
            className="btn btn-primary btn-sm mx-1"
            onClick={() => dealerInactive(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Inactive"
          >
            <i className="fa-solid fa-toggle-on" aria-hidden="true"></i>
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            type="button"
            className="btn btn-light btn-sm disabled "
            onClick={() => viewBtn(dataField)}
          >
            <i className="fa-solid fa-eye "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-light btn-sm disabled mx-1"
            onClick={() => editBtn(dataField)}
          >
            <i className="fa-solid fa-edit "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-light btn-sm disabled"
            onClick={() => apiDelete(dataField)}
          >
            <i className="fa-solid fa-trash "></i>
          </Button>
          <Button
            type="button"
            className="btn btn-primary btn-sm mx-1"
            onClick={() => dealerActive(dataField)}
            data-toggle="tooltip"
            data-placement="top"
            title="Active"
          >
            <i className="fa-solid fa-toggle-off" aria-hidden="true"></i>
          </Button>
        </>
      );
    }
  };

  //table column for data table
  const columns = [
    {
      dataField: "imageURL",
      text: "Image",
      formatter: (dataField) => {
        if (dataField) {
          return (
            <img
              src={IMG_URL + dataField}
              style={{ height: "50px", width: "50px" }}
              className="rounded"
            />
          );
        } else {
          return (
            <img
              src={noLogo}
              style={{ height: "50px", width: "50px" }}
              className="rounded"
            />
          );
        }
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: "asc",
      filter: textFilter(),
    },
    {
      dataField: "mobile",
      text: "Mobile",
      sort: "asc",
      filter: textFilter(),
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: (isActive) => {
        return isActive ? "Active" : "Inactive";
      },
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: actionsFormatter,
    },
    // {
    //   dataField: '_id',
    //   text: 'Actions',
    //   formatter: (dataField) => {
    //     return (
    //       <>
    //         <Button type="button" className="btn btn-info mx-1" onClick={() => viewBtn(dataField)}><i className="fa-solid fa-eye me-1"></i></Button>
    //         <Button type="button" className="btn btn-success mx-1" onClick={() => editBtn(dataField)}><i className="fa-solid fa-edit me-1"></i></Button>
    //         <Button type="button" className="btn btn-danger mx-1" onClick={() => apiDelete(dataField)}><i className="fa fa-trash me-1"></i></Button>
    //       </>
    //     )
    //   }
    // }
  ];

  //pagination
  const [sizePerPage, setSizePerPage] = useState(10);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setSizePerPage(sizePerPage);
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: sizePerPage,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onTableChange: handleTableChange,
  };

  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    const emailRegex = /\S+@\S+\.\S+/;
    setIsValidEmail(emailRegex.test(inputEmail));
  };

  const [isValidMobile, setIsValidMobile] = useState(true);
  const [isValidName, setIsValidName] = useState(true);

  return (
    <div className="card">
      <h5 className="card-header">Dealers</h5>
      <Button className="btn" style={{background:"#73be44", color:"#fff" }} onClick={() => setAdd(true)}>
        <i className="fa-solid fa-plus"></i>&nbsp; Add New Dealer
      </Button>

      {/* Dealers Table */}
      <BootstrapTable
        keyField="id"
        data={dealer}
        columns={columns}
        pagination={paginationFactory(options)}
        search
        filter={filterFactory()}
        rowClasses={rowClasses}
      />

      {/* Create New Dealer */}
      <Modal
        size="md"
        show={add}
        onHide={() => setAdd(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            Add Scrap Dealer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Name</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Dealers Name"
                    onChange={(e) => {
                      setName(e.target.value);
                      setIsValidName(/^[A-Za-z\s]+$/.test(e.target.value));
                    }}
                    isValid={isValidName}
                    isInvalid={!isValidName}
                    autoFocus
                    required
                    onKeyPress={(e) => {
                      const isAlphabetWithSpace = /^[A-Za-z\s]+$/;
                      const isValidInput = isAlphabetWithSpace.test(e.key);

                      if (!isValidInput) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid name.
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="email"
                    placeholder="example@xyz.com"
                    onChange={handleEmailChange}
                    isValid={isValidEmail}
                    isInvalid={!isValidEmail}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Mobile</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Dealers Mobile"
                    onChange={(e) => {
                      setMobile(e.target.value);
                      setIsValidMobile(e.target.value.length === 10);
                    }}
                    isValid={isValidMobile}
                    isInvalid={!isValidMobile}
                    onKeyPress={(e) => {
                      const isNumeric = /^[0-9]+$/;
                      const isValidInput = isNumeric.test(e.key);

                      if (!isValidInput) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={10}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a 10-digit mobile number.
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Image</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="file"
                    placeholder="Dealers Image"
                    accept=".jpg, .jpeg, .png"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <InputGroup.Text
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <BsFillEyeSlashFill />
                      ) : (
                        <BsFillEyeFill />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Row>
          <Col xs={6} md={4}>
            <Form.Label>Re-Enter Password</Form.Label>
          </Col>
          <Col xs={12} md={8}>
            <InputGroup>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Re-Enter Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
      </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleApiAdd}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View Modal */}
      <Modal
        size="md"
        show={view}
        onHide={() => setView(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Agent Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {DelView.map((item, i) => (
                <>
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Image</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      {item.imageURL ? (
                        <img
                          src={IMG_URL + item.imageURL}
                          style={{ height: "75px", width: "75px" }}
                        />
                      ) : (
                        <img
                          src={noLogo}
                          style={{ height: "75px", width: "75px" }}
                        />
                      )}{" "}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Name</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <h4>{item.name}</h4>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Mobile</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <h6>{item.mobile}</h6>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Email</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <h6>{item.email}</h6>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Created on</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <p>{item.createdAt}</p>
                    </Col>
                  </Row>
                </>
              ))}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setView(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit modal */}
      <Modal
        size="lg"
        show={edit}
        onHide={() => setEdit(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Update Dealer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Row>
          <Col xs={6} md={4}>
            <Form.Label>Name</Form.Label>
          </Col>
          <Col xs={12} md={8}>
            <Form.Control
              type="text"
              placeholder="Dealers Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setIsValidName(/^[A-Za-z\s]+$/.test(e.target.value));
              }}
              isValid={isValidName}
              isInvalid={!isValidName}
              autoFocus
              onKeyPress={(e) => {
                const isAlphabetWithSpace = /^[A-Za-z\s]+$/;
                const isValidInput = isAlphabetWithSpace.test(e.key);

                if (!isValidInput) {
                  e.preventDefault();
                }
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid name.
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Row>
          <Col xs={6} md={4}>
            <Form.Label>Email</Form.Label>
          </Col>
          <Col xs={12} md={8}>
            <Form.Control
              type="text"
              placeholder="Dealers Email"
              value={email}
              onChange={handleEmailChange}
              isValid={isValidEmail}
              isInvalid={!isValidEmail}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address.
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Row>
          <Col xs={6} md={4}>
            <Form.Label>Mobile</Form.Label>
          </Col>
          <Col xs={12} md={8}>
            <Form.Control
              type="text"
              placeholder="Dealers Mobile"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
                setIsValidMobile(e.target.value.length === 10);
              }}
              isValid={isValidMobile}
              isInvalid={!isValidMobile}
              onKeyPress={(e) => {
                const isNumeric = /^[0-9]+$/;
                const isValidInput = isNumeric.test(e.key);

                if (!isValidInput) {
                  e.preventDefault();
                }
              }}
              maxLength={10}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a 10-digit mobile number.
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Image</Form.Label>
                </Col>
                <Col xs={12} md={2}>
                  <img
                    src={IMG_URL + image}
                    style={{ height: "75px", width: "75px" }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control
                    type="file"
                    placeholder="Image"
                    accept=".jpg, .jpeg, .png"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                <InputGroup>
                  <Form.Control
                    placeholder="Dealers Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                  />
                   <InputGroup.Text
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <BsFillEyeSlashFill />
                      ) : (
                        <BsFillEyeFill />
                      )}
                    </InputGroup.Text>
                    </InputGroup>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleApiUpdate(upId)}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
