import React from 'react'
import home from '../img/scrap1.jpg'
import about from '../img/waste1.jpg'
import why from '../img/feature.jpg'
import test1 from '../img/testimonial-1.jpg'
import test2 from '../img/testimonial-2.jpg'
import test3 from '../img/testimonial-3.jpg'
import test4 from '../img/testimonial-4.jpg'
import { Link } from 'react-router-dom'

export const Home = () => {

    const handleMenuItemClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' 
        });
      };
  return (
    <>
<div className="container-fluid position-relative p-0">
        <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100" src={home} />
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div className="p-3">
                            <h5 className="text-uppercase animated slideInDown" >Turn Trash into Treasure!</h5>
                            <h1 className="display-1 text-white mb-md-4 animated zoomIn">Sort. Recycle. Earn.</h1>
                            <br/>
                            <br/><h5><Link to='https://devrecyclepayapp.ceibagreen.com/' className='text-light p-3 rounded' style={{background:'#73be44'}}>Sell Your Scrap Now <i className='fa fa-arrow-right'></i></Link></h5>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

{/* <!-- About Start --> */}
    <div className="container-fluid py-3 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title position-relative pb-3 mb-5">
                        <h5 className="fw-bold text-success text-uppercase"></h5>
                        <h1 className="mb-0" style={{color:'#73be44'}}>Our aim a Clean India</h1>
                    </div>
                    <p className="mb-2 text-justify">Recycle Pay, launched in 2023, is the dry waste management initiative of <a href='https://www.ceibagreen.com/' style={{color:"#73be44"}} >Ceiba Green Solutions .</a></p>
                    <p className='text-justify'>Recycle Pay incentivizes the micro-segregation of dry recyclables from residential,
commercial and institutional entities. Our on-demand, free pickup service helps retain
useful resources within the economy rather than ending up in the landfill, thus ringing in
efficiency, transparency in collection, segregation and recovery of dry waste.</p>
                    <p className='text-justify'>The service looks to reduce the strain on the municipality&#39;s waste-collection resources,
                    make our cities cleaner and also benefit the informal workers.</p>
                    <div className="row g-0 mb-3">
                        {/* <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                            <h5 className="mb-3"><i className="fa fa-check me-3" style={{color:'#73be44'}}></i>Award Winning</h5>
                            <h5 className="mb-3"><i className="fa fa-check me-3" style={{color:'#73be44'}}></i>Verified Agents</h5>
                        </div> */}
                        {/* <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s"> */}
                            {/* <h5 className="mb-3"><i className="fa fa-check text-success me-3"></i>24/7 Support</h5> */}
                            {/* <h5 className="mb-3"><i className="fa fa-check me-3" style={{color:'#73be44'}}></i>Fair Prices</h5>
                        </div> */}
                    </div>
                    {/* <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                        <div className=" d-flex align-items-center justify-content-center rounded" style={{width: "60px", height: "60px", background:"#73be44"}}>
                            <i className="fa fa-phone text-white"></i>
                        </div>
                        <div className="ps-4 ">
                            <h5 className="mb-2">Call to ask any question</h5>
                            <h4 className="mb-0" style={{color:'#73be44'}}>+91 7655892808</h4>
                        </div>
                    </div> */}
                    {/* <Link to="/contact" onClick={handleMenuItemClick} className="btn py-3 px-5 mt-3 wow zoomIn text-white" style={{background:'#73be44'}}
                        data-wow-delay="0.9s">Contact Us</Link> */}
                </div>
                <div className="col-lg-5">
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s"
                            src={about}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- About End --> */}

    {/* <!-- Features Start --> */}
    <div className="container-fluid py-3 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
                <h5 className="fw-bold text-uppercase" style={{color:'#73be44'}}>Why Choose Us</h5>
                <h1 className="mb-0">We are here to preserve and protect the environment.</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4">
                    <div className="row g-5">
                        <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                            <div className=" rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px", background:"#73be44"}}>
                                <i className="fa fa-money text-white"></i>
                            </div>
                            <h4>Instant Payments</h4>
                            <p className="mb-0 text-justify">Get paid for your dry recyclables (paper, plastic, electronic waste and metal)
instantly through cash or UPI (as per your choice)</p>
                        </div>
                        <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                            <div className=" rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px", background:"#73be44"}}>
                                <i className="fa fa-award text-white"></i>
                            </div>
                            <h4>At Your Convenience</h4>
                            <p className="mb-0 text-justify">Schedule a free doorstep pickup at your convenient date and time, on our smart
phone app from the comfort of your homes/office.</p>
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-4  wow zoomIn" data-wow-delay="0.9s">
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s"
                            src={why} />
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="row g-5">
                        <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                            <div className=" rounded d-flex align-items-center justify-content-center mb-3 " style={{width: "60px", height: "60px", background:"#73be44"}}>
                                <i className="fa fa-cubes text-white"></i>
                            </div>
                            <h4>Fair Pricing and Measurement</h4>
                            <p className="mb-0 text-justify">We use electronic weighing machine to weigh the dry recyclables. Our rate chart is
updated everyday thus ensuring transparent pricing.</p>
                        </div>
                        <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                            <div className=" rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px", background:"#73be44"}}>
                                <i className="fa fa-users-cog text-white"></i>
                            </div>
                            <h4>Verified Staff</h4>
                            <p className="mb-0 text-justify">Our pickup agents are verified and trained thus providing you a safe service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* <!-- Services Start --> */}
<div className="container-fluid py-3 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
                <h5 className="fw-bold text-uppercase" style={{color:'#73be44'}}>How It Works?</h5>
                <h1 className="mb-0"> Global Solutions for Local Waste</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                    <div
                        className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon" style={{background:"#73be44"}} >
                            <i className="fa fa-shield-alt text-white"></i>
                        </div>
                        <h4 className="mb-3">Install the app</h4>
                        <p className="m-0">At first you have to install the app. 
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                    <div
                        className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon" style={{background:"#73be44"}}>
                            <i className="fa fa-chart-pie text-white"></i>
                        </div>
                        <h4 className="mb-3">Give an estimated weight.</h4>
                        <p className="m-0">Give an estimated volume of material that needs to be picked up.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon" style={{background:"#73be44"}}>
                            <i className="fa fa-calendar text-white"></i>
                        </div>
                        <h4 className="mb-3">Schedule a Pickup Date and Time</h4>
                        <p className="m-0">At first you have to schedule a date and time.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon" style={{background:"#73be44"}}>
                            <i className="fa fa-truck text-white"></i>
                        </div>
                        <h4 className="mb-3">Pickup complete on schedule date</h4>
                        <p className="m-0">On the scheduled date our verified agents weigh the dry recyclables.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon" style={{background:"#73be44"}}>
                            <i className="fa fa-money text-white"></i>
                        </div>
                        <h4 className="mb-3">Instant money transfer</h4>
                        <p className="m-0">You get paid for your scrap.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                    <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="service-icon" style={{background:"#73be44"}}>
                            <i className="fa fa-recycle text-white"></i>
                        </div>
                        <h4 className="mb-3">Recycle your waste</h4>
                        <p className="m-0">Your waste goes to recycling facilities rather than the landfills.
                        </p>
                    </div>
                </div>       
                
            </div>
        </div>
    </div>
    {/* <!-- Testimonial Start --> */}
    {/* <div className="container-fluid py-3 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-4 mx-auto">
                <h5 className="fw-bold text-success text-uppercase">Testimonial</h5>
                <h1 className="mb-0">What Our Clients Says</h1>
            </div>
            <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.6s">
                <div className="testimonial-item bg-light my-4">
                    <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                        <img className="img-fluid rounded" src={test1} alt={"testimony"}/>
                        <div className="ps-4">
                            <h4 className="text-success mb-1">Natasha</h4>
                            <small className="text-uppercase">Doctor</small>
                        </div>
                    </div>
                    <div className="pt-4 pb-5 px-5 text-justify">
                    Recycle Pay has been a game-changer for our household.
                     It's incredible how something as simple as sorting and recycling our household waste can actually put money back in our pockets. 
                    The process is straightforward, and the rewards are tangible. 
                        </div>
                </div>
                <div className="testimonial-item bg-light my-4">
                    <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                        <img className="img-fluid rounded" src={test2} alt-text={"testimony"}/>
                        <div className="ps-4">
                            <h4 className="text-success mb-1">Tony</h4>
                            <small className="text-uppercase">IG Influenser</small>
                        </div>
                    </div>
                    <div className="pt-4 pb-5 px-5 text-justify">
                    Recycle Pay has made recycling our household waste a rewarding experience. 
                    Not only are we reducing our environmental footprint, but we're also earning money in the process. 
                    Sorting our recyclables has become a habit, and seeing the cash rewards accumulate is motivating.
                     </div>
                </div>
                <div className="testimonial-item bg-light my-4">
                    <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                        <img className="img-fluid rounded" src={test3} alt={"testimony"}/>
                        <div className="ps-4">
                            <h4 className="text-success mb-1">Ruther</h4>
                            <small className="text-uppercase">Advocate</small>
                        </div>
                    </div>
                    <div className="pt-4 pb-5 px-5 text-justify">
                        Recycle Pay has turned our trash into cash, and we couldn't be happier.
                         The process of recycling our household waste is made so much more rewarding 
                         with the financial incentives. It's an excellent way to encourage responsible
                          waste management and environmental consciousness. 
                     </div>
                </div>
                <div className="testimonial-item bg-light my-4">
                    <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                        <img className="img-fluid rounded" src={test4} alt={"testimony"} />
                        <div className="ps-4">
                            <h4 className="text-success mb-1">Natly</h4>
                            <small className="text-uppercase">Software Developer</small>
                        </div>
                    </div>
                    <div className="pt-4 pb-5 px-5 text-justify">Recycle Pay is a brilliant concept that has revolutionized the way we 
                    look at recycling household waste. Not only are we reducing our waste, 
                    but we're also reaping the benefits in the form of extra income. 
                    The ease of the process, combined with the financial rewards, has made recycling a profitable and environmentally conscious endeavor.
                     It's a win-win for us, our community, and the environment. 
                    Thank you, Recycle Pay, for helping us make a positive impact while earning a
                     little extra along the way! </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!-- Testimonial End --> */}
    </>
  )
}
