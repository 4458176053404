import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL_API } from "../environment";
import { format } from "date-fns";
import { utils, writeFile as writeFileXLSX } from "xlsx";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Dropdown, Pagination, Modal, Button } from "react-bootstrap";


export const Reports = () => {
  const [alldata, setAlldata] = useState([]);
  const adminId = localStorage.getItem("token");

  // function for converting date string to dd-mm-yyyy
  const getFormattedDate = (dateString) => {
    if (!dateString) {
      console.error("Date string is null or empty.");
      return dateString;
    }

    let date;
    if (dateString instanceof Date) {
      date = dateString;
    } else {
      const dateParts = dateString.split("-");
      if (dateParts.length === 3) {
        if (dateParts[0].length === 4) {
          date = new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`);
        } else {
          date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
        }
      } else {
        console.error("Invalid date format:", dateString);
        return dateString;
      }
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getBeforeDays = (days) => {
    if (!days) {
      console.error("Date string is null or empty.");
      return days;
    }

    const today = new Date();
    const dateBeforeDays = new Date(today.setDate(today.getDate() - days));
    return getFormattedDate(dateBeforeDays);
  };

  const formatDateToISO = (dateString) => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];
      return `${year}-${month}-${day}`;
    }
    return dateString;
  };

  const formatDateToddmmyyyy = (dateString) => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      return `${day}-${month}-${year}`;
    }
    return dateString;
  };

  const [fromDate, setFromDate] = useState(getFormattedDate(getBeforeDays(15)));
  const [toDate, setToDate] = useState(getFormattedDate(new Date()));
  const [searchName, setSearchName] = useState("");
  const [searchOrderId, setSearchOrderId] = useState("");
  const [finalprice, setFinalprice] = useState("");
  const [modeFilter, setModeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [scdate, setScdate] = useState("");
  const [crtdate, setSCrtdate] = useState("");
  const [contactno, setContactno] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [address, setAddress] = useState("");

  const [mont, setMont] = useState(new Date().getMonth() + 1);
  const [yer, setYer] = useState(new Date().getFullYear());
  const [itemwise, setItemwise] = useState([]);
  const [itemname, setItemName] = useState("");
  const [qtyascending, setQtyascending] = useState(false);
  const [qtydescending, setQtydescending] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [totaloffset, setTotalOffset] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [itemcatname, setItemCatName] = useState("");
  const [unit, setUnit] = useState("");
  const changeFormat = (date) => {
    const createdAtDate = new Date(date);
    return format(createdAtDate, "dd-MM-yyyy");
  };

  const changeFormat2 = (date) => {
    if (date) {
      const parts = date.split("-");
      if (parts.length === 3) {
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        return formattedDate;
      }
    }
    return "N/A";
  };

  const handleSort = (sortOrder) => {
    if (sortOrder === 'asc') {
      setQtyascending(true);
      setQtydescending(false);
    } else if (sortOrder === 'desc') {
      setQtyascending(false);
      setQtydescending(true);
    } else if (sortOrder === '') {
      setQtyascending(false);
      setQtydescending(false);
    }
  }
  //function for geting all orders for report
  const fetchData = () => {
    axios
      .get(BASE_URL_API + "orders/orderreport/" + adminId)
      .then((res) => {
        const ordersData = res.data.data[0]?.orders || [];

        // Format schedule_date and sort the data
        const formattedData = ordersData.map((order) => ({
          ...order,
          schedule_date: formatDateToISO(order.schedule_date),
        }));

        const filteredData = formattedData.filter((order) => {
          const orderDate = new Date(order.schedule_date);
          const fromFilter = !fromDate || orderDate >= new Date(fromDate);
          const toFilter = !toDate || orderDate <= new Date(toDate);
          const nameMatch =
            !searchName ||
            (order.customer_name &&
              order.customer_name
                .toLowerCase()
                .includes(searchName.toLowerCase()));
          const orderIdMatch =
            !searchOrderId ||
            order.order_id.toLowerCase().includes(searchOrderId.toLowerCase());
          const price =
            !finalprice || order.est_price.toString().includes(finalprice);
          const pmode =
            !modeFilter ||
            order.mode.toLowerCase().includes(modeFilter.toLowerCase());
          const pstatus =
            !statusFilter ||
            order.status.toLowerCase().includes(statusFilter.toLowerCase());
          const schdate =
            !scdate ||
            order.schedule_date.toLowerCase().includes(scdate.toLowerCase());
          const creatdate =
            !crtdate ||
            changeFormat(order.createdAt)
              .toLowerCase()
              .includes(changeFormat2(crtdate).toLowerCase());
          const contactMatch = !contactno || (order.customer_mobile && order.customer_mobile.includes(contactno));
          const addressMatch = !address || (order.location && order.location.toLowerCase().includes(address.toLowerCase()));
          const latitudeMatch = !latitude || (order.latitude && order.latitude.toString().includes(latitude));
          const longitudeMatch = !longitude || (order.longitude && order.longitude.toString().includes(longitude));
    

          return (
            fromFilter &&
            toFilter &&
            nameMatch &&
            orderIdMatch &&
            price &&
            pmode &&
            pstatus &&
            schdate &&
            creatdate &&
            contactMatch &&
            addressMatch &&
            latitudeMatch &&
            longitudeMatch
          );
        });

        // Sort the data based on schedule_date
        // filteredData.sort((a, b) => new Date(a.schedule_date) - new Date(b.schedule_date));

        setAlldata(filteredData);
        console.log(filteredData);
      })
      .catch((err) => console.log(err));

      axios
      .get(BASE_URL_API + "items/allCollectedItems/report?name="+itemname+"&itemcategoryname="+itemcatname+"&unit="+unit+"&asc=" + qtyascending + "&desc=" + qtydescending + "&limit=" + limit + "&offset=" + offset +"&stdate="+formatDateToddmmyyyy(fromDate)+"&enddate="+formatDateToddmmyyyy(toDate))
      .then((res) => {
        setItemwise(res.data.results);
        setTotalOffset(res.data.totalOffset);
        setTotalItems(res.data.totalCount);
      })
      .catch((err) => console.log(err));
  };

  const [totalEstimatedPrice, setTotalEstimatedPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const startItem = (offset - 1) * 10 + 1;
  const endItem = Math.min(offset * 10, totalItems);
  const handlePageChange = (pageNumber) => {
    setOffset(pageNumber);
  };

  //calculating total price for successful order and pending order
  const calculateTotalEstimatedPrice = (data) => {
    const processedRows = data.filter((row) => row.status === "processed");
    const nonProcessedRows = data.filter((row) => row.status !== "processed");

    const totalEstimated = processedRows.reduce(
      (acc, row) => acc + parseFloat(row.est_price),
      0
    );

    const total = nonProcessedRows.reduce(
      (acc, row) => acc + parseFloat(row.est_price),
      0
    );

    setTotalEstimatedPrice(totalEstimated.toFixed(2));
    setTotalPrice(total.toFixed(2));
  };

  useEffect(() => {
    calculateTotalEstimatedPrice(alldata);
  }, [alldata]);

  useEffect(() => {
    fetchData();
  }, [adminId,fromDate,toDate,searchName,searchOrderId,finalprice,modeFilter,statusFilter,scdate,crtdate,contactno,longitude,latitude,address, mont, yer, itemname, itemcatname, qtyascending, qtydescending, offset, unit]);

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
    setCurrentPage(1);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
    setCurrentPage(1);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const maxPagesToShow = 7; // Show at most 7 pages in the pagination control
    const startPages = 3; // Show the first 3 pages
    const endPages = 2; // Show the last 2 pages

    if (totaloffset <= maxPagesToShow) {
      for (let page = 1; page <= totaloffset; page++) {
        paginationItems.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }
    } else {
      // Show first 3 pages
      for (let page = 1; page <= startPages; page++) {
        paginationItems.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }

      // Show ellipsis if necessary
      if (offset > startPages + 1) {
        paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      // Show the middle pages around the current page
      const middlePages = [];
      const startMiddlePage = Math.max(offset - 1, startPages + 1);
      const endMiddlePage = Math.min(offset + 1, totaloffset - endPages);

      for (let page = startMiddlePage; page <= endMiddlePage; page++) {
        middlePages.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }

      paginationItems.push(...middlePages);

      // Show ellipsis if necessary
      if (offset < totaloffset - endPages - 1) {
        paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }

      // Show last 2 pages
      for (let page = totaloffset - endPages + 1; page <= totaloffset; page++) {
        paginationItems.push(
          <Pagination.Item key={page} active={offset === page} onClick={() => handlePageChange(page)}>
            {page}
          </Pagination.Item>
        );
      }
    }

    return paginationItems;
  };
  //function for export as excel
  const handleDownloadExcel = (alldata, sheetName, fileName) => {
    const columnsToExport = [
      "order_id",
      "customer_name",
      "customer_mobile",
      "createdAt",
      "schedule_date",
      "time",
      "est_price",
      "final_price",
      "mode",
      "status",
    ];

    const sumRow = {
      est_price: totalPrice, 
      final_price: totalEstimatedPrice,
    };

    const filteredData = alldata.map((row) =>
      columnsToExport.reduce((acc, column) => {
        if (column === "createdAt") {
          acc[column] = row[column]
            ? format(new Date(row[column]), "dd-MM-yyyy")
            : null;
        } else if (column === "schedule_date") {
          acc[column] = row[column]
            ? `${row[column].split("-")[2]}-${row[column].split("-")[1]}-${
                row[column].split("-")[0]
              }`
            : null;
        } else if (column === "status") {
          acc[column] = row[column] === "processed" ? "Payment Successful" : row[column];
        } else if (column === "est_price") {
          if (row["status"] === "processed") {
            acc[column] = null;
          } else {
            acc[column] = row["est_price"];
          }
        } else if (column === "final_price") {
          if (row["status"] === "processed") {
            acc[column] = row["est_price"];
          } else {
            acc[column] = null;
          }
        } else {
          acc[column] = row[column];
        }
        return acc;
      }, {})
    );
    const ws = utils.json_to_sheet([...filteredData, sumRow]);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);
    writeFileXLSX(wb, `${fileName}.xlsx`);
  };

  //function for export as csv
  const handleDownloadCSV = (alldata, sheetName, fileName) => {
    const csv = alldata.map((row) => Object.values(row).join(",")).join("\n");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${fileName}.csv`);
  };

  //function for download as pdf
  const handleDownloadPDF = (alldata, sheetName, fileName) => {
    const columnsToExport = [
      "order_id",
      "customer_name",
      "customer_mobile",
      "createdAt",
      "schedule_date",
      "time",
      "est_price",
      "final_price",
      "mode",
      "status",
    ];
    const sumRow = {
      order_id: "", 
      customer_name: "",
      customer_mobile: "",
      createdAt: "",
      schedule_date: "",
      time: "",
      est_price: totalPrice,
      final_price: totalEstimatedPrice, 
      mode: "",
      status: "",
    };
    const filteredData = alldata.map((row) =>
      columnsToExport.reduce((acc, column) => {
        if (column === "createdAt") {
          acc[column] = row[column]
            ? format(new Date(row[column]), "dd-MM-yyyy")
            : null;
        } else if (column === "schedule_date") {
          acc[column] = row[column]
            ? `${row[column].split("-")[2]}-${row[column].split("-")[1]}-${
                row[column].split("-")[0]
              }`
            : null;
        } else if (column === "status") {
          acc[column] = row[column] === "processed" ? "Payment Successful" : row[column];
        } else if (column === "est_price") {
          if (row["status"] === "processed") {
            acc[column] = null;
          } else {
            acc[column] = row["est_price"].toFixed(2);
          }
        } else if (column === "final_price") {
          if (row["status"] === "processed") {
            acc[column] = row["est_price"].toFixed(2);
          } else {
            acc[column] = null;
          }
        } else {
          acc[column] = row[column];
        }
        return acc;
      }, {})
    );

    const doc = new jsPDF();
    doc.autoTable({
      head: [columnsToExport],
      body: [...filteredData.map((row) => Object.values(row)), Object.values(sumRow)], 
    });

    doc.save(`${fileName}.pdf`);
  };

  //function for converting date to dd-mm-yyyy format
  const getCurrentDateFormatted = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
  
    return `${dd}-${mm}-${yyyy}`;
  };

  //function for export 
  const handleExport = () => {
    // window.print();
    const currentDate = getCurrentDateFormatted();
    handleDownloadExcel(alldata, `Report-${currentDate}`, `Report_RP_${currentDate}`);
  };
  const csvdownload = () => {
    // window.print();
    const currentDate = getCurrentDateFormatted();
    handleDownloadCSV(alldata, `Report-${currentDate}`, `Report_RP_${currentDate}`);
  };
  const pdfdownload = () => {
    // window.print();
    const currentDate = getCurrentDateFormatted();
    handleDownloadPDF(alldata, `Report-${currentDate}`, `Report_RP_${currentDate}`);
  };

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(alldata.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = alldata.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [view, setView] = useState(false);
  const viewBtn=(idd)=>{
    handleApiView (idd);
    setView(true);
  }
  const [itemview, setItemview] = useState([]);
  //function for getting order by id
  const handleApiView = (id) => {
    axios.get(BASE_URL_API + 'orders/indhistorder/'+id)
      .then((res) => {
        console.log(res.data);
        setItemview(res.data)
      }).catch(err => console.log(err));
  };

  //function for calculating subtotal
  const calculateSubtotal = (items) => {
    if (!Array.isArray(items)) return 0;
  
    return items.reduce((acc, item) => {
      const itemPrice = parseFloat(item.price) || 0;
      const weight = parseFloat(item.weight) || 0;
      return acc + itemPrice * weight;
    }, 0);
  };
  
  const subtotal = calculateSubtotal(
    itemview?.data?.result[0]?.item
  );
  const [selectedReport, setSelectedReport] = useState("OrdRepo");
  const monthNames = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  const handleExportQuantitydata = () => {
    axios
      .get(BASE_URL_API + "items/allCollectedItems/report" +"?name=" + itemname +"&unit="+unit+"&itemcategoryname="+itemcatname+ "&asc=" + qtyascending + "&desc=" + qtydescending + "&limit=" + totalItems + "&offset=" +1+"&stdate="+formatDateToddmmyyyy(fromDate)+"&enddate="+formatDateToddmmyyyy(toDate))
      .then((res) => {
        const allData = res.data.results;
        const currentDate = getCurrentDateFormatted();
        handleDownloadExcelquantity(allData, `Quantity Report-${currentDate}`, `Quantity Report${currentDate}`);
      })
      .catch((err) => console.log(err));
  };
  

  const handleDownloadExcelquantity = (itemwise, sheetName, fileName) => {
    const ws = utils.json_to_sheet(itemwise);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);
    writeFileXLSX(wb, `${fileName}.xlsx`);
  };
  return (
    <div className="card">
      <div className="card-header justify-content-between d-flex">
        <h5>Reports</h5>
        {selectedReport === "OrdRepo" && (
        <div>
          <label className="mx-2 ">
            <b>From Date :</b>
          </label>
          <input
            type="date"
            onChange={handleFromDateChange}
            value={fromDate}
            className="p-1 border border-primary text-primary"
            max={toDate}
          />
          &nbsp; &nbsp;
          <label className="mx-2 ">
            <b>To Date :</b>
          </label>
          <input
            type="date"
            onChange={handleToDateChange}
            value={toDate}
            className="p-1 border border-primary text-primary"
            min={fromDate}
          />
        </div>
        )}
        {/* {selectedReport === "QtyRepo" && (
        <div className="d-flex">
        <select className="form-select form-select-sm text-success border border-success" style={{width:"auto"}} aria-label=".form-select-sm example" value={mont} onChange={(e) => {setMont(e.target.value); setOffset(1);}}>
        {monthNames.map((month, index) => (
        <option key={index + 1} value={String(index + 1).padStart(2, '0')}>
          {month}
        </option>
          ))}
        </select>
        <select className="form-select form-select-sm text-success border border-success" aria-label=".form-select-sm example" value={yer} onChange={(e) => {setYer(parseInt(e.target.value)); setOffset(1);}}>
          {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map((year) => (
            <option key={year} value={year}> {year} </option>))}
        </select>
        </div>
        )} */}
        {selectedReport === "QtyRepo" && (
        <div>
          <label className="mx-2 ">
            <b>From Date :</b>
          </label>
          <input
            type="date"
            onChange={handleFromDateChange}
            value={fromDate}
            className="p-1 border border-primary text-primary"
            max={toDate}
          />
          &nbsp; &nbsp;
          <label className="mx-2 ">
            <b>To Date :</b>
          </label>
          <input
            type="date"
            onChange={handleToDateChange}
            value={toDate}
            className="p-1 border border-primary text-primary"
            min={fromDate}
          />
        </div>
        )}
      </div>
      <div className="d-flex justify-content-between ">
      <div>
        <div className="p-2">
        <select
          className="form-select form-select-md text-success border border-success" aria-label=".form-select-sm example"
          value={selectedReport}
          onChange={(e) => setSelectedReport(e.target.value)}
        >
          <option value="OrdRepo">Order Report</option>
          <option value="QtyRepo">Quantity Report</option>
        </select>
        </div>
      </div>
      {selectedReport === "OrdRepo" && (
        <div className="d-flex justify-content-between ">
        <div className="p-2">
          {/* export button */}
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="exportDropdown">
              Export As
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleExport} style={{color:"#73be44"}}>
                Excel
              </Dropdown.Item>
              <Dropdown.Item onClick={csvdownload} style={{color:"#73be44"}}>
                CSV
              </Dropdown.Item>
              <Dropdown.Item onClick={pdfdownload} style={{color:"#73be44"}}>
                PDF
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="p-2 my-auto mr-3">
          <span style={{ fontWeight: "bold" }}>Total Amount Paid:</span>
          <span style={{ color:"#73be44" }}>
            <b>₹ {totalEstimatedPrice}</b>
          </span>
        </div>
        </div>
      )}
      {selectedReport === "QtyRepo" && (
        <div className="d-flex justify-content-between ">
        <div className="p-2">
          {/* export button */}
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="exportDropdown">
              Export As
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleExportQuantitydata} style={{color:"#73be44"}}>
                Excel
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={csvdownload} style={{color:"#73be44"}}>
                CSV
              </Dropdown.Item>
              <Dropdown.Item onClick={pdfdownload} style={{color:"#73be44"}}>
                PDF
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        </div>
      )}
      </div>
      {/* table for showing all order details for report */}
      {selectedReport === "OrdRepo" && (
      <div className="">
        <div
          className="table-responsive"
          style={{ height: "70vh", overflowY: "auto" }}
        >
          <table className="table">
            <thead className="bg-light ">
              <tr>
                <th>Sl no</th>
                <th>
                  Order ID
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={searchOrderId}
                    onChange={(e) => setSearchOrderId(e.target.value)}
                  />
                </th>
                <th>
                  Customer Name
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                </th>
                <th>
                  Contact No
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={contactno}
                    onChange={(e) => setContactno(e.target.value)}
                  />
                </th>
                <th>
                  Address
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </th>
                <th>
                  Latitude
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                </th>
                <th>
                  Longitude
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                </th>
                <th>
                  Created Date
                  <br />
                  <input
                    type="date"
                    className="form-control"
                    style={{ width: "100%" }}
                    value={crtdate}
                    onChange={(e) => setSCrtdate(e.target.value)}
                    // min={getFormattedDate(getBeforeDays(15))}
                    // max={getFormattedDate(new Date())}
                  />
                </th>
                <th>
                  Schedule Date
                  <br />
                  <input
                    type="date"
                    className="form-control"
                    style={{ width: "100%" }}
                    value={scdate}
                    onChange={(e) => setScdate(e.target.value)}
                    // min={getFormattedDate(getBeforeDays(15))}
                    // max={getFormattedDate(new Date())}
                  />
                </th>
                <th>Time</th>
                <th>Est. Price</th>
                <th>
                  Final Price
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={finalprice}
                    onChange={(e) => setFinalprice(e.target.value)}
                  />
                </th>
                <th>
                  Mode <br />
                  <select
                    className="form-control"
                    value={modeFilter}
                    onChange={(e) => setModeFilter(e.target.value)}
                  >
                    <option value="">All Mode</option>
                    <option value="Cash">Cash</option>
                    <option value="Eco">Eco</option>
                    <option value="IMPS">IMPS</option>
                    <option value="UPI">UPI</option>
                    <option value="Donation">Donation</option>
                  </select>
                </th>
                <th>
                  Status <br />
                  <select
                    className="form-control"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <option value="">All Status</option>
                    <option value="Created">Created</option>
                    <option value="Assigned to agent">Assigned</option>
                    <option value="Rejected by admin">Rejected</option>
                    <option value="processed">Payment Successful</option>
                  </select>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "13px" }}>
              {currentItems
                // .filter((data) => {
                //   const nameMatch = data.customer_name.toLowerCase().includes(searchName.toLowerCase());
                //   const orderIdMatch = data.order_id.toLowerCase().includes(searchOrderId.toLowerCase());
                //   return nameMatch && orderIdMatch;
                // })
                .map((data, index) => {
                  const startIndex = (currentPage - 1) * itemsPerPage;
                  return (
                    <tr key={index}>
                      <td>{startIndex + index + 1}</td>
                      <td>{data.order_id}</td>
                      <td>{data.customer_name}</td>
                      <td>{data.customer_mobile}</td>
                      <td>{data.location}</td>
                      <td>{data.latitude}</td>
                      <td>{data.longitude}</td>                     
                      <td>{changeFormat(data.createdAt)}</td>
                      <td>{changeFormat2(data.schedule_date)}</td>
                      <td>{data.time}</td>
                      <td>
                        {data.status === "processed"
                          ? "--"
                          : data.est_price.toFixed(2)}
                      </td>
                      <td>
                        {data.status === "processed"
                          ? data.est_price.toFixed(2)
                          : "--"}
                      </td>
                      <td>{data.mode}</td>
                      <td>
                        {data.status === "processed"
                          ? "Payment Successful"
                          : data.status}
                      </td>
                      <td>
                        <Link  to={`/dashboard/orders/${data._id}`} className="btn btn-sm" style={{background:"#73be44", color:"#fff"}}><i className="fa-solid fa-arrow-right"/></Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan="10" className="text-right"></td>
                <td style={{color:"#73be44"}}>
                  <b>{totalPrice}</b>
                </td>
                <td style={{color:"#73be44"}}>
                  <b>{totalEstimatedPrice}</b>
                </td>
                <td colSpan="2"></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div className="float-left">
            <p>
              Showing{" "}
              {alldata.length === 0 ? indexOfFirstItem : indexOfFirstItem + 1}{" "}
              to{" "}
              {indexOfLastItem > alldata.length
                ? alldata.length
                : indexOfLastItem}{" "}
              of {alldata.length} entries
            </p>
          </div>
          {/* Pagination button */}
          <div className="d-flex align-items-center ">
            <Pagination>
              <Pagination.Prev
                onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                disabled={currentPage === 1}
              />

              {/* Display pages dynamically */}
              {Array.from({ length: totalPages }, (_, index) => {
                // Display all pages if there are less than 10 pages
                if (totalPages <= 10) {
                  return (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  );
                }

                // Display pages around the current page with ellipsis
                const pageToShow = 5; // Number of pages to show before and after ellipsis
                const firstPage = Math.max(1, currentPage - pageToShow);
                const lastPage = Math.min(totalPages, currentPage + pageToShow);

                // Display ellipsis on the left
                if (index === 1 && currentPage - pageToShow > 1) {
                  return <Pagination.Ellipsis key="left-ellipsis" />;
                }

                // Display ellipsis on the right
                if (
                  index === totalPages - 2 &&
                  currentPage + pageToShow < totalPages
                ) {
                  return <Pagination.Ellipsis key="right-ellipsis" />;
                }

                // Display pages within the range
                if (index + 1 >= firstPage && index + 1 <= lastPage) {
                  return (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  );
                }

                return null; // Hide other pages
              })}

              <Pagination.Next
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </div>
      </div>
      )}
      {selectedReport === "QtyRepo" && (
         <div className="">
         <div className="table-responsive" style={{ height: "70vh", overflowY: "auto" }}>
            <table className="table table-bordered ">
               <thead>
                 <tr>
                   <th>Item Name
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm"
                    value={itemname}
                    onChange={(e) => {setItemName(e.target.value); setOffset(1);}}
                  />
                   </th>
                   <th>Category Name
                   <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm"
                    value={itemcatname}
                    onChange={(e) => {setItemCatName(e.target.value); setOffset(1);}}
                  />
                   </th>
                   <th>Weight
                    <select className=" form-select form-select-sm" onChange={(e) => {handleSort(e.target.value); setOffset(1);}}>
                      <option value="">Sort by</option>
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </select>
                   </th>
                   <th>Unit
                   <select className=" form-select form-select-sm" onChange={(e) => {setUnit(e.target.value); setOffset(1);}}>
                      <option value="">Select</option>
                      <option value="Kg">Kg</option>
                      <option value="Pc">Pc</option>
                    </select>
                   </th>
                 </tr>
               </thead>
               <tbody>
               {itemwise.length > 0 ? (
               itemwise.map((item, index) => (
                  <tr key={index}>
                    <td>{item.Item_Name}</td>
                    <td>{item.Category_Name}</td>
                    <td>{item.Quantity}</td>
                    <td>{item.Unit}</td>
                  </tr>
                ))
              ) : (
                <td colSpan={4}>No Item Found</td>
              )}
               </tbody>
             </table>
           
         </div>
         <div className='d-flex justify-content-between pb-0'>
        <div className='p-2'>
          Showing {startItem} to {endItem} of {totalItems} items
        </div>
        <div className='p-2'>
          <Pagination className="mb-0">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={offset === 1} />
          <Pagination.Prev onClick={() => handlePageChange(offset - 1)} disabled={offset === 1} />
          {renderPaginationItems()}
          <Pagination.Next onClick={() => handlePageChange(offset + 1)} disabled={offset === totaloffset} />
          <Pagination.Last onClick={() => handlePageChange(totaloffset)} disabled={offset === totaloffset} />
          </Pagination>
        </div>
      </div>

       </div>
      )}
      {/* modal for view item and rates */}
      <Modal
            size="md"
            show={view}
            onHide={() => setView(false)}
            aria-labelledby="example-modal-sizes-title-md"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
              Order Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className=" line-items">
                      <div className="headers clearfix">
                        <div className="row">
                          <div className="col-4 " style={{color:"#73be44"}}>
                            <b>Item</b>
                          </div>
                          <div className="col-4 " style={{color:"#73be44"}}>
                            <b>Quantity</b>
                          </div>
                          <div className="col-4 text-right" style={{color:"#73be44"}}>
                            <b>Amount</b>
                          </div>
                        </div>
                      </div>

                      <div className="items">
                        {itemview?.data?.result[0]?.item.map((item, index) => (
                          <div key={index}>
                            <div className="row item mt-2">
                              <div className="col-4 desc">{item.item_name}</div>
                              <div className="col-4 qty">
                              {item.unit === 'Kg'? item.weight !== null ? item.weight.toFixed(3): '0': item.unit === 'Pc'
                                 ? item.weight !== null ? item.weight : '0' : ''} {item.unit}
                              </div>
                              <div className="col-4 amount text-right">
                                ₹
                                {(
                                  (item.weight !== null ? item.weight : 0) *
                                  item.price
                                ).toFixed(2)}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      
                    </div>
                      </Modal.Body>
            <Modal.Footer>
            <div className="total text-right ">
                        {/* <div className="extra-notes ">
                          Total Quantity :&nbsp;
                          <b className="text-success">
                            {totalQuantity.toFixed(3)} Kg
                          </b>
                        </div> */}

                        <div className="field grand-total">
                          Total{" "}
                          <span>
                            <strong style={{color:"#73be44"}}>₹{subtotal.toFixed(2)}</strong>{" "}
                          </span>
                        </div>
                      </div>
            </Modal.Footer>
          </Modal>
    


    </div>
  );
};
